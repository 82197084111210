﻿/// <reference path="../../main">


//STRUCTURE AND STYLING
.#{$adage-prefix}newsletter-signup {
    @extend %themes;

    &-inner {
        @extend %standard-padding-h;
        @extend %standard-padding-v;
        max-width: $max-line-length;
        margin-left: auto;
        margin-right: auto;
    }

    &-description {
        margin-top: $gap-sm;
    }

    &-form-item {
        flex-grow: 1;
        margin-right: 0;
    }
}
