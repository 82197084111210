﻿/// <reference path="../../main">

$chevron-list-left-margin: 1.25rem;

// STRUCTURE
.#{$prefix}sidebar-block {
	padding-bottom: 1.86rem;

	&-title {
		padding: $space $space-lg;
	}

	&-content {
		padding: $space-lg;
		padding-right: 1.25rem;

		ol {
			margin-left: $chevron-list-left-margin;
		}

		ul {
			list-style: none;
			margin-left: 0;

			& > li + li {
				margin-top: $space-sm;
			}

			& > li {
				position: relative;
				padding-left: 1rem;
				background: url("~/Static/img/png-icons/double-chevron.png") no-repeat;
				background-position: 0 0;
				background-position-y: .2rem;
				background-position-x: -.1rem;
			}
		}
	}
}

// BASIC STYLES
.#{$prefix}sidebar-block {
	
	&-title {
		background: $neutral-2;
		color: $white;
		font-family: $body-font;
		font-weight: $font-weight-normal;
		margin-bottom: 0;
		@include font-size($medium-font-sizes);
	}

	&-content {
		background: $neutral-4;

		.button {
			display: flex;
			justify-content: center;
			margin: 0 1rem;
			padding: .5rem !important;
		}

		p:first-child {
			margin-top: 0;
		}
	}
}