﻿/// <reference path="../../main">

$staff-image-max-width: 10rem;

// GRID AND CONTAINER STRUCTURE
.#{$adage-prefix}staff {
    &-container {
        margin-top: $gap-sm;
        margin-bottom: $gap;
        @extend %simple-2-col-container;
    }

    &-item {
        @extend %simple-2-col-item;
    }
}


// ITEM STRUCTURE
.#{$adage-prefix}staff {
    &-item {
        display: flex;
        align-items: flex-start;

        &-img {
            flex: 0 0 auto;
            width: 25%;
            max-width: $staff-image-max-width;
            @include responsive-property('margin-right', $gutter-map);
        }

        &-content {
            flex: 1 1 auto;
            max-width: $max-line-length / 2;
        }
    }
}

// ITEM STYLING
.#{$adage-prefix}staff {
    &-item {
        &-img {
            .#{$adage-prefix}image-block {
                position: relative;
                height: 0;
                padding-bottom: 100%;
                overflow: hidden;
                border-radius: 999rem; //arbitrarily big number to guarantee circle
                & > * {
                    position: absolute;
                }
            }
        }

        &-role {
            @include font-size($small-font-sizes);
            color: $neutral-2;
        }

        &-info {
            margin-top: $space;
        }
    }
}
