/// <reference path="../../main">

.cpg-header-image-block {
    margin-bottom: .25rem;
    
    &.border {
        border-bottom: 1px solid $neutral-3;
    }

    .cpg-header-img-wrapper {
        width: 100%;
        max-height: 15rem;
        overflow: hidden;
    }

    .cpg-header-img {
        width: 100%;
    }

    .cpg-header-image-title {
        display: flex;
        align-items: center;

        h2 {
            margin: .5rem 0;
            flex-grow: 1;
        }

        &.Orange {
            h2 {
                color: $accent-1;
            }
        }

        .link-block {
            margin: 0;
        }


        .Secondary {
            padding: .25rem .75rem;
            border-radius: 0;
            width: unset;
        }
    }


    .Black {
        h2 {
            color: $primary-text;
        }
    }

    .Orange {
        h2 {
            color: $accent-1;
        }
    }

    .Blue {
        h2 {
            color: $primary-medium;
        }
    }

    svg {
        width: 3.5rem;
        height: 3.5rem;
        margin: .5rem .25rem;
    }
}