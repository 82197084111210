﻿/// <reference path="../../main">
$standard-border-width: 1px;

// ONLY apply margins when the block is a section directly inside the <main>.
main > .#{$adage-prefix}block
.cp-main > .#{$adage-prefix}block {
	@extend %standard-margin-v;
}

// ONLY apply side padding when the block is a section directly inside the <main>.
main > .#{$adage-prefix}block > .#{$adage-prefix}block-inner {
	@extend %standard-padding-h;
}

.#{$adage-prefix}block {

	& > &-inner {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	// When two full-bleed blocks are adjacent, the margin should disappear
	&-full-bleed + &-full-bleed {
		margin-top: 0;
	}
}

// Override to margins above so that adjacent full-bleed blocks can be flush
main > .#{$adage-prefix}block-full-bleed {
	margin-bottom: 0;
}




// BASIC STYLES
.#{$adage-prefix}block {

	&-title {
		font-size: pxToRem(20px); // arbitrary size between h2 and h3
		line-height: pxToRem(28px); // respective line height
		color: $primary-text;
		border-bottom: $standard-border-width solid $neutral-3;
		padding-bottom: $space;
		margin-bottom: $gap-sm;
	}


	// CUSTOMIZATIONS FOR WYSIWYG BLOCK
	&.bottom-border > .#{$adage-prefix}block-inner {
		border-bottom: 1px solid $neutral-3;
		/*@extend %tiny-padding-v;
		@extend %small-padding-h;*/
	}

	&.top-border > .#{$adage-prefix}block-inner {
		border-top: 1px solid $neutral-3;
		@extend %tiny-padding-v;
	}

	&.left-border > .#{$adage-prefix}block-inner {
		border-left: 1px solid $neutral-3;
		@extend %tiny-padding-v;
		@extend %small-padding-h;
	}

	&.right-border > .#{$adage-prefix}block-inner {
		border-right: 1px solid $neutral-3;
		@extend %tiny-padding-v;
		@extend %small-padding-h;
	}

	&.blue-highlight > .#{$adage-prefix}block-inner {
		background: $accent-9;
		@extend %tiny-padding-v;
		@extend %tiny-padding-h;
	}

	&.blue-highlight {
		margin-top: 1rem !important;
	}

	&.gray-highlight > .#{$adage-prefix}block-inner {
		background: $neutral-4;
		@extend %tiny-padding-v;
		@extend %tiny-padding-h;
	}
}
