.cpg-audience-member-container {
    position: relative;
    padding: 1rem;
    padding-bottom: 0;

    ul {
        position: absolute;
        top: 4rem;
        right: 0;
        z-index: 1;
        max-width: 10.5rem;

        &.last {
            right: -5rem;
        }
    }

    li {
        @include font-size($medium-font-sizes);
        margin-bottom: .25rem;
    }

    a {
        text-decoration: none;
    }
}

.cpg-audience-member-title {
    @include font-size($large2-font-sizes);
    font-weight: $font-weight-normal;
    color: $accent-5;
    margin-top: 0;
    margin-bottom: 1rem;
}

.cpg-audience-member-block {
    position: relative;

    img {
        transform: translateX(-3rem);
        height: 15rem;
        width: auto;
    }
}

.cpg-audience-member-info {
    width: 12rem;
    position: absolute;
    right: 0;
    bottom: 0;
    // using rgba of $accent-3 to achieve transparency without dimming text content
    background-color: rgba(229, 184, 95, .8);
    box-shadow: -3px -2px 4px $neutral-2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1;
    padding: .5rem;
    @include font-size($small-font-sizes);

    span {
        z-index: 1;
        display: block;

        &:last-child {
            color: $offwhite;
        }
    }
}