/// <reference path="../../../ecd-styles" />

.ecd-page {

    h1, h2, 
    h1, h2 {
        font-family: $body-font;
    }

    h3, h5 {
        color: $neutral-1;
        font-family: $heading-font-medium-bold;
    }

    h1 {
        color: $neutral-1;
        font-size: $font-size-h1;
    }

    h2 {
        color: $accent-h2;
        font-size: $font-size-h2;
    }

    h3 {
        font-size: $font-size-h3;
    }

    h5 {
        font-size: $font-size-h5;
    }

    strong, bold {
        font-family: $heading-font-medium-bold;
    }

    .form-item, .form-item-item {
        label {
            font-family: $body-font;
            font-size: $font-size-normal;
            color: $neutral-1;

            & > span {
                font-family: $body-font;
                font-size: $font-size-normal;
                font-weight: $font-weight-normal;
                color: $neutral-1;
            }
        }
    }
}

.ecd-body {
    .MuiTypography-root,
    .MuiMenuItem-root,
    .MuiInputBase-input {
        font-family: $body-font;
        font-size: $font-size-normal;
        font-weight: $font-weight-normal;
    }
    .MuiInputBase-input {
        @include media(small) {
            font-size: 16px; /// hotfix . this file needs to be refactored and cleaned up to remove repetive overwrites
        }
    }
    .MuiSelect-root select {
        @extend %body;
    }
}