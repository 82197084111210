﻿
// SIDEBAR NAV

.cpg-sidebar {
    border-top: 2px solid $primary-medium;
    border-bottom: 2px solid $primary-medium;

    a {
        color: $primary-text;
        text-decoration: none;
        padding: remToPx(.5rem);
        padding-left: 0;

        &.current {
            font-weight: $font-weight-bold;
            color: $primary-medium;
        }
    }

    .svg-icon {
        height: remToPx(1rem);
        width: remToPx(1rem);
        top: 0;
        bottom: 0;
    }


    li {
        display: flex;
        flex-direction: column;
    }
}

.cpg-sidebar-links {
    display: flex;
    flex-direction: column;
}

.cpg-sidebar-link.primary {
    border-bottom: 1px solid $neutral-3;
    display: flex;
    flex-flow: column;

    span {
        background-size: remToPx(.7rem);
        background-position: center right 7px;
        background-repeat: no-repeat;

        &.active {
            background-size: remToPx(1rem);
            background-image: url('~/Static/img/chevron-down-solid-orange.svg');
        }
    }

    &:hover {
        span:not(.active) {
            background-image: url('~/Static/img/chevron-right-solid-orange.svg');
        }
    }

    a {
        padding-right: 0;
        font-size: 14px;
        line-height: 1.618;
    }
}

.cpg-sidebar-links.secondary {
    display: none;

    &.active {
        display: block;
    }
}

.cpg-sidebar-link.secondary {
    &.current {
        background: $neutral-4;
    }

    a {
        padding-left: remToPx(1rem);


        &:hover {
            background: $neutral-3;
        }
    }
}

.cpg-sidebar-links.tertiary {

    &.current {
        background: $neutral-4;
    }

    a {
        padding: 7px 14px 7px 28px;
    }
}

.cpg-sidebar-links.fourth {
    a {
        padding: 7px 14px 7px 42px;
    }
}

.cpg-sidebar-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;

    &:hover {
        background: $neutral-3;
    }

    a {
        flex: 1 1 75%;
    }

    span {
        flex: 1 1 10%;
        cursor: pointer;
    }
}

.cpg-audience-select {
    width: 160px;
    font-size: 12px;
    color: #f37321;
    padding: 3.5px 0;
    background: #f5f5f5;
    border-radius: 3px;
    height: 28px;
}

img {
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
}
