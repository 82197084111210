﻿/// <reference path="../../main">

$highlight-item-image-max-width: pxToEm(250px);

// STRUCTURE
.#{$adage-prefix}highlight-item {
    display: flex;
    
    &-clickable {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        & > * {
            min-height: 1px; //IE bugfix
            flex-shrink: 0;
        }
    }
}

.#{$prefix}highlight-item {
    display: flex;
    align-items: flex-start;
    // flex-direction: row-reverse;
    padding: 2.9rem 0;
 
    &.border {
        border-top: 1px solid $neutral-3;
    }

    &-content {
       
        margin-left: 2.1rem;

        h3 {
            margin-top: 0;
        }

        .btn {
            margin-top: $gap-sm;
        }
    }

    &-image {
        width: $highlight-item-image-max-width;
        flex: 1 0 auto;

        .#{$adage-prefix}block,
        .#{$adage-prefix}block-inner {
            margin: 0;
            height: 100%;
        }

        .#{$adage-prefix}image-block {
            position: relative;
            overflow: hidden;

            img {
                height: $highlight-item-image-max-width;
                width: $highlight-item-image-max-width;
                object-fit: cover;
            }
        }
    }
}

// BASIC STYLING
.#{$adage-prefix}highlight-item {
    &-clickable {
        text-decoration: none;
        color: $primary-dark;

        &:hover, &:focus, &:active {
            color: $primary-dark;
        }
    }

    &-details {
        @include responsive-property('padding', $card-padding-map);
    }

    &-content {
        
    }

    &-cta {
        flex-grow: 0;
        @include responsive-property('padding-left', $card-padding-map);
        @include responsive-property('padding-right', $card-padding-map);
        @include responsive-property('padding-bottom', $card-padding-map);
    }
}

.#{$prefix}highlight-item {
	
	&-title {
		color: $primary-text;
	}
}


// UI STYLING
.#{$adage-prefix}highlight-item {
    box-shadow: $shadow;
}
.cpg-main {  /// Styling for IE 
    & > div {
        & > .row {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: block;
            }
        }
    }
}