﻿/// <reference path="../../main">

// STRUCTURE/LAYOUT
.#{$adage-prefix}gallery {

    &-heading {
        @extend %standard-padding-h;
        position: absolute;
        top: 9rem;
        left: 10%;
        z-index: 99;
        background: $white;
        opacity: 0.8;
        color: $accent-1;
        padding: .25rem;
        text-transform: uppercase;
    }

    &-container {
        margin-bottom: $gap-sm / 2;
        width: $max-width;
    }

    &-item {
        &-caption {

            @include media-min(medium) {
                display: flex;
                margin-top: $gap-sm;

                &-details {
                    flex: 0 1 auto;
                    width: calc(75% - #{$gap});
                    padding-right: $gap;
                    order: -1;
                }

                &-page {
                    flex: 1 0 auto;
                    text-align: right;
                }
            }

            @include media(medium) {

                &-details {
                    margin-top: $gap-sm;
                }

                &-page {
                    text-align: center;
                }
            }

            @include media(small) {
                margin-top: $space;

                &-details {
                    margin-top: $space;
                }
            }
        }
    }

    .adage-image-block img {
        width: 100%;
    }
}


// STYLING
.#{$adage-prefix}gallery {
    &-container {
        overflow: hidden;
    }

    &-item {
        position: relative;

        &:before { // cover on top of inactive elements
            content: '';
            @extend %full-cover;
            // match to background of block, if not $primary-light
            background: rgba($primary-light, .75);
            transition: $transition;
            pointer-events: none;
            opacity: 0;
            z-index: 1;
        }

        &:not(.swiper-slide-active) {
            &:before {
                opacity: 0;
            }
        }

        &-caption {
            @include font-size($small-font-sizes);
            color: $neutral-2;
        }
    }
}

// ARROWS
.#{$adage-prefix}gallery {
    &-btn {
        &-next, &-prev {
            position: absolute;
            top: 10rem;
            z-index: 10;
            margin-top: -$button-size / 2;
            color: $primary-light;
            background-color: $neutral-2;
            height: 5rem;
            width: 2rem;
            border: 0;
            cursor: pointer;
            transition: $transition;
            padding: .2rem;
            opacity: .8;

            svg {
                height: 4.5rem;
                width: 100%;
                fill: $primary-light;
                transition: $transition;
            }

            &:focus, &:hover, &:active {
                background: $primary-light;
                color: $primary-medium;
                box-shadow: $shadow;

                svg {
                    fill: $primary-medium;
                }
            }

            @include media(small) {
                margin-top: -$button-size;
                padding: .6rem;
            }
        }

        &-next {
            right: 1rem;
        }

        &-prev {
            left: 1rem;
        }
    }
}

//Pagination
