.cpg-otp-block {
    .cpg-otp-heading {
        color: $primary-text;
        @include font-size($medium-large-font-sizes);
        font-weight: $font-weight-bold;
        margin-bottom: .5rem;
    }

    .on-this-page-link-list {
        li {
            position: relative;
            padding-bottom: .75rem;
            padding-left: 1.5rem;
            background: url('~/Static/img/png-icons/double-chevron.png') no-repeat;
            background-position-y: .15rem;
            background-position-x: 0;
            margin-right: $space;
        }

        a {
            text-transform: capitalize;
            margin: .5rem 0;
            text-decoration: none;
            cursor: pointer;
            font-size: 1rem;
        }
    }
}
