    // ------------- MEDIA QUERIES ------------- //

//@media (max-width:$x-large-media-query-size) 
$x-large-media-query-size: pxToEm(1600px);	 

//@media (max-width:$large-media-query-size)
$large-media-query-size: pxToEm(1280px);		

//@media (max-width:$medium-media-query-size)
$medium-media-query-size: pxToEm(1024px);

//@media (max-width:$tablet-media-query-size)
$tablet-media-query-size: pxToEm(767px);	

//@media (max-width:$small-media-query-size)
$small-media-query-size: pxToEm(640px);	

//@media (max-width:$mobile-media-query-size)
$mobile-media-query-size: pxToEm(340px); 

// ------------- BREAKPOINTS MAP ------------- //

$breakpoints: ( 
    x-large:		$x-large-media-query-size,
    large:			$large-media-query-size, 
    medium:			$medium-media-query-size, 
    tablet:			$tablet-media-query-size, 
    small:			$small-media-query-size,
    mobile:			$mobile-media-query-size 
);

// ------------- COLORS ------------- //

$white:				#ffffff !default;
$black:				#000000 !default;

// ------------- CPG COLORS ------------- //

$neutral-1:			#303030 !default;
$neutral-2:			#818181 !default;
$neutral-3:			#e9e9e9 !default;
$neutral-4:			#f5f5f5 !default;
$neutral-5:			#d8d8d8 !default;
$neutral-6:			#ddd !default;
$primary-dark:		#062f55 !default;
$primary-medium:	#0a4181 !default;
$primary:			#0079c1 !default;
$primary-light:		#c1d7e9 !default;
$accent-1:			#f37321 !default;
$accent-1-dark:		#e54d1b !default;
$accent-1-darker:	#E64C00 !default;
$accent-2:			#578c28 !default;
$accent-2-dark:		#4a7821 !default;
$accent-2-light:	#72a347 !default;
$accent-3:			#ffd35f !default;
$accent-4:			#5f259f !default;
$accent-5:          #7f6634 !default;
$accent-6:          #27ae60 !default;
$accent-7:          #b8e986 !default;
$accent-8:          #514721 !default;
$accent-9:          #DFE9F1 !default;
$accent-10:         #E3A100 !default;

$accent-h2:         #174983 !default;
$accent-hr:         #e1e1e1 !default;
$accent-asterisk:   #ad3333 !default;
$accent-focused:   #767676 !default;
                    

$offwhite:		    #fffbbf !default;
$golden-glow:       #fedf9a !default;

$light-blue:        #d4ecf3 !default;
$light-pink:        #fbe3e4 !default;
$light-pink-border: #fbc2c4 !default;
$light-yellow:      #fffbef !default;
$light-yellow-border:      #ffeebe !default;
$red:               #BF0001;
$red-2:              #a50519;
$dark-red:          #9d1d38;
$light-red:         #FCC2C5;
$blue-light:        #A1B7D0;
$blue:              #0F6FA8 !default;
$gray-light:        #BBBBBB !default;
$gray-verylight:    #E1E1E1;

$gray-dark: #5f5c55 !default;
$gray-border:   #c1c1c1 !default;
$primary-text: $neutral-1 !default;
$brown-text:        #514721 !default;
$red-text:          #8a1f11 !default;
$blue-text:         #205791 !default;


// BOX COLORS //
$box-blue: #e5ecf9;
$box-neutral: #fbfdfe;
$box-border: #acdae7;

// UNIQUE COLORS FROM CREATE ACCOUNT PAGES //
$create-account-blue-1: #cce9f7 !default;
$create-account-blue-2: #1b7ba9 !default;
$create-account-blue-3: #5eb9e6 !default;
$create-account-blue-4: #d5edf8 !default;
$create-account-blue-5: #205791 !default;
$create-account-blue-6: #92cae4 !default;
$create-account-blue-7: #1c7abf !default;
$create-account-green-1: #E6EFC2 !default;
$create-account-yellow-1: #FFDC7F !default;
$create-account-yellow-2: #FEBD1E !default;
$create-account-text: #333 !default;
$create-account-error-red-border: #79162b;
$create-account-error-red-background: #9d1d38;
$create-account-error-red-text: #ffdce3;


// UNIQUE COLORS FROM SEARCH PAGE //
$search-gray-1: #fcfbf9 !default;
$search-gray-2: #f0eeed !default;
$search-gray-3: #9c9a94 !default;
$search-gray-4: #f2f2f2 !default;
$search-gray-5: #707070 !default;
$search-gray-6: #BBBBBB !default;
$search-blue-light: #f2fcff !default;
$search-blue-dark: #2b5a67 !default;

$primary-btn-color: #038A3F;
$primary-btn-color-border: #02612C;
$primary-btn-color-hover: #02612C;

$secondary-btn-color: #2081D9;
$secondary-btn-color-border: #80B7E9;
$secondary-btn-color-hover: #174983;
$secondary-btn-shadow-hover: #CECECE;

// ------------- FONTS ------------- //
$body-font: 'Helvetica Neue LT W05_55 Roman', Arial, sans-serif !default;
$body-font-italic: 'Helvetica Neue LT W05_56 Italic', Arial, sans-serif !default;
$heading-font-medium: 'Helvetica Neue LT W05_65 Medium', Arial, sans-serif !default;
$heading-font-bold: 'Helvetica Neue LT W05_75 Bold', Arial, sans-serif !default;
$heading-font-medium-bold: "Helvetica Neue LT W05_75 Bold", "Helvetica", "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;

// ------------- FONT WEIGHTS ------------- //

$font-size-normal:		14px !default;
$font-size-h1:		34px !default;
$font-size-h2:		26px !default;
$font-size-h3:		22px !default;
$font-size-h5:		16px !default;
$font-weight-normal:		400 !default;
$font-weight-bold:			700 !default;

// ------------- CONSTANTS ------------- //

$gap-lg:					5rem	!default;
$gap:						2.5rem	!default;
$gap-sm:					1rem	!default;
$gap-md:					1.5rem	!default;
$space-lg:					.75rem	!default;
$space:						.5rem	!default;
$space-sm:					.25rem	!default;
$max-line-length:			52rem	!default;	// 730px
$max-input-width:			30rem	!default;
$max-width:					68.5rem	!default;	// 960px
$max-width-lg:					85rem	!default;	// 1190px
$rem-conversion-scale:      14; //Default rem scale conversion for CPG web project
$ui-icon-thickness:         2px !default; //for pseudoelement arrows, close buttons, etc
$ui-icon-size:              .75rem !default;

$border-radius:             3px !default;
$button-size:               3rem !default; //for gallery navs, etc

$btn-padding: $space-lg 2rem;
$gap-form:					24px	!default;
$gap-results:			    20px	!default;

// ------------- MISCELLANEOUS --------------- //
$prefix:			'cpg-' !default; //prefix for project-specific classes
$adage-prefix:		'adage-' !default; //prefix for project-specific classes
$ecd-prefix:        'ecd-' !default; //prefix for project-specific classes
$transition:		.2s !default;
$shadow:			.16rem .16rem .6rem rgba($primary-dark, .3);

// ------------- PADDING/MARGIN MAPS --------- //

$standard-padding-map: ( // for global use on most elements. Extend %standard-padding-h or %standard-padding-v
        null        : 5rem,
        large       : 4rem,
        medium      : 2.5rem,
        small       : 1.5rem
    );

$small-padding-map: ( // for global use on most elements. Extend %small-padding-h or %small-padding-v
        null        : 2.5rem,
        large       : 2rem,
        medium      : 1.25rem,
        small       : .75rem
    );

$tiny-padding-map: ( // for global use on most elements. Extend %tiny-padding-h or %tiny-padding-v
        null        : 2.125rem,
        large       : 1.75rem,
        medium      : 1rem,
        small       : .5rem
    );

$block-margin-map: ( // for use between blocks. Extend %standard-margin-v
        null        : 6rem,
        large       : 5rem,
        medium      : 4rem,
        small       : 2rem
    );

$block-margin-small-map: ( // for use between blocks. Extend %standard-margin-v
        null        : 3rem,
        large       : 2.5rem,
        medium      : 2rem,
        small       : 1rem
    );

$gutter-map: (  // for use in anything with columns. Use mixin responsive-property('margin-right', $gutter-map), etc. 
                // DON'T REMOVE ANY BREAKPOINTS.
        null        : 2.25rem,
        large       : 1.5rem,
        medium      : 1.5rem,
        small       : 1.25rem
    );

$card-padding-map: ( // for use with cards or smaller sub-components. Use mixin responsive-property('margin-right', $gutter-map), etc
        null        : 1.5rem,
        large       : 1.25rem,
        small       : 1rem
    );

$giant-padding-map: ( // for use with galleries
        null        : 14rem,
        large       : 10rem,
        medium      : 4rem,
        small       : 3.5rem
    );

$text-spacing-map: ( //spacing between text elements inside a .rich-text container
        null        : 1rem
    );

$square-icon-size-map: (
        null        : 3rem,
        small       : 2.5rem
    );