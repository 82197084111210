﻿/// <reference path="../../main">
.cpgtextlistitemblock {
    width: 100%;
}
.list-item-block {
    border: 10px solid $accent-9;
    display: flex;
    width: 100%;
    margin-bottom: $gap;



    & > div {
        padding: $gap-sm $space-lg;
        flex: 1 1 auto;
        width: 50%;

        ul {
            list-style: disc;
            margin: $gap-sm;
            padding-left: $gap-sm;
        }
    }

    &-first {
        background: $neutral-4;
    }
}