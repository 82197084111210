﻿/// <reference path="../../../main">

// STRUCTURE
.cpg-search-result {
    padding-top: $gap;

    &-content {
        .search-bar {
            display: flex;

            .MuiAutocomplete {
                &-root {
                    display: flex;
                    width: 100%;
                }

                &-input {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    border: 1px solid $search-gray-6;
                    padding: $space;
                }
            }

            &-inner {
                display: flex;
                flex: 1;
                position: relative;
            }

            &-clear {
                @extend %un-button;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                margin-right: $gap-sm;
                display: flex;

                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                    fill: $search-gray-5;
                }
            }

            &-button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    &-list {

        .cpg-search-results-item {
            display: flex;
            padding: 1.25rem;
            margin-bottom: $gap-sm;

            &:hover {
                background-color: $search-gray-4;
            }

            &-best-bet {
                border: 1px solid $primary-medium;
                border-radius: 3px;
            }

            &-icon {
                margin-right: 1rem;
                flex-shrink: 0;

                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                    fill: #1c7abf;
                }
            }

            &-text {
                flex-grow: 1;

                h4 {
                    margin: 0 0 0.25em;

                    a {
                        background-image: none;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &-description {
                color: $search-blue-dark;
                margin: $gap-sm 0;
            }
        }
    }
}