.row {
    display: flex;

    .row {
        display: block;

        > * + * {
            margin-left: auto;
        }
    }

    > *:not(:first-child) {
        margin-left: 2rem !important;
    }

    > .col-xl-8,
    > .col-lg-8,
    > .col-md-8,
    > .col-sm-8,
    > .col-xxl-8 {
        flex: 1 1 66%;
    }

    &:last-child {
        margin-bottom: 3.7rem;
    }

    > .col-xl-6,
    > .col-lg-6,
    > .col-md-6,
    > .col-sm-6,
    > .col-xxl-6 {
        flex: 1 1 50%;
    }

    > .col-xl-4,
    > .col-lg-4,
    > .col-md-4,
    > .col-sm-4,
    > .col-xxl-4 {
        flex: 0 1 calc(33.3333% - (4rem /3));
    }

    > .col-xl-8,
    > .col-lg-8,
    > .col-md-8,
    > .col-sm-8,
    > .col-xxl-8 {
        flex: 1 1 66%;
    }

    .col-xl-12 {
        flex: 1 1 auto;
    }
    // One-off rules to allow for full width
    // on these generated wrappers
    .cpgsectioncontainerblock,
    .cpgthreecolumnborderblock,
    .cpgtableblock,
    .videoblock,
    .fiftyfiftyblock,
    .cpglargecourseblock,
    .cpgsidebarblock,
    .wysiwygblock,
    .imagetextitemblock,
    .formcontainerblock,
    .cpgsidebarlinkblock,
    .cpgreadmoreblock,
    .cpgheaderimageblock {
        width: 100%;
    }

    .cpgtabbedblock .cpgtableblock {
        width: 100%;
    }

    .cpgsmallcourseblock {
        &.col-xl-6 {
            max-width: calc(50% - 2rem);
        }
    }
}
