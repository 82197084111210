﻿/// <reference path="../../main">


// STRUCTURE
.#{$adage-prefix}accordion {
    /*max-width: $max-line-length;*/

    &-panel {
        overflow: hidden;
        transition: $transition height;

        &[aria-hidden="true"] {
            height: 0;
        }
    }
}


// STYLES
.#{$adage-prefix}accordion {
    margin-left: auto;
    margin-right: auto;
    // nested style here to force override of screen.css button style
    .#{$adage-prefix}accordion-trigger {
        @extend %un-button;
        @include font-size($medium-font-sizes);
        display: block;
        width: 100%;
        padding-top: $space;
        padding-bottom: $space;
        color: $primary;
        text-shadow: none;
        box-shadow: none;

        // IE11 targeting
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            width: 102%;
        }

        &:hover {
            color: $primary;
        }
    }

    &-item {
        border-top: 1px solid $neutral-3;
        padding: 0 $space;

        &:last-child {
            border-bottom: 1px solid $neutral-3;
        }
    }

    &-panel {
        &-content {
            @include font-size($medium-font-sizes);
            line-height: 2rem;
            padding-top: $space;
            padding-bottom: $space;
            padding-left: $space;
        }
    }

    table {
        @extend %cpg-table;

        tr {
            border-top: 1px solid $neutral-2;
        }
    }

    ul {
        line-height: 1.25
    }

    li {
        margin-bottom: .25rem;
    }
}
// ARROW/INDICATOR
.#{$adage-prefix}accordion {
    &-trigger {
        position: relative;
        padding-right: $ui-icon-size * 3;

        &:after {
            content: '';
            width: $ui-icon-size;
            height: $ui-icon-size;
            display: block;
            position: absolute;
            right: 0rem;
            top: 50%;
            transform:zrotate(-45deg);
            transition: $transition transform;
            border-color: $neutral-2;
            border-style: solid;
            border-width: 0 $ui-icon-thickness $ui-icon-thickness 0;

            // IE11 targeting
            @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                right: .5rem;
            }
        }

        &[aria-expanded="true"] {
            &:after {
                transform: translateY(#{-(hypotenuse($ui-icon-size, $ui-icon-size) / 2)}) rotate(45deg);
            }
        }
    }
}

.accordioncontainerblock{
    width: 100%;
}
