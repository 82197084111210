/// <reference path="../../main.scss" />

$primary-btn-color: #1c7abf;
$primary-btn-color-border: #18669f;
$primary-btn-color-hover: #3791d3;

$btn-padding: .625rem 1.75rem;

%btn {
    display: inline-block;
    padding: $btn-padding;
    text-decoration: none;
    border-radius: $border-radius;
    border: 1px solid transparent;
    font-family: $body-font;
    @include font-size($reg-font-sizes);

    &[disabled] {
        cursor: not-allowed;
        opacity: .5;
    }
}

.btn-primary, %btn-primary, .Primary, a.button {
    @extend %btn;
    font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
    cursor: pointer;
    background-color: $primary-btn-color;
    /*@include link-helper($white, $white);*/
    color: $white;
    border-color: $primary-btn-color;
    border-bottom: 2px solid $primary-btn-color-border;

    &:hover, &:active, &:focus {
        background-color: $primary-btn-color-hover;
        border-color: $primary-btn-color-hover;
        border-bottom-color: $primary-btn-color-border;
        background-image: none;
        color: $white;
    }
}

.btn-secondary, %btn-secondary, .Secondary {
    @extend %btn;
    background-color: $accent-2;
    @include link-helper($white, $white);
    border-color: $accent-2;
    border-bottom: 2px solid $accent-2-dark;
    color: $white;
    letter-spacing: .03rem;
    font-family: "Helvetica Neue LT W05_65 Medium", "Helvetica", Arial, sans-serif;
    background-image: none !important;

    &:hover, &:active, &:focus {
        background-color: $accent-2-light;
        border-color: $accent-2-light;
        border-bottom-color: darken($accent-2, 10%);
    }
}

.btn-neutral, %btn-neutral, .Neutral, .button-neutral {
    @extend %btn;
    background-color: $neutral-3;
    border-bottom: 2px solid lightgrey;
    color: $primary-btn-color;
    background-image: none !important;

    &:hover, &:active, &:focus {
        background-color: $neutral-4;
        color: $primary-btn-color;
    }
}

.btn-tertiary, %btn-tertiary, .Tertiary {
    @extend %btn;
    background: linear-gradient($accent-1, $accent-1-darker) !important;
    border-color: $accent-1-dark !important;
    color: $white;
    padding: .25rem .75rem !important;
    font-weight: $font-weight-bold;
    text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
    box-shadow: 0px 1px 1px rgba(0,0,0,0.5);
    background-image: none !important;

    &:hover, &:active, &:focus {
        background: linear-gradient($accent-1-darker, $accent-1);
    }
}


%un-button, .un-button, .Hyperlink {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline;
    color: $primary-dark;
    @include font-size($reg-font-sizes);
    text-align: left;
    @extend %body;

    &:hover, &:focus {
        background: transparent;
        color: $primary-dark;
    }
}

.new-button {
    @extend %btn-secondary;
    text-decoration: none;
    padding: .24rem 2rem .27rem 2rem;
    border-radius: 3px;
    letter-spacing: .03rem;
    font-family: "Helvetica Neue LT W05_65 Medium", "Helvetica", Arial, sans-serif;
    font-size: .84rem;
}

.btn-group-container { // spacing for group of buttons
    display: flex;
    flex-wrap: wrap;

    & > [class*='btn'] {

        & + [class*='btn'] {
            margin-left: $space;
        }
    }
}
