/// <reference path="../../main">

.cpg-three-column-border-block {
    display: flex;
    justify-content: space-between;

    margin-top: 2rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    border-top: 1px solid $neutral-3;
}

.cpg-one-third-column {
    padding: 0 .75rem;
    min-height: 5rem;
    flex: 1;

    &:not(:first-child) {
        border-left: 1px solid $neutral-3;
    }

    h3 {
        margin: 0 0 .25rem;
    }

    ul {
        list-style: outside;
        padding-left: 1.5rem;
    }
}