/// <reference path="../../main">

.vertical-media-block {
    .adage-block {
        margin: 0;
    }

    .adage-video-block-caption {
        @include font-size($reg-font-sizes);
    }

    h1, h2, h3, h4, h5 {
        @include font-size($medium-large-font-sizes);
        color: $accent-1;
        font-weight: $font-weight-bold;
        padding: 1rem 0;
        margin-top: 0;
        margin-bottom: .5rem;
        border-bottom: 1px solid $neutral-3;
    }

    h3 {
        padding-bottom: .25rem;
        margin-bottom: 1.5rem;
    }

    > p {
        @include font-size($reg-font-sizes);
    }

    audio {
        width: 100%;
    }

    .adage-image-block img {
        width: 90%;
        margin-bottom: 1rem;
    }
}
