﻿/// <reference path="../../main">


// STRUCTURE
.#{$adage-prefix}wysiwyg-block {
    &:only-child {
        margin-top: 2rem;
    }

    h3 {
        font-family: $heading-font-medium;
        font-weight: $font-weight-normal;

        + hr {
            border: .5px solid $neutral-3;
            height: 1px;
        }
    }

    p, li {
        a:not(:hover) {
            text-decoration: none;
        }
    }

    h4.variation.top {
        color: $accent-1;
        font-weight: $font-weight-normal;
        font-family: $body-font;

        &:first-child {
            margin-top: 0;
        }
    }

    .gray-highlight & {
        .rich-text div {

            &:first-child {
                padding-bottom: 1rem;
            }

            &:last-child {
                padding-top: 1rem;
            }
        }
    }
    // for custom wysiwyg block with just an hr
    .rich-text hr:only-child {
        margin-top: -1rem;
    }
    // Importer imports this class and the header is too big without this
    .rich-text .tiny-span-18 h2 {
        font-size: 1.3rem;
        font-family: $heading-font-bold;
    }

    ul, li {
        list-style: outside;
        /*li  {
            list-style: outside;
            margin-bottom: $space;
        }*/
    }
}
.adage-block.blue-highlight .adage-block-inner, .adage-block.gray-highlight .adage-block-inner {
    padding: .5rem 1rem;
}


// Themed 

.cpg-theme-purple {
    .#{$adage-prefix}wysiwyg-block {
        /*padding: 0 3.5rem;*/

        /*h3 {
            color: $accent-4;
        }*/

        h4 {
            font-family: $body-font;
        }
    }
}