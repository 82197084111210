﻿/// <reference path="../../main">

// STRUCTURE & LAYOUT
.#{$adage-prefix}modal {
    &-wrapper {
        &[aria-hidden="true"] {
            visibility: hidden;
            opacity: 0;
        }

        transition: .5s opacity;
        z-index: 150;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 100vh;
        opacity: 1;
        overflow: auto;
    }

    &-wrapper.alt {
        .#{$adage-prefix}modal-dialog {
            width: 38rem;
        }

        .#{$adage-prefix}modal-dialog {
            background: $white;
            padding-top: .5rem;
        }

        .cpg-login {
            padding-top: 2rem;
        }
    }

    &-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        &:after {
            content: '';
            position: fixed;
            width: 100%;
            height: 100vh;
        }
    }

    &-dialog {
        position: relative;
        width: 100%;
        max-width: $medium-media-query-size;
        margin-left: auto;
        margin-right: auto;
        top: $gap;

        @include media(tablet) {
            width: 100%;
            bottom: auto;
        }
    }

    &-inner {
        position: relative;
        overflow: auto;
    }


    &-wrapper.narrow {
        padding: 10rem;

        .#{$adage-prefix}modal-inner {
            padding: 0;
        }
    }

    
}

// STYLING
.#{$adage-prefix}modal {
    &-wrapper {
        background-color: rgba($primary-dark, .8);
        @include responsive-property('padding', $standard-padding-map);
    }

    &-inner {
        background-color: transparent;
        @include responsive-property('padding', $standard-padding-map);

        &-full-bleed {
            padding: 0;
        }
    }

    &-upper {
        text-align: right;
        background: $primary;
        padding: 1rem;
        margin: 0 .5rem;
        border-radius: 5px;

        .#{$adage-prefix}modal-close {
            height: 1rem;
            width: 1rem;
            position: static;

            &:after,
            &:before {
                position: static;
                width: 1rem;
                height: .22rem;
            }

            &::before {
                transform: rotate(-45deg) translateX(-2px);
            }
            &::after {
                transform: rotate(45deg) translateX(-2px);
            }
        }
    }
}

// CLOSE BUTTON
.#{$adage-prefix}modal {
    &-close {
        // Uses hypotenuse of icon square size to match rotated icon size
        @extend %un-button;
        width: hypotenuse($ui-icon-size, $ui-icon-size);
        height: hypotenuse($ui-icon-size, $ui-icon-size);
        position: absolute;
        right: -$gap;
        top: -$gap;

        @include media(medium) {
            right: 0;
        }

        &:before, &:after {
            content: '';
            display: block;
            width: hypotenuse($ui-icon-size, $ui-icon-size);
            height: $ui-icon-thickness;
            background: $primary-light;
            position: absolute;
            left: 0;
            top: calc(50% - #{$ui-icon-thickness / 2});
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }
    }
}