﻿/// <reference path="../../../../ecd-styles">

// STRUCTURE
.quickfind {
    &-detail {
        &-section {
            &-title {
                background: $gray-verylight;
                padding: $space/2;

                p {
                    margin: 0;
                }
            }

            &-inner {
                padding-bottom: $gap-sm;
            }

            &-columns {                                
                grid-template-columns: repeat(2, 1fr);
                column-gap: $space/2;
                @include media-min(medium) {
                    display: grid;
                }
            }

            &-button-link {
                @extend %un-button;
                @include link-helper($blue, $blue-text);
            }
        }

        margin-top: $gap-sm;
        @extend %un-button;
        @include link-helper($blue, $blue-text);

        &-three-col-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &-scroll-container {
        height: 12.5rem;
        border: 1px solid $gray-dark;
        overflow: scroll;
        padding: $gap-sm;
    }
}
