﻿/// <reference path="../../main.scss" />

%standard-padding-h {
    @include responsive-property('padding-left', $standard-padding-map);
    @include responsive-property('padding-right', $standard-padding-map);
}

%standard-padding-v {
    @include responsive-property('padding-top', $standard-padding-map);
    @include responsive-property('padding-bottom', $standard-padding-map);
}

%standard-margin-v {
    @include responsive-property('margin-top', $block-margin-map);
    @include responsive-property('margin-bottom', $block-margin-map);
}

%small-padding-h {
    @include responsive-property('padding-left', $small-padding-map);
    @include responsive-property('padding-right', $small-padding-map);
}

%small-padding-v {
    @include responsive-property('padding-top', $small-padding-map);
    @include responsive-property('padding-bottom', $small-padding-map);
}

%small-margin-v {
    @include responsive-property('margin-top', $block-margin-small-map);
    @include responsive-property('margin-bottom', $block-margin-small-map);
}

%tiny-padding-h {
    @include responsive-property('padding-left', $tiny-padding-map);
    @include responsive-property('padding-right', $tiny-padding-map);
}

%tiny-padding-v {
    @include responsive-property('padding-top', $tiny-padding-map);
    @include responsive-property('padding-bottom', $tiny-padding-map);
}

%full-cover {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
%width-constraint {
    @extend %standard-padding-h;
    max-width: $max-width-lg;
    margin: 0 auto;
    width: 100%;
}
.hide {
    display: none;
    visibility: hidden;
}


// Pattern for a simple 2 column layout that has responsive margins and collapses to 1 column at the medium breakpoint
%simple-2-col-container {
    @include media-min(medium) {
        display: flex;
        flex-wrap: wrap;
    }
}

%simple-2-col-item {
    @include responsive-property('margin-right', $gutter-map);

    @include media-min(medium) {
        flex: 0 0 auto;
        margin-bottom: map-get($standard-padding-map, medium);

        &:nth-child(even) {
            margin-right: 0;
        }

        &:last-child, &:nth-last-child(2):nth-child(odd) {
            margin-bottom: 0;
        }
    }

    @include media-range(medium, large) {
        width: calc(50% - #{map-get($gutter-map, "large") / 2});
    }

    @include media-min(large) {
        @each $breakpoint, $value in $gutter-map { //Sass gets confused if you try to get the "null" item in a map so we have to iterate
            @if $breakpoint == null {
                width: calc(50% - #{$value / 2});
            }
        }
    }

    @include media(medium) {
        width: 100%;
        margin-bottom: map-get($standard-padding-map, medium);
        margin-right: 0;
    }
}