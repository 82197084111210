a {
    font-weight: normal;
    color: $accent-h2;
}

a:visited {
    color: $accent-h2;
}

// Applying icons to anchor links depending on the href resource type
a[href^="mailto:"],
a[href^="http://"],
a[href^="https://"],
a[href$=".doc"],
a[href$=".docx"],
a[href$=".pdf"]{
    padding-right: 1.5rem;
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: right;
}

a[href$=".pdf"] {
    background-image: url('~/Static/img/pdf.svg');
}

a[href$=".doc"], a[href$=".docx"] {
    background-image: url('~/Static/img/file.svg');
    background-size: .8rem;
}

a[href^="http://"], a[href^="https://"] {
    background-image: url('~/Static/img/external.svg');
}

a[href^="mailto:"] {
    background-image: url('~/Static/img/email.svg');
    // background-size: .8rem;
    // background-repeat: no-repeat;
}

.ss_email {
    content: url('~/Static/img/email.svg');
    height: .8rem;
}
/*Make sure that we don't show the external on certain links*/
a[href^="https://player.vimeo"], a[href^="https://test.cpg"], a[href^="https://qatest.cpg"], a[href^="https://dev.cpg"], a[href^="https://cpg"], a[href^="https://prod.cpg"], a[href^="https://www.cpg"], a[href^="http://localhost"], a[href^="http://churchpension"], a[href^="https://youtu.be"], .no-icon, .noicon {
    background-image: none !important;

    &:not(.btn) {
        padding-right: 0;
    }
}
// screen.css does not provide styles to .docx links
a[href$=".docx"] {
    background-position: right center;
}

// Remove padding from video thumbnail links
.adage-video-thumbnail-container {
    a[href^="https://youtu.be"], a[href^="http://youtu.be"], a[href^="https://www.youtube"], a[href^="http://www.youtube"] {
        padding-right: 0;
    }
}
