﻿/// <reference path="../../main">

$iframe-padding: 0.714rem;

// STRUCTURE
.#{$adage-prefix}block > .#{$adage-prefix}video-block {
    //only apply max width if at block-level
    /*max-width: $max-line-length;*/

	&-caption {
		max-width: $max-width;
		margin-top: $space;
	}
}

.#{$adage-prefix}video {

    &-iframe {
        position: relative;
        padding-bottom: ratioToPercent(16, 9);
        width: 100%;
        max-width: 100%;
        background: linear-gradient(rgba($neutral-3, .3), $neutral-3);

        & > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: $gap-md;
        }
    }

    &-native {
        max-width: 100%;

        & > video {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    &-thumbnail {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;

        &-trigger {
            cursor: pointer;
            width: 100%;
            height: 100%;
            position: relative;
            @extend %un-button;

            img {
                @extend %full-bleed-image;
            }

            figure {
                @extend %full-bleed-image-ie-replacement;
            }
        }
    }

    &-block-caption {

        ul {
            list-style: outside;
            padding-left: 1rem;
            margin-top: .5rem;

            @include font-size($reg-font-sizes);
        }
    }

    &-block,
    &-block-caption {

        .col-xl-4 & {
            padding: 0 1.25rem;
        }
    }

    &-block {
        .col-xl-4 & {
            margin-top: 1rem;
        }
    }
}

// PLAY BUTTON ON VIDEOS
$video-play-circle-size: 5rem;
$video-play-triangle-size: 1.25rem;

.#{$adage-prefix}video {
    &-thumbnail {
        &-trigger {
            &:before {
                content: '';
                display: block;
                position: absolute;
                transition: $transition;
                background: rgba($primary-medium, .5);
                width: $video-play-circle-size;
                height: $video-play-circle-size;
                border-radius: 50%;
                margin-top: -$video-play-circle-size / 2;
                margin-left: -$video-play-circle-size / 2;
                top: 50%;
                left: 50%;
                z-index: 1;
                @extend %focus;
            }

            svg {
                position: absolute;
                fill: $primary-light;
                width: $video-play-triangle-size;
                height: $video-play-triangle-size;
                margin-top: -$video-play-triangle-size / 2;
                margin-left: -$video-play-triangle-size / 2;
                top: 50%;
                left: 50%;
                z-index: 2;
            }

            &:hover, &:focus {
                &:before {
                    background: $primary-medium;
                    box-shadow: $shadow;
                }
            }
        }
    }
}

// Handle video directly inside main triggering a modal
main > .#{$adage-prefix}block > .#{$adage-prefix}video-block {

    .#{$adage-prefix}video-thumbnail {
        position: static;
    }
}

$cpg-video-play-circle-size: 3.36rem;
$cpg-video-play-circle-top-position: 2.607rem;
$cpg-video-play-circle-left-position: 5.107rem;

.adage-block-inner {
    .video-content {
        display: none;
    }

    .video-link {
        background-image: unset;
    }

    .adage-video-thumbnail {

        &-container {
            position: relative;
            display: inline-block;

            &:hover {
                cursor: pointer;
            }

            svg {
                position: absolute;
                width: $cpg-video-play-circle-size;
                height: $cpg-video-play-circle-size;
                top: calc(50% - #{$cpg-video-play-circle-size/2});
                left: calc(50% - #{$cpg-video-play-circle-size/2});
                z-index: 2;
            }
        }
    }
}