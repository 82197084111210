﻿
$generic-gallery-height: 35.71rem;
$generic-gallery-width: 50.71rem;
$generic-gallery-close-position: .5rem;
$generic-gallery-border-radius: 5px;
$btn-background-color: #eaf4fa;
$btn-font-size: 0.857rem;
$btn-border-color: #c0def0;
$btn-color: #326495;
$btn-width: 5.7rem;
$btn-width-left: 6.43rem;
$btn-height: 2.14rem;

.generic-gallery {

    &-container {
        display: none;

        &.active {
            display: flex;
            align-items: center;
        }

        .adage-modal {

            &-close {
                right: $generic-gallery-close-position;
                top: $generic-gallery-close-position;
                background: url('~/Static/img/png-icons/cross.png') no-repeat;
                z-index: 10;

                &:before {
                    content: none;
                }

                &:after {
                    content: none;
                }
            }

            &-dialog {
                padding-top: 0;
                height: $generic-gallery-height;
                width: $generic-gallery-width;
            }

            &-inner {
                height: 100%;
                padding: 0;
                background-color: white;
                border-radius: $generic-gallery-border-radius;

                .video-content {
                    height: 100%;

                    video {
                        height: 100%;
                    }
                }
            }
        }
    }

    &-btn {
        background-color: $btn-background-color;
        color: $btn-color;
        border: 1px solid $btn-border-color;
        font-weight: $font-weight-bold;
        font-size: $btn-font-size;
        width: $btn-width;
        height: $btn-height;
        padding: 0;
        border-radius: 5px;

        &.right {
            background: $btn-background-color url('~/Static/img/png-icons/arrow_right.png') no-repeat;
            background-position: 95% 50%;
            padding: 5px 15px 5px 8px;
        }

        &.left {
            background: $btn-background-color url('~/Static/img/png-icons/arrow_left.png') no-repeat;
            background-position: 5% 50%;
            margin-right: 3px;
            padding: 5px 3px 5px 15px;
            width: $btn-width-left;
        }

        &-container {
            display: flex;
            justify-content: center;
            margin-top: $space-lg;
        }
    }
}
