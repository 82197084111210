@charset "UTF-8";
/* 
html5doctor.com Reset Stylesheet v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
*/
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=1138a18e-c013-4917-bede-27d3abe0d667");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

html, body {
  height: 100%;
  font-size: 100%; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style-type: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input, select {
  vertical-align: middle; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

.sdi-map .v-application--wrap .row {
  flex-wrap: nowrap; }

a[href^="mailto:"],
a[href^="http://"],
a[href^="https://"],
a[href$=".doc"],
a[href$=".docx"],
a[href$=".pdf"] {
  padding-right: 1.5rem;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: right; }

a[href$=".pdf"] {
  background-image: url("~/Static/img/pdf.svg"); }

a[href$=".doc"], a[href$=".docx"] {
  background-image: url("~/Static/img/file.svg");
  background-size: .8rem; }

a[href^="http://"], a[href^="https://"] {
  background-image: url("~/Static/img/external.svg"); }

a[href^="mailto:"] {
  background-image: url("~/Static/img/email.svg"); }

.ss_email {
  content: url("~/Static/img/email.svg");
  height: .8rem; }

/*Make sure that we don't show the external on certain links*/
a[href^="https://player.vimeo"], a[href^="https://test.cpg"], a[href^="https://dev.cpg"], a[href^="https://cpg"], a[href^="https://prod.cpg"], a[href^="https://www.cpg"], a[href^="http://localhost"], a[href^="http://churchpension"], a[href^="https://youtu.be"], .no-icon, .noicon {
  background-image: none !important; }
  a[href^="https://player.vimeo"]:not(.btn), a[href^="https://test.cpg"]:not(.btn), a[href^="https://dev.cpg"]:not(.btn), a[href^="https://cpg"]:not(.btn), a[href^="https://prod.cpg"]:not(.btn), a[href^="https://www.cpg"]:not(.btn), a[href^="http://localhost"]:not(.btn), a[href^="http://churchpension"]:not(.btn), a[href^="https://youtu.be"]:not(.btn), .no-icon:not(.btn), .noicon:not(.btn) {
    padding-right: 0; }

a[href$=".docx"] {
  background-position: right center; }

.adage-video-thumbnail-container a[href^="https://youtu.be"], .adage-video-thumbnail-container a[href^="http://youtu.be"], .adage-video-thumbnail-container a[href^="https://www.youtube"], .adage-video-thumbnail-container a[href^="http://www.youtube"] {
  padding-right: 0; }

.adage-fiftyfifty-heading, .adage-fiftyfifty .adage-image-block figcaption, .visually-hidden, .sr-only {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }

.hide {
  display: none;
  visibility: hidden; }

@font-face {
  font-family: "Helvetica Neue LT W05_55 Roman";
  src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot");
  src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix") format("embedded-opentype"), url("../fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("../fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff"), url("../fonts/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf") format("truetype"); }

@font-face {
  font-family: "Helvetica Neue LT W05_56 Italic";
  src: url("../fonts/c708845d-fe72-4432-9276-47c4f91aec1c.eot");
  src: url("../fonts/c708845d-fe72-4432-9276-47c4f91aec1c.eot?#iefix") format("embedded-opentype"), url("../fonts/4bd56f95-e7ab-4a32-91fd-b8704cbd38bc.woff2") format("woff2"), url("../fonts/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.woff") format("woff"), url("../fonts/c07fd9e2-5e0b-4297-9229-af0c8e331d94.ttf") format("truetype"); }

@font-face {
  font-family: "Helvetica Neue LT W05_65 Medium";
  src: url("../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot");
  src: url("../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("embedded-opentype"), url("../fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"), url("../fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff"), url("../fonts/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf") format("truetype"); }

@font-face {
  font-family: "Helvetica Neue LT W05_75 Bold";
  src: url("../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot");
  src: url("../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix") format("embedded-opentype"), url("../fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"), url("../fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff"), url("../fonts/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf") format("truetype"); }

/*$medium-large-font-sizes: ( //h3
    null : (1.2rem, (1.65/1.125)),
);*/
body, .adage-modal-close, .cpg-step-link-text, .cpg-search-result-content .search-bar-clear, .adage-accordion .adage-accordion-trigger, .adage-video-thumbnail-trigger, .quickfind-autocomplete-link, .quickfind-advanced-cta, .quickfind-results-table-link, .quickfind-pagination-page-link, .quickfind-parish-results-item-link, .quickfind-clergy-results-item-link, .quickfind-detail-section-button-link, .quickfind-detail, .un-button, .Hyperlink, .ecd-body .MuiSelect-root select {
  font-size: 100%;
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif, serif;
  color: #303030;
  font-size: 0.875rem;
  line-height: 1.57143;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body ::selection, .adage-modal-close ::selection, .cpg-step-link-text ::selection, .cpg-search-result-content .search-bar-clear ::selection, .adage-accordion .adage-accordion-trigger ::selection, .adage-video-thumbnail-trigger ::selection, .quickfind-autocomplete-link ::selection, .quickfind-advanced-cta ::selection, .quickfind-results-table-link ::selection, .quickfind-pagination-page-link ::selection, .quickfind-parish-results-item-link ::selection, .quickfind-clergy-results-item-link ::selection, .quickfind-detail-section-button-link ::selection, .quickfind-detail ::selection, .un-button ::selection, .Hyperlink ::selection, .ecd-body .MuiSelect-root select ::selection {
    color: #ffffff; }
  body ::-moz-selection, .adage-modal-close ::-moz-selection, .cpg-step-link-text ::-moz-selection, .cpg-search-result-content .search-bar-clear ::-moz-selection, .adage-accordion .adage-accordion-trigger ::-moz-selection, .adage-video-thumbnail-trigger ::-moz-selection, .quickfind-autocomplete-link ::-moz-selection, .quickfind-advanced-cta ::-moz-selection, .quickfind-results-table-link ::-moz-selection, .quickfind-pagination-page-link ::-moz-selection, .quickfind-parish-results-item-link ::-moz-selection, .quickfind-clergy-results-item-link ::-moz-selection, .quickfind-detail-section-button-link ::-moz-selection, .quickfind-detail ::-moz-selection, .un-button ::-moz-selection, .Hyperlink ::-moz-selection, .ecd-body .MuiSelect-root select ::-moz-selection {
    color: #ffffff; }

a {
  transition: 0.2s;
  color: #1c7abf; }
  a:hover, a:active, a:focus {
    color: #4a9dcf; }

[data-whatinput="mouse"] a, [data-whatinput="touch"] a {
  box-shadow: none !important;
  outline: 0 !important; }

b, strong {
  font-weight: 700;
  letter-spacing: .02em; }

em, i {
  font-style: italic; }

h1 {
  font-size: 2.125rem;
  line-height: 1.11765;
  color: #303030;
  font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
  font-weight: 500; }

h2 {
  font-size: 1.75rem;
  line-height: 1.21429;
  color: #303030;
  font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
  font-weight: 500;
  margin-bottom: 1rem; }

h3 {
  font-size: 1.125rem;
  line-height: 1.11111;
  color: #f37321;
  font-family: "Helvetica Neue LT W05_75 Bold", Arial, sans-serif;
  margin: 1rem 0 .5rem 0;
  font-weight: 500;
  line-height: 1.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.11111;
  color: #303030;
  font-family: "Helvetica Neue LT W05_75 Bold", "Helvetica", "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
  font-weight: 500;
  margin: 1rem 0 .5rem 0;
  line-height: 1.5rem; }
  h4.variation {
    color: #f37321 !important; }

h5 {
  font-size: 0.875rem;
  color: #303030;
  font-family: "Helvetica Neue LT W05_75 Bold", Arial, sans-serif;
  font-weight: 700;
  margin-bottom: 1.5rem;
  letter-spacing: .006em;
  line-height: 1.5rem; }

h6 {
  font-size: 0.625rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: .5px;
  color: #303030;
  font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
  font-weight: 500; }

sup, sub {
  font-size: .65em;
  margin-left: 1px; }

sup {
  vertical-align: super; }

sub {
  vertical-align: sub; }

p {
  line-height: 1.5rem;
  margin: .875rem 0;
  letter-spacing: .008em; }

h2 + h3, h3 + p, h4 + p {
  margin-top: 0; }

hr {
  border: 0.5px solid #e9e9e9;
  height: 1px;
  margin-top: .5rem;
  margin-bottom: .5rem; }
  h2 + hr,
  h3 + hr {
    border: 0.5px solid #e9e9e9;
    height: 1px; }

hr.alternate-hr-gray {
  border-color: #e9e9e9;
  height: 1px; }

small {
  color: #818181;
  font-size: .85rem; }
  small a:not(:hover) {
    text-decoration: none; }

/*li {
    a:not(:hover) {
        text-decoration: none;
    }
}*/
a:not(:hover) {
  text-decoration: none; }

.rich-text > h1 + h1, .rich-text > h1 + h2, .rich-text > h1 + h3, .rich-text > h1 + h4, .rich-text > h1 + h5, .rich-text > h1 + h6, .rich-text > h1 + p, .rich-text > h1 + ul, .rich-text > h1 + ol, .rich-text > h2 + h1, .rich-text > h2 + h2, .rich-text > h2 + h3, .rich-text > h2 + h4, .rich-text > h2 + h5, .rich-text > h2 + h6, .rich-text > h2 + p, .rich-text > h2 + ul, .rich-text > h2 + ol, .rich-text > h3 + h1, .rich-text > h3 + h2, .rich-text > h3 + h3, .rich-text > h3 + h4, .rich-text > h3 + h5, .rich-text > h3 + h6, .rich-text > h3 + p, .rich-text > h3 + ul, .rich-text > h3 + ol, .rich-text > h4 + h1, .rich-text > h4 + h2, .rich-text > h4 + h3, .rich-text > h4 + h4, .rich-text > h4 + h5, .rich-text > h4 + h6, .rich-text > h4 + p, .rich-text > h4 + ul, .rich-text > h4 + ol, .rich-text > h5 + h1, .rich-text > h5 + h2, .rich-text > h5 + h3, .rich-text > h5 + h4, .rich-text > h5 + h5, .rich-text > h5 + h6, .rich-text > h5 + p, .rich-text > h5 + ul, .rich-text > h5 + ol, .rich-text > h6 + h1, .rich-text > h6 + h2, .rich-text > h6 + h3, .rich-text > h6 + h4, .rich-text > h6 + h5, .rich-text > h6 + h6, .rich-text > h6 + p, .rich-text > h6 + ul, .rich-text > h6 + ol, .rich-text > p + h1, .rich-text > p + h2, .rich-text > p + h3, .rich-text > p + h4, .rich-text > p + h5, .rich-text > p + h6, .rich-text > p + p, .rich-text > p + ul, .rich-text > p + ol, .rich-text > ul + h1, .rich-text > ul + h2, .rich-text > ul + h3, .rich-text > ul + h4, .rich-text > ul + h5, .rich-text > ul + h6, .rich-text > ul + p, .rich-text > ul + ul, .rich-text > ul + ol, .rich-text > ol + h1, .rich-text > ol + h2, .rich-text > ol + h3, .rich-text > ol + h4, .rich-text > ol + h5, .rich-text > ol + h6, .rich-text > ol + p, .rich-text > ol + ul, .rich-text > ol + ol {
  margin-top: 1rem; }

.rich-text ul, .rich-text ol {
  margin-left: 2rem; }

.rich-text ul {
  list-style: initial; }
  .rich-text ul li {
    margin-bottom: 0.25rem; }

.rich-text ol {
  list-style: decimal; }

.rich-text a.no-icon {
  background-image: none;
  padding-right: 0; }

.rich-text .links-container a {
  border-bottom: 0 !important; }

.btn-primary, button, submit, input[type="submit"], input[type="button"], .adage-theme-light.adage-fwcta .btn, .adage-theme-light.adage-newsletter-signup .btn, .adage-theme-default.adage-fwcta .btn, .adage-theme-default.adage-newsletter-signup .btn, .forms-publications-block .column .cpg-mail-link, .EPiServerForms .Form__Element.FormSubmitButton, .EPiServerForms .Form__Element .FormCaptcha__Refresh, .Primary, a.button, .btn-secondary, .new-button, .adage-theme-dark.adage-fwcta .btn, .adage-theme-dark.adage-newsletter-signup .btn, .cpg-wrapper-two-column .new-button, .quickfind-pagination-prev-next, .Secondary, .btn-neutral, .rich-text .links-container a, .cpg-imgtext-item.smaller .rich-text a:nth-child(2):not(.no-btn), .EPiServerForms .Form__Element.FormResetButton, .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:last-of-type, .pod .tiny-span-7 a, .Neutral, .button-neutral, .btn-tertiary, .Tertiary, .link-block a.btn, .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:first-of-type {
  display: inline-block;
  padding: 0.625rem 1.75rem;
  text-decoration: none;
  border-radius: 3px;
  border: 1px solid transparent;
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
  font-size: 0.875rem; }
  .btn-primary[disabled], button[disabled], submit[disabled], input[disabled][type="submit"], input[disabled][type="button"], .adage-theme-light.adage-fwcta .btn[disabled], .adage-theme-light.adage-newsletter-signup .btn[disabled], .adage-theme-default.adage-fwcta .btn[disabled], .adage-theme-default.adage-newsletter-signup .btn[disabled], .forms-publications-block .column .cpg-mail-link[disabled], .EPiServerForms .Form__Element.FormSubmitButton[disabled], .EPiServerForms .Form__Element .FormCaptcha__Refresh[disabled], .Primary[disabled], a.button[disabled], .btn-secondary[disabled], .new-button[disabled], .adage-theme-dark.adage-fwcta .btn[disabled], .adage-theme-dark.adage-newsletter-signup .btn[disabled], .quickfind-pagination-prev-next[disabled], .Secondary[disabled], .btn-neutral[disabled], .rich-text .links-container a[disabled], .cpg-imgtext-item.smaller .rich-text a[disabled]:nth-child(2):not(.no-btn), .EPiServerForms .Form__Element.FormResetButton[disabled], .cpg-theme-purple .cpg-language-header .cpg-language-header-content a[disabled]:last-of-type, .pod .tiny-span-7 a[disabled], .Neutral[disabled], .button-neutral[disabled], .btn-tertiary[disabled], .Tertiary[disabled], .link-block a.btn[disabled], .cpg-theme-purple .cpg-language-header .cpg-language-header-content a[disabled]:first-of-type {
    cursor: not-allowed;
    opacity: .5; }

.btn-primary, button, submit, input[type="submit"], input[type="button"], .adage-theme-light.adage-fwcta .btn, .adage-theme-light.adage-newsletter-signup .btn, .adage-theme-default.adage-fwcta .btn, .adage-theme-default.adage-newsletter-signup .btn, .forms-publications-block .column .cpg-mail-link, .EPiServerForms .Form__Element.FormSubmitButton, .EPiServerForms .Form__Element .FormCaptcha__Refresh, .Primary, a.button {
  font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
  cursor: pointer;
  background-color: #1c7abf;
  /*@include link-helper($white, $white);*/
  color: #ffffff;
  border-color: #1c7abf;
  border-bottom: 2px solid #18669f; }
  .btn-primary:hover, .btn-primary:active, .btn-primary:focus, button:hover, submit:hover, input:hover[type="submit"], input:hover[type="button"], .adage-theme-light.adage-fwcta .btn:hover, .adage-theme-light.adage-newsletter-signup .btn:hover, .adage-theme-default.adage-fwcta .btn:hover, .adage-theme-default.adage-newsletter-signup .btn:hover, .forms-publications-block .column .cpg-mail-link:hover, .EPiServerForms .Form__Element.FormSubmitButton:hover, .EPiServerForms .Form__Element .FormCaptcha__Refresh:hover, button:active, submit:active, input:active[type="submit"], input:active[type="button"], .adage-theme-light.adage-fwcta .btn:active, .adage-theme-light.adage-newsletter-signup .btn:active, .adage-theme-default.adage-fwcta .btn:active, .adage-theme-default.adage-newsletter-signup .btn:active, .forms-publications-block .column .cpg-mail-link:active, .EPiServerForms .Form__Element.FormSubmitButton:active, .EPiServerForms .Form__Element .FormCaptcha__Refresh:active, button:focus, submit:focus, input:focus[type="submit"], input:focus[type="button"], .adage-theme-light.adage-fwcta .btn:focus, .adage-theme-light.adage-newsletter-signup .btn:focus, .adage-theme-default.adage-fwcta .btn:focus, .adage-theme-default.adage-newsletter-signup .btn:focus, .forms-publications-block .column .cpg-mail-link:focus, .EPiServerForms .Form__Element.FormSubmitButton:focus, .EPiServerForms .Form__Element .FormCaptcha__Refresh:focus, .Primary:hover, .Primary:active, .Primary:focus, a.button:hover, a.button:active, a.button:focus {
    background-color: #3791d3;
    border-color: #3791d3;
    border-bottom-color: #18669f;
    background-image: none;
    color: #ffffff; }

.btn-secondary, .new-button, .adage-theme-dark.adage-fwcta .btn, .adage-theme-dark.adage-newsletter-signup .btn, .cpg-wrapper-two-column .new-button, .quickfind-pagination-prev-next, .Secondary {
  background-color: #578c28;
  transition: 0.2s;
  color: #ffffff;
  border-color: #578c28;
  border-bottom: 2px solid #4a7821;
  color: #ffffff;
  letter-spacing: .03rem;
  font-family: "Helvetica Neue LT W05_65 Medium", "Helvetica", Arial, sans-serif;
  background-image: none !important; }
  .btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus, .new-button:hover, .adage-theme-dark.adage-fwcta .btn:hover, .adage-theme-dark.adage-newsletter-signup .btn:hover, .quickfind-pagination-prev-next:hover, .new-button:active, .adage-theme-dark.adage-fwcta .btn:active, .adage-theme-dark.adage-newsletter-signup .btn:active, .quickfind-pagination-prev-next:active, .new-button:focus, .adage-theme-dark.adage-fwcta .btn:focus, .adage-theme-dark.adage-newsletter-signup .btn:focus, .quickfind-pagination-prev-next:focus, .Secondary:hover, .Secondary:active, .Secondary:focus {
    color: #ffffff; }
  .btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus, .new-button:hover, .adage-theme-dark.adage-fwcta .btn:hover, .adage-theme-dark.adage-newsletter-signup .btn:hover, .quickfind-pagination-prev-next:hover, .new-button:active, .adage-theme-dark.adage-fwcta .btn:active, .adage-theme-dark.adage-newsletter-signup .btn:active, .quickfind-pagination-prev-next:active, .new-button:focus, .adage-theme-dark.adage-fwcta .btn:focus, .adage-theme-dark.adage-newsletter-signup .btn:focus, .quickfind-pagination-prev-next:focus, .Secondary:hover, .Secondary:active, .Secondary:focus {
    background-color: #72a347;
    border-color: #72a347;
    border-bottom-color: #3e641d; }

.btn-neutral, .rich-text .links-container a, .cpg-imgtext-item.smaller .rich-text a:nth-child(2):not(.no-btn), .EPiServerForms .Form__Element.FormResetButton, .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:last-of-type, .pod .tiny-span-7 a, .Neutral, .button-neutral {
  background-color: #e9e9e9;
  border-bottom: 2px solid lightgrey;
  color: #1c7abf;
  background-image: none !important; }
  .btn-neutral:hover, .btn-neutral:active, .btn-neutral:focus, .rich-text .links-container a:hover, .cpg-imgtext-item.smaller .rich-text a:hover:nth-child(2):not(.no-btn), .EPiServerForms .Form__Element.FormResetButton:hover, .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:hover:last-of-type, .pod .tiny-span-7 a:hover, .rich-text .links-container a:active, .cpg-imgtext-item.smaller .rich-text a:active:nth-child(2):not(.no-btn), .EPiServerForms .Form__Element.FormResetButton:active, .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:active:last-of-type, .pod .tiny-span-7 a:active, .rich-text .links-container a:focus, .cpg-imgtext-item.smaller .rich-text a:focus:nth-child(2):not(.no-btn), .EPiServerForms .Form__Element.FormResetButton:focus, .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:focus:last-of-type, .pod .tiny-span-7 a:focus, .Neutral:hover, .Neutral:active, .Neutral:focus, .button-neutral:hover, .button-neutral:active, .button-neutral:focus {
    background-color: #f5f5f5;
    color: #1c7abf; }

.btn-tertiary, .Tertiary {
  background: linear-gradient(#f37321, #E64C00) !important;
  border-color: #e54d1b !important;
  color: #ffffff;
  padding: .25rem .75rem !important;
  font-weight: 700;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  background-image: none !important; }
  .btn-tertiary:hover, .btn-tertiary:active, .btn-tertiary:focus, .Tertiary:hover, .Tertiary:active, .Tertiary:focus {
    background: linear-gradient(#E64C00, #f37321); }

.adage-modal-close, .cpg-step-link-text, .cpg-search-result-content .search-bar-clear, .adage-accordion .adage-accordion-trigger, .adage-video-thumbnail-trigger, .quickfind-autocomplete-link, .quickfind-advanced-cta, .quickfind-results-table-link, .quickfind-pagination-page-link, .quickfind-parish-results-item-link, .quickfind-clergy-results-item-link, .quickfind-detail-section-button-link, .quickfind-detail, .un-button, .Hyperlink {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: inline;
  color: #062f55;
  font-size: 0.875rem;
  text-align: left; }
  .adage-modal-close:hover, .cpg-step-link-text:hover, .cpg-search-result-content .search-bar-clear:hover, .adage-accordion .adage-accordion-trigger:hover, .adage-video-thumbnail-trigger:hover, .quickfind-autocomplete-link:hover, .quickfind-advanced-cta:hover, .quickfind-results-table-link:hover, .quickfind-pagination-page-link:hover, .quickfind-parish-results-item-link:hover, .quickfind-clergy-results-item-link:hover, .quickfind-detail-section-button-link:hover, .quickfind-detail:hover, .adage-modal-close:focus, .cpg-step-link-text:focus, .cpg-search-result-content .search-bar-clear:focus, .adage-accordion .adage-accordion-trigger:focus, .adage-video-thumbnail-trigger:focus, .quickfind-autocomplete-link:focus, .quickfind-advanced-cta:focus, .quickfind-results-table-link:focus, .quickfind-pagination-page-link:focus, .quickfind-parish-results-item-link:focus, .quickfind-clergy-results-item-link:focus, .quickfind-detail-section-button-link:focus, .quickfind-detail:focus, .un-button:hover, .un-button:focus, .Hyperlink:hover, .Hyperlink:focus {
    background: transparent;
    color: #062f55; }

.new-button {
  text-decoration: none;
  padding: .24rem 2rem .27rem 2rem;
  border-radius: 3px;
  letter-spacing: .03rem;
  font-family: "Helvetica Neue LT W05_65 Medium", "Helvetica", Arial, sans-serif;
  font-size: .84rem; }

.btn-group-container {
  display: flex;
  flex-wrap: wrap; }
  .btn-group-container > [class*='btn'] + [class*='btn'] {
    margin-left: 0.5rem; }

input[type=text], input[type=number], input[type=password], input[type=tel], input[type=email], input[type=search], input[type=url], input[type=date], input[type=month], input[type=week], input[type=time], input[type=datetime], input[type=datetime-local], select, textarea {
  /*minimum size for form element font-size to prevent iOS resizing is 16px*/
  font-size: 1rem;
  /*
    line-height: 1.75;
    padding: $space $gap-sm;
    width: 100%;
    max-width: $max-input-width;
    border: 1px solid $neutral-2;
    border-radius: $border-radius;
    font-family: $body-font;

    &:invalid:not(:focus) {
    }

    &[disabled] {
        cursor: not-allowed;
    }*/ }
  input:focus[type=text], input:focus[type=number], input:focus[type=password], input:focus[type=tel], input:focus[type=email], input:focus[type=search], input:focus[type=url], input:focus[type=date], input:focus[type=month], input:focus[type=week], input:focus[type=time], input:focus[type=datetime], input:focus[type=datetime-local], select:focus, textarea:focus {
    box-shadow: none; }

select, textarea {
  width: 10rem;
  color: #f37321;
  padding: .25rem 0;
  background: #f5f5f5; }

textarea {
  height: 5rem; }

label {
  font-size: 0.875rem;
  font-weight: 700;
  display: block;
  line-height: 2; }
  label:not(.visually-hidden) ~ * {
    margin-top: 0.5rem; }

.adage-form-row {
  margin-top: 1rem;
  align-items: flex-end; }

.adage-video-thumbnail-trigger:before {
  /* @include focus(); TODO create scss focus styles outside of react styles*/ }

.row {
  display: flex; }
  .row .row {
    display: block; }
    .row .row > * + * {
      margin-left: auto; }
  .row > *:not(:first-child) {
    margin-left: 2rem !important; }
  .row > .col-xl-8,
  .row > .col-lg-8,
  .row > .col-md-8,
  .row > .col-sm-8,
  .row > .col-xxl-8 {
    flex: 1 1 66%; }
  .row:last-child {
    margin-bottom: 3.7rem; }
  .row > .col-xl-6,
  .row > .col-lg-6,
  .row > .col-md-6,
  .row > .col-sm-6,
  .row > .col-xxl-6 {
    flex: 1 1 50%; }
  .row > .col-xl-4,
  .row > .col-lg-4,
  .row > .col-md-4,
  .row > .col-sm-4,
  .row > .col-xxl-4 {
    flex: 0 1 calc(33.3333% - (4rem /3)); }
  .row > .col-xl-8,
  .row > .col-lg-8,
  .row > .col-md-8,
  .row > .col-sm-8,
  .row > .col-xxl-8 {
    flex: 1 1 66%; }
  .row .col-xl-12 {
    flex: 1 1 auto; }
  .row .cpgsectioncontainerblock,
  .row .cpgthreecolumnborderblock,
  .row .cpgtableblock,
  .row .videoblock,
  .row .fiftyfiftyblock,
  .row .cpglargecourseblock,
  .row .cpgsidebarblock,
  .row .wysiwygblock,
  .row .imagetextitemblock,
  .row .formcontainerblock,
  .row .cpgsidebarlinkblock,
  .row .cpgreadmoreblock,
  .row .cpgheaderimageblock {
    width: 100%; }
  .row .cpgtabbedblock .cpgtableblock {
    width: 100%; }
  .row .cpgsmallcourseblock.col-xl-6 {
    max-width: calc(50% - 2rem); }

.adage-header-inner-lg, .ecd-header-inner, .ecd-footer-inner, .ecd-page, main > .adage-block > .adage-block-inner, .adage-gallery-heading, .adage-fwcta, .adage-newsletter-signup-inner {
  padding-left: 5rem;
  padding-right: 5rem; }
  @media screen and (max-width: 1280px) {
    .adage-header-inner-lg, .ecd-header-inner, .ecd-footer-inner, .ecd-page, main > .adage-block > .adage-block-inner, .adage-gallery-heading, .adage-fwcta, .adage-newsletter-signup-inner {
      padding-left: 4rem; } }
  @media screen and (max-width: 1024px) {
    .adage-header-inner-lg, .ecd-header-inner, .ecd-footer-inner, .ecd-page, main > .adage-block > .adage-block-inner, .adage-gallery-heading, .adage-fwcta, .adage-newsletter-signup-inner {
      padding-left: 2.5rem; } }
  @media screen and (max-width: 640px) {
    .adage-header-inner-lg, .ecd-header-inner, .ecd-footer-inner, .ecd-page, main > .adage-block > .adage-block-inner, .adage-gallery-heading, .adage-fwcta, .adage-newsletter-signup-inner {
      padding-left: 1.5rem; } }
  @media screen and (max-width: 1280px) {
    .adage-header-inner-lg, .ecd-header-inner, .ecd-footer-inner, .ecd-page, main > .adage-block > .adage-block-inner, .adage-gallery-heading, .adage-fwcta, .adage-newsletter-signup-inner {
      padding-right: 4rem; } }
  @media screen and (max-width: 1024px) {
    .adage-header-inner-lg, .ecd-header-inner, .ecd-footer-inner, .ecd-page, main > .adage-block > .adage-block-inner, .adage-gallery-heading, .adage-fwcta, .adage-newsletter-signup-inner {
      padding-right: 2.5rem; } }
  @media screen and (max-width: 640px) {
    .adage-header-inner-lg, .ecd-header-inner, .ecd-footer-inner, .ecd-page, main > .adage-block > .adage-block-inner, .adage-gallery-heading, .adage-fwcta, .adage-newsletter-signup-inner {
      padding-right: 1.5rem; } }

.adage-fwcta, .adage-newsletter-signup-inner {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  @media screen and (max-width: 1280px) {
    .adage-fwcta, .adage-newsletter-signup-inner {
      padding-top: 4rem; } }
  @media screen and (max-width: 1024px) {
    .adage-fwcta, .adage-newsletter-signup-inner {
      padding-top: 2.5rem; } }
  @media screen and (max-width: 640px) {
    .adage-fwcta, .adage-newsletter-signup-inner {
      padding-top: 1.5rem; } }
  @media screen and (max-width: 1280px) {
    .adage-fwcta, .adage-newsletter-signup-inner {
      padding-bottom: 4rem; } }
  @media screen and (max-width: 1024px) {
    .adage-fwcta, .adage-newsletter-signup-inner {
      padding-bottom: 2.5rem; } }
  @media screen and (max-width: 640px) {
    .adage-fwcta, .adage-newsletter-signup-inner {
      padding-bottom: 1.5rem; } }

main > .adage-block .cp-main > .adage-block {
  margin-top: 6rem;
  margin-bottom: 6rem; }
  @media screen and (max-width: 1280px) {
    main > .adage-block .cp-main > .adage-block {
      margin-top: 5rem; } }
  @media screen and (max-width: 1024px) {
    main > .adage-block .cp-main > .adage-block {
      margin-top: 4rem; } }
  @media screen and (max-width: 640px) {
    main > .adage-block .cp-main > .adage-block {
      margin-top: 2rem; } }
  @media screen and (max-width: 1280px) {
    main > .adage-block .cp-main > .adage-block {
      margin-bottom: 5rem; } }
  @media screen and (max-width: 1024px) {
    main > .adage-block .cp-main > .adage-block {
      margin-bottom: 4rem; } }
  @media screen and (max-width: 640px) {
    main > .adage-block .cp-main > .adage-block {
      margin-bottom: 2rem; } }

.adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }
  @media screen and (max-width: 1280px) {
    .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner {
      padding-left: 2rem; } }
  @media screen and (max-width: 1024px) {
    .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner {
      padding-left: 1.25rem; } }
  @media screen and (max-width: 640px) {
    .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner {
      padding-left: 0.75rem; } }
  @media screen and (max-width: 1280px) {
    .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner {
      padding-right: 2rem; } }
  @media screen and (max-width: 1024px) {
    .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner {
      padding-right: 1.25rem; } }
  @media screen and (max-width: 640px) {
    .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner {
      padding-right: 0.75rem; } }

.cpg-main .adage-block {
  margin-top: 3rem;
  margin-bottom: 3rem; }
  @media screen and (max-width: 1280px) {
    .cpg-main .adage-block {
      margin-top: 2.5rem; } }
  @media screen and (max-width: 1024px) {
    .cpg-main .adage-block {
      margin-top: 2rem; } }
  @media screen and (max-width: 640px) {
    .cpg-main .adage-block {
      margin-top: 1rem; } }
  @media screen and (max-width: 1280px) {
    .cpg-main .adage-block {
      margin-bottom: 2.5rem; } }
  @media screen and (max-width: 1024px) {
    .cpg-main .adage-block {
      margin-bottom: 2rem; } }
  @media screen and (max-width: 640px) {
    .cpg-main .adage-block {
      margin-bottom: 1rem; } }

.adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner {
  padding-left: 2.125rem;
  padding-right: 2.125rem; }
  @media screen and (max-width: 1280px) {
    .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner {
      padding-left: 1.75rem; } }
  @media screen and (max-width: 1024px) {
    .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner {
      padding-left: 1rem; } }
  @media screen and (max-width: 640px) {
    .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner {
      padding-left: 0.5rem; } }
  @media screen and (max-width: 1280px) {
    .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner {
      padding-right: 1.75rem; } }
  @media screen and (max-width: 1024px) {
    .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner {
      padding-right: 1rem; } }
  @media screen and (max-width: 640px) {
    .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner {
      padding-right: 0.5rem; } }

.adage-block.top-border > .adage-block-inner, .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner, .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner, .ecd-page {
  padding-top: 2.125rem;
  padding-bottom: 2.125rem; }
  @media screen and (max-width: 1280px) {
    .adage-block.top-border > .adage-block-inner, .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner, .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner, .ecd-page {
      padding-top: 1.75rem; } }
  @media screen and (max-width: 1024px) {
    .adage-block.top-border > .adage-block-inner, .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner, .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner, .ecd-page {
      padding-top: 1rem; } }
  @media screen and (max-width: 640px) {
    .adage-block.top-border > .adage-block-inner, .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner, .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner, .ecd-page {
      padding-top: 0.5rem; } }
  @media screen and (max-width: 1280px) {
    .adage-block.top-border > .adage-block-inner, .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner, .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner, .ecd-page {
      padding-bottom: 1.75rem; } }
  @media screen and (max-width: 1024px) {
    .adage-block.top-border > .adage-block-inner, .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner, .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner, .ecd-page {
      padding-bottom: 1rem; } }
  @media screen and (max-width: 640px) {
    .adage-block.top-border > .adage-block-inner, .adage-block.left-border > .adage-block-inner, .adage-block.right-border > .adage-block-inner, .adage-block.blue-highlight > .adage-block-inner, .adage-block.gray-highlight > .adage-block-inner, .ecd-page {
      padding-bottom: 0.5rem; } }

.adage-gallery-item:before {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }

.adage-header-inner-lg, .ecd-header-inner, .ecd-footer-inner, .ecd-page {
  max-width: 85rem;
  margin: 0 auto;
  width: 100%; }

.hide {
  display: none;
  visibility: hidden; }

@media (min-width: 1024px) {
  .adage-form-row, .adage-staff-container {
    display: flex;
    flex-wrap: wrap; } }

.adage-form-item, .adage-staff-item {
  margin-right: 2.25rem; }
  @media screen and (max-width: 1280px) {
    .adage-form-item, .adage-staff-item {
      margin-right: 1.5rem; } }
  @media screen and (max-width: 1024px) {
    .adage-form-item, .adage-staff-item {
      margin-right: 1.5rem; } }
  @media screen and (max-width: 640px) {
    .adage-form-item, .adage-staff-item {
      margin-right: 1.25rem; } }
  @media (min-width: 1024px) {
    .adage-form-item, .adage-staff-item {
      flex: 0 0 auto;
      margin-bottom: 2.5rem; }
      .adage-form-item:nth-child(even), .adage-staff-item:nth-child(even) {
        margin-right: 0; }
      .adage-form-item:last-child, .adage-staff-item:last-child, .adage-form-item:nth-last-child(2):nth-child(odd), .adage-staff-item:nth-last-child(2):nth-child(odd) {
        margin-bottom: 0; } }
  @media (min-width: 1024px) and (max-width: 1280px) {
    .adage-form-item, .adage-staff-item {
      width: calc(50% - 0.75rem); } }
  @media (min-width: 1280px) {
    .adage-form-item, .adage-staff-item {
      width: calc(50% - 1.125rem); } }
  @media (max-width: 1024px) {
    .adage-form-item, .adage-staff-item {
      width: 100%;
      margin-bottom: 2.5rem;
      margin-right: 0; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.back-to-top {
  position: fixed;
  bottom: 5.5rem;
  right: 1.875rem;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 99;
  cursor: pointer;
  -webkit-border-radius: 1.25rem;
  -moz-border-radius: 1.25rem;
  border-radius: 1.25rem;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  opacity: 0;
  transform: scale(0);
  background-color: #0079c1; }
  .back-to-top.active {
    opacity: 1;
    transform: scale(1); }
  .back-to-top:hover {
    background-color: #0a4181; }
  .back-to-top:focus {
    background-color: #0079c1; }
  .back-to-top svg {
    fill: #ffffff;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(0.5rem, 0.5rem); }

.adage-block > .adage-block-inner {
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.adage-block-full-bleed + .adage-block-full-bleed {
  margin-top: 0; }

main > .adage-block-full-bleed {
  margin-bottom: 0; }

.adage-block-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #303030;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem; }

.adage-block.bottom-border > .adage-block-inner {
  border-bottom: 1px solid #e9e9e9;
  /*@extend %tiny-padding-v;
		@extend %small-padding-h;*/ }

.adage-block.top-border > .adage-block-inner {
  border-top: 1px solid #e9e9e9; }

.adage-block.left-border > .adage-block-inner {
  border-left: 1px solid #e9e9e9; }

.adage-block.right-border > .adage-block-inner {
  border-right: 1px solid #e9e9e9; }

.adage-block.blue-highlight > .adage-block-inner {
  background: #DFE9F1; }

.adage-block.blue-highlight {
  margin-top: 1rem !important; }

.adage-block.gray-highlight > .adage-block-inner {
  background: #f5f5f5; }

.adage-fiftyfifty .adage-image-block img, .adage-fiftyfifty .adage-video-block img, .adage-fiftyfifty .ratio-standard .adage-video-thumbnail-container img, .adage-fiftyfifty .ratio-square .adage-video-thumbnail-container img, .adage-video-thumbnail-trigger img {
  height: 100%;
  width: auto;
  max-width: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  @supports (object-fit: cover) {
    .adage-fiftyfifty .adage-image-block img, .adage-fiftyfifty .adage-video-block img, .adage-fiftyfifty .ratio-standard .adage-video-thumbnail-container img, .adage-fiftyfifty .ratio-square .adage-video-thumbnail-container img, .adage-video-thumbnail-trigger img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      transform: none;
      left: 0;
      top: 0; } }

.adage-fiftyfifty .adage-image-block figure, .adage-fiftyfifty .adage-video-block figure, .adage-video-thumbnail-trigger figure {
  background-size: cover;
  background-position: center center;
  height: 100%;
  opacity: 0;
  transition: .3s opacity;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .adage-fiftyfifty .adage-image-block figure[class], .adage-fiftyfifty .adage-video-block figure[class], .adage-video-thumbnail-trigger figure[class] {
    opacity: 1; }

.adage-theme-light.adage-fwcta, .adage-theme-light.adage-newsletter-signup, .adage-theme-default.adage-fwcta, .adage-theme-default.adage-newsletter-signup {
  background: #e9e9e9;
  color: #062f55; }

.adage-theme-dark.adage-fwcta, .adage-theme-dark.adage-newsletter-signup {
  background: #062f55;
  color: #c1d7e9; }

body {
  display: flex;
  flex-direction: column;
  min-width: 60rem; }

header {
  flex: 0 0 auto; }

footer {
  flex: 10 0 auto; }

main {
  flex: 1 0 auto;
  background: #ffffff; }

.skip-link {
  position: absolute;
  background: #ffffff;
  padding: 0.5rem;
  z-index: 1000;
  top: 0;
  left: 0;
  transform: translateY(-100%); }
  .skip-link:focus {
    transform: none; }

li p {
  margin: 0; }

main.top-border {
  border-top: 5px solid #0a4181; }

.indent-2 {
  margin-left: 7rem !important; }

.cpg-sidebar {
  border-top: 2px solid #0a4181;
  border-bottom: 2px solid #0a4181; }
  .cpg-sidebar a {
    color: #303030;
    text-decoration: none;
    padding: 7px;
    padding-left: 0; }
    .cpg-sidebar a.current {
      font-weight: 700;
      color: #0a4181; }
  .cpg-sidebar .svg-icon {
    height: 14px;
    width: 14px;
    top: 0;
    bottom: 0; }
  .cpg-sidebar li {
    display: flex;
    flex-direction: column; }

.cpg-sidebar-links {
  display: flex;
  flex-direction: column; }

.cpg-sidebar-link.primary {
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-flow: column; }
  .cpg-sidebar-link.primary span {
    background-size: 9.8px;
    background-position: center right 7px;
    background-repeat: no-repeat; }
    .cpg-sidebar-link.primary span.active {
      background-size: 14px;
      background-image: url("~/Static/img/chevron-down-solid-orange.svg"); }
  .cpg-sidebar-link.primary:hover span:not(.active) {
    background-image: url("~/Static/img/chevron-right-solid-orange.svg"); }
  .cpg-sidebar-link.primary a {
    padding-right: 0;
    font-size: 14px;
    line-height: 1.618; }

.cpg-sidebar-links.secondary {
  display: none; }
  .cpg-sidebar-links.secondary.active {
    display: block; }

.cpg-sidebar-link.secondary.current {
  background: #f5f5f5; }

.cpg-sidebar-link.secondary a {
  padding-left: 14px; }
  .cpg-sidebar-link.secondary a:hover {
    background: #e9e9e9; }

.cpg-sidebar-links.tertiary.current {
  background: #f5f5f5; }

.cpg-sidebar-links.tertiary a {
  padding: 7px 14px 7px 28px; }

.cpg-sidebar-links.fourth a {
  padding: 7px 14px 7px 42px; }

.cpg-sidebar-trigger {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch; }
  .cpg-sidebar-trigger:hover {
    background: #e9e9e9; }
  .cpg-sidebar-trigger a {
    flex: 1 1 75%; }
  .cpg-sidebar-trigger span {
    flex: 1 1 10%;
    cursor: pointer; }

.cpg-audience-select {
  width: 160px;
  font-size: 12px;
  color: #f37321;
  padding: 3.5px 0;
  background: #f5f5f5;
  border-radius: 3px;
  height: 28px; }

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

/**
 * When the native `<dialog>` element is supported, the overlay is implied and
 * can be styled with `::backdrop`, which means the DOM one should be removed.
 *
 * The `data-a11y-dialog-native` attribute is set by the script when the
 * `<dialog>` element is properly supported.
 *
 * Feel free to replace `:first-child` with the overlay selector you prefer.
 */
[data-a11y-dialog-native] > :first-child {
  display: none; }

/**
 * When the `<dialog>` element is not supported, its default display is `inline`
 * which can cause layout issues. This makes sure the dialog is correctly
 * displayed when open.
 */
dialog[open] {
  display: block; }

/**
 * When the native `<dialog>` element is not supported, the script toggles the
 * `aria-hidden` attribute on the container. If `aria-hidden` is set to `true`,
 * the container should be hidden entirely.
 *
 * Feel free to replace `.dialog-container` with the container selector you
 * prefer.
 */
.dialog-container[aria-hidden='true'] {
  display: none; }

.adage-modal-wrapper {
  transition: .5s opacity;
  z-index: 150;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  opacity: 1;
  overflow: auto; }
  .adage-modal-wrapper[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0; }

.adage-modal-wrapper.alt .adage-modal-dialog {
  width: 38rem; }

.adage-modal-wrapper.alt .adage-modal-dialog {
  background: #ffffff;
  padding-top: .5rem; }

.adage-modal-wrapper.alt .cpg-login {
  padding-top: 2rem; }

.adage-modal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .adage-modal-overlay:focus {
    outline: none;
    box-shadow: none;
    border: none; }
  .adage-modal-overlay:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100vh; }

.adage-modal-dialog {
  position: relative;
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  top: 2rem; }
  @media (max-width: 767px) {
    .adage-modal-dialog {
      width: 100%;
      bottom: auto; } }

.adage-modal-inner {
  position: relative;
  overflow: auto; }

.adage-modal-wrapper.narrow {
  padding: 10rem; }
  .adage-modal-wrapper.narrow .adage-modal-inner {
    padding: 0; }

.adage-modal-wrapper {
  background-color: rgba(6, 47, 85, 0.8);
  padding: 5rem; }
  @media screen and (max-width: 1280px) {
    .adage-modal-wrapper {
      padding: 4rem; } }
  @media screen and (max-width: 1024px) {
    .adage-modal-wrapper {
      padding: 2.5rem; } }
  @media screen and (max-width: 640px) {
    .adage-modal-wrapper {
      padding: 1.5rem; } }

.adage-modal-inner {
  background-color: transparent;
  padding: 5rem; }
  @media screen and (max-width: 1280px) {
    .adage-modal-inner {
      padding: 4rem; } }
  @media screen and (max-width: 1024px) {
    .adage-modal-inner {
      padding: 2.5rem; } }
  @media screen and (max-width: 640px) {
    .adage-modal-inner {
      padding: 1.5rem; } }
  .adage-modal-inner-full-bleed {
    padding: 0; }

.adage-modal-upper {
  text-align: right;
  background: #0079c1;
  padding: 1rem;
  margin: 0 .5rem;
  border-radius: 5px; }
  .adage-modal-upper .adage-modal-close {
    height: 1rem;
    width: 1rem;
    position: static; }
    .adage-modal-upper .adage-modal-close:after, .adage-modal-upper .adage-modal-close:before {
      position: static;
      width: 1rem;
      height: .22rem; }
    .adage-modal-upper .adage-modal-close::before {
      transform: rotate(-45deg) translateX(-2px); }
    .adage-modal-upper .adage-modal-close::after {
      transform: rotate(45deg) translateX(-2px); }

.adage-modal-close {
  width: 0.88388rem;
  height: 0.88388rem;
  position: absolute;
  right: -2rem;
  top: -2rem; }
  @media (max-width: 1024px) {
    .adage-modal-close {
      right: 0; } }
  .adage-modal-close:before, .adage-modal-close:after {
    content: '';
    display: block;
    width: 0.88388rem;
    height: 2px;
    background: #c1d7e9;
    position: absolute;
    left: 0;
    top: calc(50% - 1px); }
  .adage-modal-close:before {
    transform: rotate(-45deg); }
  .adage-modal-close:after {
    transform: rotate(45deg); }

.react-gallery {
  display: flex;
  flex-flow: row wrap; }

.react-gallery-img {
  flex: 1 1 20rem;
  margin: 1rem 0.5rem;
  cursor: pointer; }

.cpg-slideshow {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  overflow: hidden; }

.cpg-slideshow__close {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 0;
  cursor: pointer;
  pointer-events: none;
  color: #ffffff; }
  .cpg-slideshow__close::after {
    content: " ";
    display: block;
    position: absolute;
    top: 3rem;
    width: 2rem;
    height: 2rem;
    left: calc(50% + 26.5rem);
    background: #0a4181 url("~/Static/img/close.svg") center center no-repeat;
    background-size: 25px 25px;
    cursor: pointer;
    pointer-events: auto;
    z-index: 1; }

.cpg-slideshow__slide {
  display: block;
  position: absolute;
  top: 3rem;
  left: 50%;
  width: 800px;
  height: auto;
  margin: 0 0 24px -400px;
  background: #ffffff;
  z-index: -1; }

.cpg-slideshow__media {
  display: block;
  position: relative;
  width: 100%;
  padding: 3rem 2rem;
  text-align: center;
  line-height: 0;
  background: #212121; }
  .cpg-slideshow__media img {
    top: 0;
    left: 0;
    min-height: 420px;
    height: 100%;
    width: auto; }

.cpg-slideshow__controls {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 300ms ease; }
  .cpg-slideshow__controls:hover {
    opacity: 1; }
  .cpg-slideshow__controls--prev, .cpg-slideshow__controls--next {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 10rem;
    background-size: 30px 30px, 50px 50px;
    font-size: 0;
    cursor: pointer;
    opacity: .75; }
  .cpg-slideshow__controls--prev {
    left: 0;
    background-image: url("~/Static/img/chevron-left-solid.svg"), url("~/Static/img/circle-solid.svg");
    background-position: 3.8rem center, center center;
    background-repeat: no-repeat; }
  .cpg-slideshow__controls--next {
    right: 0;
    background-image: url("~/Static/img/chevron-right-solid.svg"), url("~/Static/img/circle-solid.svg");
    background-position: 4rem center, center center;
    background-repeat: no-repeat; }

.cpg-react-accordion-item.open {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 1rem; }
  .cpg-react-accordion-item.open:last-child {
    border-bottom: 0; }

.cpg-react-accordion-item .svg-icon {
  width: 1rem;
  height: 1rem; }

.cpg-react-accordion-item .cpg-text-field-wrapper {
  padding-bottom: 2rem; }

.cpg-react-accordion-item .cpg-text-field {
  align-items: baseline; }

.cpg-react-accordion-trigger {
  color: #0a4181;
  padding-left: 2rem;
  font-size: 1.125rem;
  line-height: 1.11111;
  font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
  font-weight: 400;
  position: relative; }
  .cpg-react-accordion-trigger::before {
    content: '▸';
    height: 1rem;
    width: 1rem;
    position: absolute;
    left: 0;
    color: #0079c1; }
  .open .cpg-react-accordion-trigger {
    color: #6C4F19;
    border-bottom: 1px solid #FCB13A; }
    .open .cpg-react-accordion-trigger::before {
      content: '▾';
      height: 1rem;
      width: 1rem;
      position: absolute;
      left: 0;
      color: #f37321; }

.cpg-verification-help-steps ol {
  list-style: inside decimal;
  padding: 3rem 1rem 1rem; }

/* DayPicker styles */
.DayPicker {
  display: inline-block;
  font-size: 0.75rem;
  margin-right: 6rem; }

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  padding-bottom: 1em;
  user-select: none; }

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid #dedddb;
  border-radius: 5px; }

.DayPicker-Month {
  display: table;
  margin: .2em;
  border-spacing: 0;
  border-collapse: collapse;
  user-select: none; }

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;
  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer; }

.DayPicker-NavButton:hover {
  opacity: 0.8; }

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC"); }

.DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg=="); }

.DayPicker-NavButton--interactionDisabled {
  display: none; }

.DayPicker-Caption {
  display: table-caption;
  background: #165ea9 url(/Static/img/ui-bg_highlight-hard_25_165ea9_1x100.png) 50% 50% repeat-x;
  margin-bottom: 0.5em;
  border-radius: 5px;
  color: white;
  text-align: center; }

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
  padding: 0.25rem;
  font-weight: 700; }

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em; }

.DayPicker-WeekdaysRow {
  display: table-row; }

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em; }

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none; }

.DayPicker-Body {
  display: table-row-group; }

.DayPicker-Week {
  display: table-row; }

.DayPicker-Day {
  display: table-cell;
  padding: 0.25em;
  vertical-align: middle;
  text-align: right;
  cursor: pointer;
  background-color: #eaf4fa;
  color: #326495;
  font-weight: 700;
  min-width: 1.875rem;
  border: 1px solid #fff7e5; }
  .DayPicker-Day:focus {
    box-shadow: none; }

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer; }

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: pointer; }

.DayPicker-Footer {
  padding-top: 0.5em; }

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer; }

/* Default modifiers */
.DayPicker-Day--today {
  color: #7f621b !important;
  background: #fee095 !important;
  font-weight: 700 !important; }

.DayPicker-Day--outside {
  color: #e17009;
  background-color: white;
  border: none;
  cursor: default; }

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  /* background-color: #eff1f1; */ }

/* Example modifiers */
.DayPicker-Day--sunday {
  background-color: #F7F8F8; }

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0; }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #fee095; }

.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #fee095; }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #fee095; }

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF; }

/* DayPickerInput */
.DayPickerInput {
  display: inline-block; }

.DayPickerInput-OverlayWrapper {
  position: relative; }

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); }

.generic-gallery-container {
  display: none; }
  .generic-gallery-container.active {
    display: flex;
    align-items: center; }
  .generic-gallery-container .adage-modal-close {
    right: 0.5rem;
    top: 0.5rem;
    background: url("~/Static/img/png-icons/cross.png") no-repeat;
    z-index: 10; }
    .generic-gallery-container .adage-modal-close:before {
      content: none; }
    .generic-gallery-container .adage-modal-close:after {
      content: none; }
  .generic-gallery-container .adage-modal-dialog {
    padding-top: 0;
    height: 35.71rem;
    width: 50.71rem; }
  .generic-gallery-container .adage-modal-inner {
    height: 100%;
    padding: 0;
    background-color: white;
    border-radius: 5px; }
    .generic-gallery-container .adage-modal-inner .video-content {
      height: 100%; }
      .generic-gallery-container .adage-modal-inner .video-content video {
        height: 100%; }

.generic-gallery-btn {
  background-color: #eaf4fa;
  color: #326495;
  border: 1px solid #c0def0;
  font-weight: 700;
  font-size: 0.857rem;
  width: 5.7rem;
  height: 2.14rem;
  padding: 0;
  border-radius: 5px; }
  .generic-gallery-btn.right {
    background: #eaf4fa url("~/Static/img/png-icons/arrow_right.png") no-repeat;
    background-position: 95% 50%;
    padding: 5px 15px 5px 8px; }
  .generic-gallery-btn.left {
    background: #eaf4fa url("~/Static/img/png-icons/arrow_left.png") no-repeat;
    background-position: 5% 50%;
    margin-right: 3px;
    padding: 5px 3px 5px 15px;
    width: 6.43rem; }
  .generic-gallery-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 0.75rem; }

.cpg-wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 60rem;
  margin: 0 auto;
  padding: 1rem 0.625rem; }
  .cpg-wrapper-large {
    max-width: 100rem; }
  .cpg-wrapper .cpg-main {
    margin: 0 1.75rem;
    flex-shrink: 0; }
    .cpg-wrapper .cpg-main:last-child {
      margin-right: 0; }
  .cpg-wrapper--media-article {
    display: block; }
  .cpg-wrapper-home {
    padding-top: 3rem; }

.cpg-wrapper .cpg-theme-purple h1 {
  color: #873FAD; }

.cpg-main {
  flex: 1 0 0; }
  .cpg-main .adage-block {
    margin: 0;
    flex: 0 0 25%; }
  .cpg-main-content {
    margin-left: 2rem; }
  .cpg-main--media-article {
    display: flex;
    width: auto; }
    .cpg-main--media-article-content > .cpg-main-content {
      margin: 0; }
    .cpg-main--media-article-important {
      display: flex;
      align-items: flex-start; }
      .cpg-main--media-article-important.cpg-theme-purple h3 {
        border-bottom: 2px solid #873FAD; }
      .cpg-main--media-article-important h3 {
        padding-bottom: 10px;
        border-bottom: 2px solid #0079c1; }
      .cpg-main--media-article-important h4 {
        color: #303030 !important;
        border-left: solid 3px #f37321;
        padding-left: 5px;
        margin-top: 0; }
      .cpg-main--media-article-important .rich-text > div:last-child {
        display: flex;
        padding: 2rem 3rem;
        border-bottom: solid 1px #d8d8d8; }
      .cpg-main--media-article-important .rich-text > div:nth-last-child(2) {
        display: flex;
        padding: 2rem 3rem;
        border-bottom: solid 1px #d8d8d8; }
      .cpg-main--media-article-important .span-3 {
        flex: 0 0 17%;
        margin: 0;
        height: 6.5rem;
        width: 6.5rem; }
        .cpg-main--media-article-important .span-3 img {
          height: 6.5rem;
          width: 6.5rem; }
    .cpg-main--media-article ul, .cpg-main--media-article ol {
      margin-left: 2rem; }
    .cpg-main--media-article ul {
      list-style: initial; }
    .cpg-main--media-article ol {
      list-style: decimal; }
  .cpg-main .tabbed-block-nav .tabbed-block-tab {
    width: 16rem; }
  .cpg-main .tabbed-block .row {
    background: #ffffff; }
  .cpg-main .cpgtableblock {
    max-width: 68.5rem;
    width: 100%; }
    .cpg-main .cpgtableblock td select + button {
      margin-top: 10px; }

.cpg-sidebar-left .adage-block + .adage-block, .cpg-sidebar-right .adage-block + .adage-block {
  margin-top: 2rem; }

.cpg-sidebar-left {
  flex: 0 0 13.6rem;
  min-width: 13.6rem; }

.cpg-sidebar-right {
  min-width: 13.625rem; }
  .cpg-sidebar-right:not(.wide) {
    flex: 0 0 13.625rem; }
  .cpg-sidebar-right.wide {
    flex: 0 0 19.1875rem; }

.cpg-header {
  display: flex;
  justify-content: space-between; }
  .cpg-header--audiences {
    display: block; }
    .cpg-header--audiences .cpg-wrapper {
      justify-content: space-between;
      padding: 0; }

.cpg-footer {
  display: flex;
  justify-content: space-between; }
  .cpg-footer--only-next {
    flex-direction: row-reverse; }
  .cpg-footer--toc {
    margin-right: 314px; }
  .cpg-footer .pagination-link {
    font-weight: normal; }

.cpg-main-content h1, .cpg-main-content h2, .cpg-main-content h3, .cpg-main-content h5 {
  color: #f37321;
  font-size: 1.125rem;
  line-height: 1.11111;
  font-weight: 700; }

.cpg-main .cpg-header-image-block + div .adage-wysiwyg-block p {
  font-size: 0.875rem;
  line-height: 1.57143; }

.cpg-main-sidebar {
  border-left: 1px solid #e9e9e9;
  padding-left: 2.5rem; }

.cpg-header {
  padding: 1rem 0;
  border-bottom: 2px solid #0079c1;
  margin-bottom: 1rem; }
  .cpg-header.cpg-theme-purple {
    border-bottom: 2px solid #873FAD; }
  .cpg-header-home {
    border-bottom: none; }
  .cpg-header .cpg-heading {
    color: #0079c1;
    font-size: 1.75rem;
    line-height: 1.21429; }
    .cpg-header .cpg-heading.cpg-theme-purple {
      color: #873FAD; }
  .cpg-header .cpg-heading-date {
    background: #0079c1;
    color: #ffffff;
    font-size: 1.125rem;
    line-height: 1.11111;
    padding: .25rem 2.5rem;
    line-height: 1.6;
    position: relative; }
    .cpg-header .cpg-heading-date.cpg-theme-purple {
      background: #873FAD; }
    .cpg-header .cpg-heading-date::after {
      content: "";
      background: #ffffff;
      position: absolute;
      bottom: -1rem;
      right: -1rem;
      height: 2rem;
      width: 2rem;
      transform: rotate(45deg); }
  .cpg-header--audiences {
    padding: 0;
    background: linear-gradient(#fedf9a, #E5B85F); }

.cpg-footer {
  margin-top: 2rem;
  border-top: 2px solid #0079c1;
  padding: 1rem; }
  .cpg-footer.cpg-theme-purple {
    border-top: 2px solid #873FAD; }
  .cpg-footer a {
    color: #0079c1;
    font-weight: 700; }
    .cpg-footer a.cpg-theme-purple {
      color: #873FAD; }
  .cpg-footer .svg-icon {
    height: 1.25rem;
    width: 1.25rem;
    vertical-align: middle;
    margin: 0 .5rem; }

.cpgtabbedblock .tabbed-block .tabbed-block-content > div > .row {
  display: flex; }

.addthis_inline_share_toolbox .at-share-btn {
  margin-right: 12px !important; }
  .addthis_inline_share_toolbox .at-share-btn .at-icon-wrapper {
    height: 24px !important;
    width: 24px !important;
    line-height: 24px !important; }
    .addthis_inline_share_toolbox .at-share-btn .at-icon-wrapper svg {
      width: 24px !important;
      height: 24px !important; }

.cpg-nav-landing-main {
  position: relative;
  margin: 0; }

.cpg-nav-landing-links {
  margin-bottom: 2rem; }
  .cpg-nav-landing-links ul {
    display: flex;
    justify-content: space-evenly; }
  .cpg-nav-landing-links li {
    margin-right: .25rem;
    text-align: center;
    display: flex;
    flex: 1 1 auto; }
    .cpg-nav-landing-links li:last-child {
      margin-right: 0; }
  .cpg-nav-landing-links a {
    width: 100%;
    color: #303030;
    background: #e9e9e9;
    padding: 1.5rem;
    border-bottom: 3px solid #0a4181;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.57143; }
    .cpg-nav-landing-links a:hover {
      background: #f5f5f5;
      border-color: #f37321; }

.cpg-wrapper-two-column {
  display: flex;
  align-items: flex-start;
  max-width: 60rem;
  margin: 0 auto;
  padding: 0 1.75rem 1.75rem 0; }
  .cpg-wrapper-two-column > * {
    border-top: 1px solid #e9e9e9;
    flex-grow: 1; }
  .cpg-wrapper-two-column-left {
    flex-basis: 33.125rem;
    flex-grow: 1;
    padding: 3.125rem 3.125rem 3.125rem 0; }
    .cpg-wrapper-two-column-left .row {
      margin: 1rem 0;
      padding-bottom: .5rem; }
      .cpg-wrapper-two-column-left .row:not(:nth-child(2)) {
        border-bottom: 1px solid #e9e9e9; }
    .cpg-wrapper-two-column-left .adage-image-block {
      display: flex;
      justify-content: center; }
    .cpg-wrapper-two-column-left .adage-wysiwyg-block:only-child {
      margin-top: 0; }
    .cpg-wrapper-two-column-left .row0 {
      margin: 0; }
  .cpg-wrapper-two-column-right {
    flex-basis: 22.8125rem;
    border-left: 1px solid #e9e9e9;
    padding: 3.125rem 0 3.125rem 3.125rem; }
    .cpg-wrapper-two-column-right figure {
      margin-bottom: 2rem; }
    .cpg-wrapper-two-column-right .row1 {
      border-bottom: 1px solid #e9e9e9; }
  .cpg-wrapper-two-column h1 {
    color: #f37321;
    font-size: 1.75rem;
    line-height: 1.21429;
    margin-bottom: 2rem;
    font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
    max-width: 19rem; }
  .cpg-wrapper-two-column h5 {
    font-size: 1.125rem;
    line-height: 1.11111; }
  .cpg-wrapper-two-column .left-col-child h5 {
    margin-bottom: 0; }
    .cpg-wrapper-two-column .left-col-child h5 + p {
      margin-bottom: 2rem; }
  .cpg-wrapper-two-column .new-button {
    text-decoration: none;
    padding: .24rem 2rem .27rem 2rem;
    border-radius: 3px;
    letter-spacing: .03rem;
    font-family: "Helvetica Neue LT W05_65 Medium", "Helvetica", Arial, sans-serif;
    font-size: .84rem; }
  .cpg-wrapper-two-column p {
    font-size: 0.875rem;
    line-height: 1.57143; }
  .cpg-wrapper-two-column .cpg-sidebar-link-group-title {
    color: #303030; }

.cpg-events-container .cpg-event-group {
  background: #f5f5f5;
  padding: 2rem;
  padding-top: 1rem;
  margin-bottom: 1rem; }

.cpg-events-container .cpg-event {
  border-bottom: 1px solid #e9e9e9;
  display: flex; }
  .cpg-events-container .cpg-event:last-child {
    border-bottom: 0; }

.cpg-events-container .cpg-event-heading {
  flex: 0 0 25%;
  padding-right: 1.5rem; }
  .cpg-events-container .cpg-event-heading h4 {
    font-size: 0.875rem;
    line-height: 1.57143; }
  .cpg-events-container .cpg-event-heading h5 {
    font-size: 0.625rem;
    line-height: 1.5;
    color: #303030; }

.cpg-events-container .cpg-event-body {
  flex: 0 0 75%;
  padding-right: 4rem; }
  .cpg-events-container .cpg-event-body h1, .cpg-events-container .cpg-event-body h2 {
    font-size: 0.875rem;
    font-weight: 700;
    margin-top: 1rem; }

.cpg-error .adage-block-inner {
  padding: 0; }

.cpg-error .cpg-error-reasons {
  border: 10px solid #d4ecf3;
  border-radius: .5rem;
  padding: .5rem;
  margin: 0; }
  .cpg-error .cpg-error-reasons p {
    margin-top: 0; }

.cpg-login-wrapper {
  width: 38rem; }

.cpg-forgot-wrapper {
  width: 52rem; }

.cpg-login,
.cpg-forgot {
  padding-top: 4rem; }
  .cpg-login--password .cpg-forgot-fields,
  .cpg-forgot--password .cpg-forgot-fields {
    background: linear-gradient(#ffffff, #ffffff 20%, #ffeebe); }

.cpg-forgot-main,
.cpg-login-main {
  border: 1px solid #ffd35f;
  border-radius: .5rem;
  position: relative; }
  .cpg-forgot-main h1,
  .cpg-login-main h1 {
    font-size: 0.875rem;
    line-height: 1.57143;
    color: #f37321;
    font-family: "Helvetica Neue LT W05_75 Bold", Arial, sans-serif;
    font-weight: 600;
    margin: 1rem 0 .125rem 0;
    position: absolute;
    top: -2.8rem;
    left: 1rem;
    padding: 1rem;
    background: #ffffff; }
  .cpg-forgot-main a,
  .cpg-login-main a {
    text-decoration: none; }
    .cpg-forgot-main a:hover,
    .cpg-login-main a:hover {
      text-decoration: underline; }

.cpg-forgot-prompt,
.cpg-forgot-welcome,
.cpg-login-welcome,
.cpg-login-error {
  margin-top: .5rem;
  padding: .5rem .75rem;
  border-width: 1px;
  border-style: solid;
  border-radius: .5rem; }

.cpg-forgot-prompt {
  background: #d4ecf3;
  color: #205791;
  border-color: #c1d7e9; }

.cpg-login-error {
  background: #fbe3e4;
  color: #8a1f11;
  border-color: #fbc2c4; }

.cpg-forgot-welcome,
.cpg-login-welcome {
  background: #fffbef;
  border-color: #ffeebe;
  color: #514721; }
  .cpg-forgot-welcome p,
  .cpg-login-welcome p {
    margin: .25rem; }

.cpg-forgot-fields,
.cpg-login-fields {
  padding: 2rem .75rem;
  background: linear-gradient(#ffffff, #ffffff 60%, #ffeebe);
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem; }
  .cpg-forgot-fields--password,
  .cpg-login-fields--password {
    padding-bottom: .75rem; }

.cpg-forgot-field,
.cpg-login-field {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .25rem 4rem .25rem 4rem;
  position: relative; }
  .cpg-forgot-field label,
  .cpg-login-field label {
    color: #514721;
    position: relative;
    font-size: 0.875rem;
    font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
    font-weight: 400; }
    .cpg-forgot-field label::before,
    .cpg-login-field label::before {
      display: block;
      left: -.5rem;
      position: absolute;
      color: #e54d1b;
      content: "*"; }
  .cpg-forgot-field input,
  .cpg-login-field input {
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    padding: .35rem;
    font-size: 0.875rem; }
  .cpg-forgot-field a,
  .cpg-login-field a {
    font-size: 0.625rem;
    line-height: 1.5; }
  .cpg-forgot-field > *,
  .cpg-login-field > * {
    margin: 0 .25rem; }
  .cpg-forgot-field--short input,
  .cpg-login-field--short input {
    width: 8rem; }
  .cpg-forgot-field .field-validation-error,
  .cpg-login-field .field-validation-error {
    position: absolute;
    left: 22rem;
    top: .25rem;
    background: #9d1d38;
    color: #e9e9e9;
    padding: .15rem;
    padding-left: .6rem;
    opacity: 1;
    display: table;
    flex-direction: column;
    width: 18rem;
    font-size: 0.625rem;
    line-height: 1.5;
    z-index: 99; }
    .cpg-forgot-field .field-validation-error::before,
    .cpg-login-field .field-validation-error::before {
      display: block;
      content: " ";
      height: .75rem;
      width: .75rem;
      position: absolute;
      left: -.3rem;
      top: .4rem;
      background: #9d1d38;
      transform: rotate(45deg); }

.cpg-forgot-field {
  justify-content: flex-start; }
  .cpg-forgot-field label {
    flex: 0 0 25%;
    text-align: right; }
    .cpg-forgot-field label::before {
      display: none; }
    .cpg-forgot-field label span {
      color: #e54d1b;
      margin-right: .75rem; }
  .cpg-forgot-field select {
    color: #303030;
    background: #ffffff; }
    .cpg-forgot-field select:nth-last-child(1) {
      width: 4rem; }
  .cpg-forgot-field .field-validation-error {
    left: 26rem; }
  .cpg-forgot-field--short .field-validation-error {
    left: 22.5rem;
    top: .4rem; }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .cpg-forgot-field .field-validation-error {
      left: 25rem; }
    .cpg-forgot-field--short .field-validation-error {
      left: 20rem;
      top: .4rem; } }
  .cpg-forgot-field--pw label {
    flex: 1 0 auto; }
  .cpg-forgot-field--pw .field-validation-error {
    left: 42rem; }
  .cpg-forgot-field--pw.recovery .field-validation-error {
    left: 44rem;
    top: .75rem;
    margin: 0; }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .cpg-forgot-field--pw .field-validation-error {
      left: 42rem; } }

.cpg-form-help {
  text-align: center; }
  .cpg-form-help img {
    margin-top: -.5rem; }
  .cpg-form-help--inline {
    color: #0079c1;
    text-decoration: dashed underline;
    cursor: default; }

.cpg-forgot-form-bottom,
.cpg-login-form-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fffbef;
  padding: .75rem;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  box-shadow: inset 0px 5px 4px -3px rgba(50, 50, 50, 0.5); }
  .cpg-forgot-form-bottom.right,
  .cpg-login-form-bottom.right {
    justify-content: flex-end; }
    .cpg-forgot-form-bottom.right input:first-child,
    .cpg-login-form-bottom.right input:first-child {
      margin-right: 1rem; }
  .cpg-forgot-form-bottom input:hover,
  .cpg-login-form-bottom input:hover {
    cursor: pointer; }

.cpg-forgot-form-bottom {
  justify-content: flex-end; }

.cpg-forgot-bottom-text,
.cpg-login-bottom-text {
  padding: .6rem;
  margin-top: 1.3rem; }
  .cpg-forgot-bottom-text-main,
  .cpg-login-bottom-text-main {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .cpg-forgot-bottom-text-left,
  .cpg-login-bottom-text-left {
    flex: 0 1 60%;
    color: #818181;
    position: relative; }
    .cpg-forgot-bottom-text-left em,
    .cpg-login-bottom-text-left em {
      color: #e54d1b; }
  .cpg-forgot-bottom-text .btn_create_account,
  .cpg-login-bottom-text .btn_create_account {
    cursor: pointer;
    padding: .5rem;
    background: linear-gradient(#c1d7e9, #0079c1);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    font-weight: 700;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5); }
    .cpg-forgot-bottom-text .btn_create_account:hover,
    .cpg-login-bottom-text .btn_create_account:hover {
      background: linear-gradient(#0079c1, #c1d7e9); }

.ccis_help p {
  color: #818181; }
  .ccis_help p em {
    color: #e54d1b; }

.cpg-recovery-field {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;
  flex: 0 0 60%; }
  .cpg-recovery-field .field-validation-error {
    position: absolute;
    left: 103%;
    top: .25rem;
    background: #9d1d38;
    color: #e9e9e9;
    padding: .4rem;
    padding-left: .6rem;
    opacity: 1;
    display: table;
    flex-direction: column;
    max-width: 20rem;
    z-index: 999; }
    .cpg-recovery-field .field-validation-error::before {
      display: block;
      content: " ";
      height: .75rem;
      width: .75rem;
      position: absolute;
      left: -.3rem;
      top: .5rem;
      background: #9d1d38;
      transform: rotate(45deg); }
  .cpg-recovery-field label {
    flex-shrink: 1;
    position: relative;
    font-size: 0.875rem;
    font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
    font-weight: 400;
    padding-right: 1rem; }
    .cpg-recovery-field label::before {
      display: block;
      left: -.5rem;
      position: absolute;
      color: #e54d1b;
      content: "*"; }
  .cpg-recovery-field input {
    flex-shrink: 1;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    padding: .35rem;
    font-size: 0.875rem; }
  .cpg-recovery-field-wrapper {
    display: flex;
    align-items: baseline; }
    .cpg-recovery-field-wrapper .cpg-recovery-field {
      padding: 0;
      justify-content: flex-end; }
    .cpg-recovery-field-wrapper .cpg-recovery-field-rules {
      background: #FFDC7F;
      padding: .4rem 0;
      margin-left: .5rem;
      border: 1px solid #FEBD1E;
      font-size: 0.625rem;
      line-height: 1.5;
      flex-grow: 1;
      position: relative; }
      .cpg-recovery-field-wrapper .cpg-recovery-field-rules::before {
        content: "";
        position: absolute;
        transform: rotate(45deg);
        background: #FFDC7F;
        width: .75rem;
        height: .75rem;
        top: .5rem;
        left: -.25rem; }
      .cpg-recovery-field-wrapper .cpg-recovery-field-rules p {
        margin: 0 .75rem; }
      .cpg-recovery-field-wrapper .cpg-recovery-field-rules ul, .cpg-recovery-field-wrapper .cpg-recovery-field-rules li {
        margin-left: .75rem;
        list-style: outside;
        width: 15rem; }
    .cpg-recovery-field-wrapper > .cpg-recovery-field {
      flex: 0 0 50%; }

.cpg-recovery-field-wrapper-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-basis: 50%; }

.adage-modal-wrapper {
  padding-top: 200px;
  align-items: inherit !important; }
  .adage-modal-wrapper .cpg-login-wrapper {
    padding: 1rem .5rem;
    background: #ffffff; }
  .adage-modal-wrapper .cpg-login {
    margin: 0; }

/* Below is my effort to make a fade out animation for the validation */
.cpg-create-account {
  padding-top: 4rem;
  align-self: stretch; }
  .cpg-create-account-wrapper {
    display: block;
    width: 55rem; }
    .cpg-create-account-wrapper.wide {
      width: 70rem; }
  .cpg-create-account-footer {
    margin: 1rem 1.75rem; }

.cpg-create-account-modal {
  padding-top: 0;
  margin-left: 0 !important; }
  .cpg-create-account-modal .cpg-create-account-main {
    padding-top: 0; }

.cpg-create-account-main {
  padding-top: 2.5rem;
  border: 1px solid #ffd35f;
  border-radius: .5rem;
  position: relative;
  background: #ffffff; }
  .cpg-create-account-main h1 {
    font-size: 0.875rem;
    line-height: 1.57143;
    color: #f37321;
    font-family: "Helvetica Neue LT W05_75 Bold", Arial, sans-serif;
    font-weight: 600;
    margin: 1rem 0 .125rem 0;
    position: absolute;
    top: -2.8rem;
    left: 1rem;
    padding: 1rem;
    background: #ffffff; }

.cpg-create-account-steps {
  padding-bottom: 1rem; }
  .cpg-create-account-steps ol {
    display: flex;
    justify-content: center; }

.cpg-step-display.current .cpg-step-display-number {
  width: 1.7rem;
  height: 1.7rem;
  line-height: .7;
  background-color: #1b7ba9;
  color: #ffffff;
  border-width: 3px;
  border-color: #cce9f7;
  padding-top: 0.25rem; }

.cpg-step-display-number {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  background-color: #cce9f7;
  color: #1b7ba9;
  border: 1px solid #5eb9e6;
  border-radius: 100%;
  padding: .25rem;
  margin-right: .5rem;
  text-align: center;
  line-height: 1; }

.cpg-step-display-text {
  color: #333;
  margin-right: 1.5rem; }

.cpg-create-account-step-body {
  padding: 0; }
  .cpg-create-account-step-body .adage-modal-dialog {
    max-width: 50rem; }

.cpg-step-info {
  padding: 0 .6rem;
  margin: .75rem 0;
  background: #d5edf8;
  color: #205791;
  border: 1px solid #92cae4;
  border-radius: .3rem; }
  .cpg-step-info p, .cpg-step-info a {
    display: inline-block;
    margin: .75rem 0; }
  .cpg-step-info.green {
    background-color: #E6EFC2;
    color: #333;
    border-color: #e9e9e9; }
  .cpg-step-info.red {
    border-color: #79162b;
    background-color: #9d1d38;
    color: #ffdce3; }
  .cpg-step-info.yellow {
    background-color: #fffbef;
    border-color: #ffeebe;
    color: #514721; }

.cpg-create-account-fields {
  padding: .75rem;
  background: linear-gradient(#ffffff, #ffffff 60%, #ffeebe);
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem; }

.cpg-create-account-section.email .cpg-text-field {
  justify-content: flex-start; }

.cpg-create-account-section.email .form-element-input {
  width: 18rem; }

.cpg-select-fieldset,
.cpg-text-field {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .cpg-select-fieldset legend,
  .cpg-select-fieldset label,
  .cpg-text-field legend,
  .cpg-text-field label {
    flex-shrink: 1;
    position: relative;
    font-size: 0.875rem;
    font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
    font-weight: 400; }
    .cpg-select-fieldset legend::before,
    .cpg-select-fieldset label::before,
    .cpg-text-field legend::before,
    .cpg-text-field label::before {
      display: block;
      left: -.5rem;
      position: absolute;
      color: #e54d1b;
      content: "*"; }
  .cpg-select-fieldset input,
  .cpg-text-field input {
    flex-shrink: 1;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    padding: .35rem;
    font-size: 0.875rem; }
  .cpg-select-fieldset a,
  .cpg-text-field a {
    font-size: 0.625rem;
    line-height: 1.5; }
  .cpg-select-fieldset > *,
  .cpg-text-field > * {
    margin: 0 .25rem; }
  .cpg-select-fieldset-wrapper,
  .cpg-text-field-wrapper {
    display: flex;
    align-items: baseline; }
    .cpg-select-fieldset-wrapper .cpg-text-field,
    .cpg-text-field-wrapper .cpg-text-field {
      padding: 0;
      justify-content: flex-end; }
    .cpg-select-fieldset-wrapper .cpg-text-field-rules,
    .cpg-text-field-wrapper .cpg-text-field-rules {
      background: #FFDC7F;
      padding: .4rem 0;
      margin-left: .5rem;
      border: 1px solid #FEBD1E;
      font-size: 0.625rem;
      line-height: 1.5;
      flex-grow: 1;
      position: relative; }
      .cpg-select-fieldset-wrapper .cpg-text-field-rules::before,
      .cpg-text-field-wrapper .cpg-text-field-rules::before {
        content: "";
        position: absolute;
        transform: rotate(45deg);
        background: #FFDC7F;
        width: .75rem;
        height: .75rem;
        top: .5rem;
        left: -.25rem; }
      .cpg-select-fieldset-wrapper .cpg-text-field-rules p,
      .cpg-text-field-wrapper .cpg-text-field-rules p {
        margin: 0 .75rem; }
      .cpg-select-fieldset-wrapper .cpg-text-field-rules ul, .cpg-select-fieldset-wrapper .cpg-text-field-rules li,
      .cpg-text-field-wrapper .cpg-text-field-rules ul,
      .cpg-text-field-wrapper .cpg-text-field-rules li {
        margin-left: .75rem;
        list-style: outside;
        width: 15rem; }
    .cpg-select-fieldset-wrapper > .cpg-text-field, .cpg-select-fieldset-wrapper-inner,
    .cpg-text-field-wrapper > .cpg-text-field,
    .cpg-text-field-wrapper-inner {
      flex: 0 0 50%; }
    .cpg-select-fieldset-wrapper-inner,
    .cpg-text-field-wrapper-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      .cpg-select-fieldset-wrapper-inner.wide,
      .cpg-text-field-wrapper-inner.wide {
        flex: 0 1 60%; }
  .cpg-select-fieldset .form-element-error,
  .cpg-text-field .form-element-error {
    display: table;
    position: absolute;
    left: 102%;
    top: .25rem;
    background: #9d1d38;
    color: #e9e9e9;
    opacity: 1;
    flex-direction: column;
    width: 20rem;
    z-index: 99;
    border: 1px solid #FCC2C5;
    font-size: 0.875rem; }
    .cpg-select-fieldset .form-element-error::before,
    .cpg-text-field .form-element-error::before {
      display: block;
      content: " ";
      height: .75rem;
      width: .75rem;
      position: absolute;
      left: -.3rem;
      top: .3rem;
      background: #9d1d38;
      transform: rotate(45deg); }
    .cpg-select-fieldset .form-element-error-text,
    .cpg-text-field .form-element-error-text {
      display: block; }
      .cpg-select-fieldset .form-element-error-text-wrapper,
      .cpg-text-field .form-element-error-text-wrapper {
        margin-left: .5rem;
        padding: .25rem;
        background: #9d1d38; }
  .cpg-select-fieldset .form-element-error-icon,
  .cpg-text-field .form-element-error-icon {
    height: 1rem;
    width: 1rem; }

.cpg-select-fieldset {
  padding-top: .5rem; }
  .cpg-select-fieldset select {
    color: #333;
    background: #ffffff; }
    .cpg-select-fieldset select:last-child {
      width: 3rem; }

.cpg-step-link-text {
  border-bottom: dashed;
  border-bottom-width: 1px;
  color: #1c7abf; }

.cpg-create-account-step-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #fffbef;
  padding: .75rem;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  box-shadow: inset 0px 5px 4px -3px rgba(50, 50, 50, 0.5); }

.cpg-field-checkbox,
.cpg-show-password {
  display: flex;
  align-items: center;
  padding: 0 2rem; }
  .cpg-field-checkbox.center,
  .cpg-show-password.center {
    justify-content: center; }
  .cpg-field-checkbox img,
  .cpg-show-password img {
    margin: .25rem; }

.cpg-securityq-fieldset {
  display: flex;
  align-items: center;
  justify-content: center; }
  .cpg-securityq-fieldset select {
    width: 20rem;
    font-size: 0.625rem;
    line-height: 1.5; }
  .cpg-securityq-fieldset input {
    width: 20rem; }

.cpg-profile-settings {
  padding-top: 4rem;
  align-self: stretch; }
  .cpg-profile-settings-wrapper {
    display: block;
    width: 55rem; }
    .cpg-profile-settings-wrapper.wide {
      width: 70rem; }
  .cpg-profile-settings-footer {
    margin: 1rem 1.75rem; }

.cpg-profile-settings-main {
  padding-top: 1rem;
  border: 1px solid #ffd35f;
  border-radius: .5rem .5rem 0 0;
  position: relative;
  background: #ffffff; }
  .cpg-profile-settings-main h1 {
    font-size: 0.875rem;
    line-height: 1.57143;
    color: #f37321;
    font-family: "Helvetica Neue LT W05_75 Bold", Arial, sans-serif;
    font-weight: 600;
    margin: 1rem 0 .125rem 0;
    position: absolute;
    top: -2.8rem;
    left: 1rem;
    padding: 1rem;
    background: #ffffff; }

.cpg-profile-settings-fields {
  padding: .75rem;
  background: linear-gradient(#ffffff, #ffffff 60%, #ffeebe);
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem; }
  .cpg-profile-settings-fields .cpg-react-accordion-item.open {
    border-color: #fedf9a; }
  .cpg-profile-settings-fields .cpg-react-accordion-trigger {
    font-size: 0.875rem;
    line-height: 1.57143; }

.cpg-profile-settings-section {
  display: flex;
  padding-top: 2rem;
  padding-left: 2rem; }
  .cpg-profile-settings-section h5 {
    color: #BC8917;
    font-size: 0.875rem;
    line-height: 1.57143; }
  .cpg-profile-settings-section .cpg-text-field-wrapper {
    width: 44rem; }
  .cpg-profile-settings-section label {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.57143; }
  .cpg-profile-settings-section .cpg-text-field-rules {
    font-size: 0.875rem;
    border-radius: 3px; }
    .cpg-profile-settings-section .cpg-text-field-rules ul {
      margin-left: 1rem; }
  .cpg-profile-settings-section.password .cpg-text-field-wrapper {
    width: 47rem; }
  .cpg-profile-settings-section.security-questions {
    display: block; }
    .cpg-profile-settings-section.security-questions legend {
      font-size: 0.875rem;
      line-height: 1.57143; }
  .cpg-profile-settings-section .contact-email .form-element-error {
    left: 77%;
    width: 12rem; }
  .cpg-profile-settings-section .contact-email .form-element-input {
    width: 14rem; }

.cpg-profile-settings-contact-info {
  flex: 0 1 50%;
  padding: 0 2rem;
  margin-bottom: 2rem; }
  .cpg-profile-settings-contact-info:first-child {
    padding-left: 0;
    border-right: 1px solid #fedf9a; }
  .cpg-profile-settings-contact-info .cpg-text-field {
    align-items: flex-end; }
    .cpg-profile-settings-contact-info .cpg-text-field label {
      align-self: center;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.57143; }
    .cpg-profile-settings-contact-info .cpg-text-field label::before {
      display: none; }
    .cpg-profile-settings-contact-info .cpg-text-field.small input {
      width: 2.25rem; }
    .cpg-profile-settings-contact-info .cpg-text-field.medium input {
      width: 4rem; }

.cpg-contact-info-heading {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #fedf9a;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.57143; }
  .cpg-contact-info-heading h5 {
    margin-bottom: 0;
    margin-right: 1rem; }
  .cpg-contact-info-heading label {
    font-size: 0.875rem; }

.cpg-general-info {
  display: flex;
  align-items: baseline;
  padding-bottom: 1rem; }
  .cpg-general-info-wrapper {
    display: flex;
    flex-direction: column; }
  .cpg-general-info span {
    font-size: 0.875rem;
    line-height: 1.57143; }
    .cpg-general-info span:first-child {
      font-weight: 700;
      text-align: right;
      padding-right: 1rem;
      flex: 0 0 20%; }
    .cpg-general-info span:last-child {
      flex: 0 0 auto; }

.cpg-contact-info--phone {
  display: flex;
  position: relative; }
  .cpg-contact-info--phone .form-element-error {
    display: table;
    position: absolute;
    left: 87%;
    top: .6rem;
    background: #9d1d38;
    color: #e9e9e9;
    opacity: 1;
    flex-direction: column;
    width: 9rem;
    z-index: 99;
    border: 1px solid #FCC2C5;
    font-size: 0.625rem;
    line-height: 1.5; }
    .cpg-contact-info--phone .form-element-error::before {
      display: block;
      content: " ";
      height: .75rem;
      width: .75rem;
      position: absolute;
      left: -.3rem;
      top: .3rem;
      background: #9d1d38;
      transform: rotate(45deg); }
    .cpg-contact-info--phone .form-element-error-text {
      display: block; }
      .cpg-contact-info--phone .form-element-error-text-wrapper {
        margin-left: .5rem;
        padding: .25rem;
        background: #9d1d38; }
    .cpg-contact-info--phone .form-element-error .form-element-error-icon {
      height: 1rem;
      width: 1rem; }
    .cpg-contact-info--phone .form-element-error.wide {
      left: 93%; }

.cpg-profile-settings-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #fffbef;
  padding: .75rem;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  box-shadow: inset 0px 5px 4px -3px rgba(50, 50, 50, 0.5); }

.liturgical-calendar-menu {
  min-width: 11.875rem;
  padding-right: 1.25rem;
  margin-right: 0.625rem;
  min-height: 1rem; }

.liturgical-calendar-outer {
  display: flex; }

.liturgical-calendar-selection {
  border: 1px solid #d4d0c8;
  border-radius: 5px;
  padding: 0.625rem;
  margin: 2rem 0 1.428rem 0; }
  .liturgical-calendar-selection .select-header {
    margin-top: -22px;
    background: white;
    width: 140px;
    margin-left: 20px;
    padding-left: 10px; }
  .liturgical-calendar-selection .month-text, .liturgical-calendar-selection .year-text {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    margin-top: 0.25rem; }
    .liturgical-calendar-selection .month-text span, .liturgical-calendar-selection .year-text span {
      color: #f90000; }
  .liturgical-calendar-selection .year-text {
    margin-right: 1.25rem; }
  .liturgical-calendar-selection .select-period {
    display: flex; }
    .liturgical-calendar-selection .select-period select {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      color: black;
      background: white;
      font-size: 0.875rem;
      border-radius: 2px; }
  .liturgical-calendar-selection button {
    font-weight: 700;
    background: #f39000 url("/Static/img/bgr_button-default.gif");
    border-radius: 2px;
    border: 1px solid #ed3c11;
    padding: 4px 10px 4px 7px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
    margin: 4px 0 6px 6px; }
    .liturgical-calendar-selection button:hover {
      background: #e34100 url("/Static/img/bgr_button-hover.gif"); }

.liturgical-calendar-events {
  display: flex; }

.liturgical-calendar-view .event-item:hover, .liturgical-calendar-view .event-item.active {
  background-color: #fee095; }
  .liturgical-calendar-view .event-item:hover .event-name, .liturgical-calendar-view .event-item.active .event-name {
    color: #7f611b; }

.liturgical-calendar-view .event-list {
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dedddb; }

.liturgical-calendar-view .event-item {
  display: flex; }
  .liturgical-calendar-view .event-item .date {
    flex-shrink: 0;
    width: 7rem;
    color: #c4972c;
    text-align: right;
    margin-right: 1rem; }

.cpg-contactform-form select, .cpg-contactform-subscribe select {
  color: #f37321;
  font-weight: 700;
  border-radius: 5px;
  width: 14rem; }

.cpg-contactform-title {
  border-bottom: 1px solid #818181;
  margin-bottom: 2rem;
  padding-bottom: 1rem; }

.cpg-contactform-inner {
  display: flex;
  border-bottom: 1px solid #818181;
  margin-bottom: 2rem;
  padding-bottom: 2rem; }
  .cpg-contactform-inner .field-validation-error {
    color: #BF0001; }

.cpg-contactform-form, .cpg-contactform-info {
  width: 50%; }

.cpg-contactform-info {
  padding-left: 2rem; }
  .cpg-contactform-info-disclaimer p {
    color: #818181; }
  .cpg-contactform-info input[type=submit] {
    margin-top: 2rem; }

.cpg-contactform-form {
  border-right: 1px solid #818181;
  padding-right: 2rem; }
  .cpg-contactform-form > div {
    margin-bottom: 1rem; }
  .cpg-contactform-form input, .cpg-contactform-form textarea {
    width: 100%;
    margin-top: 0;
    background-color: #f5f5f5;
    border-width: 1px;
    border-color: #f5f5f5;
    color: #303030;
    border-style: solid;
    padding: .5rem; }

.cpg-contactform-contact {
  display: flex;
  padding-bottom: 2rem; }
  .cpg-contactform-contact p {
    margin-top: 0;
    margin-bottom: 0; }
  .cpg-contactform-contact > div {
    padding-right: 2rem; }

.cpg-contactform-subscribe {
  width: 50%; }
  .cpg-contactform-subscribe > div {
    margin-bottom: 1rem; }
  .cpg-contactform-subscribe input[type=text] {
    width: 100%;
    padding: .5rem;
    margin-top: 0;
    background-color: #f5f5f5;
    border-width: 1px;
    border-color: #f5f5f5;
    color: #303030;
    border-style: solid; }

.cpg-search-alert-box {
  padding: 0.625rem;
  margin: 1.25rem 0;
  background: #fffbbf;
  color: #514721;
  box-shadow: 0px 0.0625rem 0.125rem rgba(0, 0, 0, 0.5);
  border-radius: 0.3125rem; }
  .cpg-search-alert-box p {
    margin: 0; }

.cpg-search-box {
  margin-bottom: 1.5rem; }
  .cpg-search-box-top {
    display: flex;
    color: #5f5c55;
    align-items: center;
    border-bottom: 0.125rem groove #ffffff; }
    .cpg-search-box-top p {
      margin-top: 0;
      margin-bottom: 0; }
    .cpg-search-box-top > * {
      width: 50%;
      flex-grow: 1; }
    .cpg-search-box-top .page-size {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .cpg-search-box-top .page-size > p {
        font-weight: 700;
        margin-right: 1rem; }
      .cpg-search-box-top .page-size select {
        color: #000000;
        width: 4rem; }

.cpg-search-box .cpg-search-pagination, .cpg-pagination-footer .cpg-search-pagination {
  display: flex;
  margin-top: 1rem;
  justify-content: flex-start; }

.cpg-search-result-content .cpg-pagination-footer {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.25rem;
  box-shadow: none;
  border-radius: initial; }

.search-pagination-arrows {
  display: flex;
  gap: 0.5rem; }
  .search-pagination-arrows p {
    margin: 0;
    padding: 0 0.25rem; }
  .search-pagination-arrows svg {
    margin: 0; }
  .search-pagination-arrows .double-arrow {
    display: flex; }
    .search-pagination-arrows .double-arrow svg:first-child {
      margin-right: -7px; }
  .search-pagination-arrows-item {
    display: flex;
    gap: 0.25rem; }
    .search-pagination-arrows-item svg use {
      --chevron-color: $blue; }

.search-pagination-arrows-left svg {
  transform: rotate(180deg); }

.cpg-search-pagination-arrows-disabled {
  opacity: 50%; }
  .cpg-search-pagination-arrows-disabled span, .cpg-search-pagination-arrows-disabled p {
    color: #A1B7D0; }
  .cpg-search-pagination-arrows-disabled svg use {
    --chevron-color: $blue-light; }

.cpg-search-pagination-buttons-item.selected {
  background: #E1E1E1;
  border-radius: 2px;
  border: solid #BBBBBB 1px;
  padding: 0.25rem; }

.cpg-search-result-content .cpg-search-pagination-buttons-item.selected span {
  color: #000000 !important; }

.cpg-search-result-content .cpg-search-pagination-buttons {
  align-items: center;
  border: none; }

.cpg-search-pagination-buttons-item + .cpg-search-pagination-buttons-item {
  border: none !important;
  /*margin-left: $gap-sm !important;*/ }

.cpg-search-pagination {
  font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif, serif;
  font-weight: 700; }

.cpg-search-result .cpg-wrapper-large {
  max-width: 60rem; }

.cpg-pagination-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  .cpg-pagination-footer .cpg-search-pagination {
    margin-top: 0; }
  .cpg-pagination-footer .page-size {
    font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif, serif;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-left: 1rem; }
    .cpg-pagination-footer .page-size p {
      margin: 0; }
    .cpg-pagination-footer .page-size select {
      color: #000000;
      margin-top: 1rem;
      background: #fff;
      border-radius: 3px;
      width: 8rem;
      padding: 0.5rem; }

.cpg-search-pagination {
  display: flex;
  justify-content: center;
  align-items: center; }
  .cpg-search-pagination-arrows {
    display: flex;
    justify-content: center; }
    .cpg-search-pagination-arrows-item {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .cpg-search-pagination-arrows-item svg {
        width: 1rem;
        height: 1rem;
        fill: #1c7abf; }
      .cpg-search-pagination-arrows-item p {
        color: #1c7abf; }
      .cpg-search-pagination-arrows-item:hover:not(.cpg-search-pagination-arrows-disabled) p {
        color: #4a9dcf; }
      .cpg-search-pagination-arrows-item:hover:not(.cpg-search-pagination-arrows-disabled) svg {
        fill: #4a9dcf; }
    .cpg-search-pagination-arrows-disabled {
      cursor: default; }
      .cpg-search-pagination-arrows-disabled p {
        color: #9c9a94; }
      .cpg-search-pagination-arrows-disabled svg {
        fill: #9c9a94; }
  .cpg-search-pagination-buttons {
    display: flex;
    justify-content: center;
    margin: 0 .5rem;
    padding: 0 .5rem;
    border-left: 0.0625rem solid #818181;
    border-right: 0.0625rem solid #818181;
    color: #818181; }
    .cpg-search-pagination-buttons-item + .cpg-search-pagination-buttons-item {
      margin-left: .5rem;
      padding-left: .5rem;
      border-left: 0.0625rem solid #818181; }
    .cpg-search-pagination-buttons-item.selected span, .cpg-search-pagination-buttons-item.ellipsis span {
      color: #9c9a94 !important;
      cursor: default; }
    .cpg-search-pagination-buttons-item span {
      color: #1c7abf;
      cursor: pointer; }
      .cpg-search-pagination-buttons-item span:hover {
        color: #4a9dcf; }

.cpg-pagination-footer {
  border: 0.0625rem solid #fcfbf9;
  background: #f0eeed;
  padding: 0 0.625rem;
  margin: 1.25rem 0;
  box-shadow: 0px 0.0625rem 0.125rem rgba(0, 0, 0, 0.5);
  border-radius: 0.3125rem; }

.cpg-search-result {
  padding-top: 2rem; }
  .cpg-search-result-content .search-bar {
    display: flex; }
    .cpg-search-result-content .search-bar .MuiAutocomplete-root {
      display: flex;
      width: 100%; }
    .cpg-search-result-content .search-bar .MuiAutocomplete-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border: 1px solid #BBBBBB;
      padding: 0.5rem; }
    .cpg-search-result-content .search-bar-inner {
      display: flex;
      flex: 1;
      position: relative; }
    .cpg-search-result-content .search-bar-clear {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 1rem;
      display: flex; }
      .cpg-search-result-content .search-bar-clear svg {
        width: 1.25rem;
        height: 1.25rem;
        fill: #707070; }
    .cpg-search-result-content .search-bar-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .cpg-search-result-list .cpg-search-results-item {
    display: flex;
    padding: 1.25rem;
    margin-bottom: 1rem; }
    .cpg-search-result-list .cpg-search-results-item:hover {
      background-color: #f2f2f2; }
    .cpg-search-result-list .cpg-search-results-item-best-bet {
      border: 1px solid #0a4181;
      border-radius: 3px; }
    .cpg-search-result-list .cpg-search-results-item-icon {
      margin-right: 1rem;
      flex-shrink: 0; }
      .cpg-search-result-list .cpg-search-results-item-icon svg {
        width: 1.25rem;
        height: 1.25rem;
        fill: #1c7abf; }
    .cpg-search-result-list .cpg-search-results-item-text {
      flex-grow: 1; }
      .cpg-search-result-list .cpg-search-results-item-text h4 {
        margin: 0 0 0.25em; }
        .cpg-search-result-list .cpg-search-results-item-text h4 a {
          background-image: none;
          text-decoration: none; }
          .cpg-search-result-list .cpg-search-results-item-text h4 a:hover {
            text-decoration: underline; }
    .cpg-search-result-list .cpg-search-results-item-description {
      color: #2b5a67;
      margin: 1rem 0; }

.cpg-sidebar-search {
  min-width: 18.6875rem;
  padding-right: 2.5rem;
  flex: 0 0 18.6875rem; }

.cpg-search-sidebar {
  padding: 0.625rem;
  margin: 1.25rem 0;
  border-radius: 0.3125rem; }

.cpg-search-facets-list {
  margin-bottom: 1.25rem; }
  .cpg-search-facets-list-title {
    margin-bottom: 1rem; }
  .cpg-search-facets-list > strong {
    margin-bottom: 0.3125rem;
    display: block;
    font-size: 0.85rem; }
  .cpg-search-facets-list-item {
    display: flex;
    align-items: center; }
    .cpg-search-facets-list-item input[type="checkbox"] {
      margin-right: 0.625rem; }
    .cpg-search-facets-list-item label {
      display: flex;
      align-items: center; }
      .cpg-search-facets-list-item label svg {
        width: 1.125rem;
        height: 1.125rem; }
    .cpg-search-facets-list-item .option {
      display: flex;
      align-items: center;
      font-size: 0.85rem;
      margin-left: 0.3125rem;
      padding-bottom: 4px;
      cursor: pointer; }
  .cpg-search-facets-list .cpg-search-facets-list-footer {
    margin-top: 0.625rem; }
    .cpg-search-facets-list .cpg-search-facets-list-footer a {
      cursor: pointer; }
      .cpg-search-facets-list .cpg-search-facets-list-footer a + a {
        margin-left: .5rem;
        padding-left: .5rem;
        border-left: 0.0625rem solid #818181; }
      .cpg-search-facets-list .cpg-search-facets-list-footer a:hover {
        color: #4a9dcf; }

.adage-fiftyfifty {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem; }
  .adage-fiftyfifty-item {
    flex: 1 1 auto;
    width: 50%;
    display: block; }
  .adage-fiftyfifty-inner {
    display: flex; }
    .adage-fiftyfifty-inner.reversed .adage-fiftyfifty-item:first-child {
      display: none;
      visibility: hidden; }
    .adage-fiftyfifty-inner.reversed .adage-fiftyfifty-item:last-child {
      display: none;
      visibility: hidden; }

.adage-fiftyfifty-item + .adage-fiftyfifty-item {
  margin-left: 1rem; }

.adage-fiftyfifty-item .adage-wysiwyg-block {
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center; }

.adage-fiftyfifty-item p:first-child {
  margin-top: 0; }

.adage-fiftyfifty-item:last-child .adage-video-block-caption {
  text-align: right; }

.adage-fiftyfifty-item.gray {
  border-top: 3px solid #0a4181;
  background: #f5f5f5;
  padding: 2.15rem; }
  .adage-fiftyfifty-item.gray h2 {
    margin-bottom: 1rem;
    font-family: "Helvetica Neue LT W05_75 Bold", Arial, sans-serif;
    font-size: 1.75rem;
    line-height: 1.2; }

.adage-fiftyfifty-item #testimony #test-img-wrapper {
  float: left;
  margin-right: 1rem; }

.adage-fiftyfifty-item #testimony #test-textblock {
  width: 27.5rem;
  padding-left: 1rem;
  font-size: 0.875rem;
  line-height: 1.57143; }

.adage-fiftyfifty .adage-image-block {
  width: 100%; }
  .adage-fiftyfifty .adage-image-block .adage-video-thumbnail-container {
    position: static; }

.adage-fiftyfifty-title {
  border-bottom: 0; }
  .row0 .adage-fiftyfifty-title {
    font-size: 1.75rem;
    line-height: 1.21429; }

.adage-fiftyfifty .adage-block {
  width: 100%; }

.adage-fiftyfifty .adage-image-block, .adage-fiftyfifty .adage-video-block {
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  margin-top: 0; }
  .adage-fiftyfifty .adage-image-block > a, .adage-fiftyfifty .adage-video-block > a {
    width: 100%;
    height: 100%; }

.adage-fiftyfifty-inner.ratio-standard .adage-image-block {
  padding-bottom: 66.66667%; }

.adage-fiftyfifty-inner.ratio-standard .adage-video-block {
  padding-bottom: 56.25%; }

.adage-fiftyfifty-inner.ratio-square .adage-image-block {
  padding-bottom: 100%; }

.adage-fiftyfifty .adage-video-thumbnail-container {
  width: 100%; }
  .adage-fiftyfifty .adage-video-thumbnail-container img {
    width: 100%; }

.adage-fiftyfifty .ratio-standard .adage-video-thumbnail-container:after, .adage-fiftyfifty .ratio-square .adage-video-thumbnail-container:after {
  content: '';
  width: 100%;
  display: block; }

.adage-fiftyfifty .ratio-standard .adage-video-thumbnail-container:after {
  padding-bottom: 66.66667%; }

.adage-fiftyfifty .ratio-square .adage-video-thumbnail-container:after {
  padding-bottom: 100%; }

.cpg-small-cta-container {
  margin-top: 2rem; }
  .cpg-small-cta-container h2 {
    font-size: 1.3rem;
    font-family: "Helvetica Neue LT W05_75 Bold", Arial, sans-serif; }

.cpg-small-cta {
  display: flex; }
  .cpg-small-cta-right {
    flex: 1 1 50%;
    padding-left: 1rem; }
  .cpg-small-cta a {
    font-size: 1.1rem;
    font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif; }
    .cpg-small-cta a:not(:hover) {
      text-decoration: none; }

.adage-accordion table,
.cpg-table {
  width: 100%; }
  .adage-accordion table ul,
  .cpg-table ul {
    list-style: initial;
    margin-left: 25px; }
  
  .cpg-table-header {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    line-height: 1.11111; }
  .adage-accordion table thead,
  .cpg-table thead {
    border-bottom: 1px solid #818181;
    border-top: 1px solid #818181;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.57143; }
  .adage-accordion table th,
  .cpg-table th {
    background: #f5f5f5;
    vertical-align: bottom; }
  .adage-accordion table td, .adage-accordion table th,
  .cpg-table td,
  .cpg-table th {
    text-align: left;
    padding: 1rem;
    border-left: 1px solid #818181; }
    .adage-accordion table td:last-child, .adage-accordion table th:last-child,
    .cpg-table td:last-child,
    .cpg-table th:last-child {
      border-right: 1px solid #818181; }
    .adage-accordion table td:not(:first-child):empty, .adage-accordion table th:not(:first-child):empty,
    .cpg-table td:not(:first-child):empty,
    .cpg-table th:not(:first-child):empty {
      border-left: none; }
    .adage-accordion table td p, .adage-accordion table th p,
    .cpg-table td p,
    .cpg-table th p {
      margin: 0; }
  .adage-accordion table td,
  .cpg-table td {
    border-bottom: 1px solid #818181;
    padding-right: .8rem; }
  .adage-accordion table tr,
  .cpg-table tr {
    border-top: 1px solid #818181; }
    .adage-accordion table tr.highlighted,
    .cpg-table tr.highlighted {
      color: #f37321;
      font-weight: 700; }
  
  .cpg-table--borderless th {
    background: #ffffff; }
  
  .cpg-table--borderless thead {
    border-top: 0; }
  
  .cpg-table--borderless td,
  .cpg-table--borderless th {
    border-left: 0; }
    
    .cpg-table--borderless td:last-child,
    .cpg-table--borderless th:last-child {
      border-right: 0; }

.adage-accordion {
  /*max-width: $max-line-length;*/ }
  .adage-accordion-panel {
    overflow: hidden;
    transition: 0.2s height; }
    .adage-accordion-panel[aria-hidden="true"] {
      height: 0; }

.adage-accordion {
  margin-left: auto;
  margin-right: auto; }
  .adage-accordion .adage-accordion-trigger {
    font-size: 0.875rem;
    line-height: 1.57143;
    display: block;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #0079c1;
    text-shadow: none;
    box-shadow: none; }
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      .adage-accordion .adage-accordion-trigger {
        width: 102%; } }
    .adage-accordion .adage-accordion-trigger:hover {
      color: #0079c1; }
  .adage-accordion-item {
    border-top: 1px solid #e9e9e9;
    padding: 0 0.5rem; }
    .adage-accordion-item:last-child {
      border-bottom: 1px solid #e9e9e9; }
  .adage-accordion-panel-content {
    font-size: 0.875rem;
    line-height: 1.57143;
    line-height: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .adage-accordion table tr {
    border-top: 1px solid #818181; }
  .adage-accordion ul {
    line-height: 1.25; }
  .adage-accordion li {
    margin-bottom: .25rem; }

.adage-accordion-trigger {
  position: relative;
  padding-right: 1.875rem; }
  .adage-accordion-trigger:after {
    content: '';
    width: 0.625rem;
    height: 0.625rem;
    display: block;
    position: absolute;
    right: 0rem;
    top: 50%;
    transform: zrotate(-45deg);
    transition: 0.2s transform;
    border-color: #818181;
    border-style: solid;
    border-width: 0 2px 2px 0; }
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      .adage-accordion-trigger:after {
        right: .5rem; } }
  .adage-accordion-trigger[aria-expanded="true"]:after {
    transform: translateY(-0.44194rem) rotate(45deg); }

.accordioncontainerblock {
  width: 100%; }

.adage-wysiwyg-block:only-child {
  margin-top: 2rem; }

.adage-wysiwyg-block h3 {
  font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
  font-weight: 400; }
  .adage-wysiwyg-block h3 + hr {
    border: 0.5px solid #e9e9e9;
    height: 1px; }

.adage-wysiwyg-block p a:not(:hover), .adage-wysiwyg-block li a:not(:hover) {
  text-decoration: none; }

.adage-wysiwyg-block h4.variation.top {
  color: #f37321;
  font-weight: 400;
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif; }
  .adage-wysiwyg-block h4.variation.top:first-child {
    margin-top: 0; }

.gray-highlight .adage-wysiwyg-block .rich-text div:first-child {
  padding-bottom: 1rem; }

.gray-highlight .adage-wysiwyg-block .rich-text div:last-child {
  padding-top: 1rem; }

.adage-wysiwyg-block .rich-text hr:only-child {
  margin-top: -1rem; }

.adage-wysiwyg-block .rich-text .tiny-span-18 h2 {
  font-size: 1.3rem;
  font-family: "Helvetica Neue LT W05_75 Bold", Arial, sans-serif; }

.adage-wysiwyg-block ul, .adage-wysiwyg-block li {
  list-style: outside;
  /*li  {
            list-style: outside;
            margin-bottom: $space;
        }*/ }

.adage-block.blue-highlight .adage-block-inner, .adage-block.gray-highlight .adage-block-inner {
  padding: .5rem 1rem; }

.cpg-theme-purple .adage-wysiwyg-block {
  /*padding: 0 3.5rem;*/
  /*h3 {
            color: $accent-4;
        }*/ }
  .cpg-theme-purple .adage-wysiwyg-block h4 {
    font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif; }

.adage-block > .adage-video-block {
  /*max-width: $max-line-length;*/ }
  .adage-block > .adage-video-block-caption {
    max-width: 60rem;
    margin-top: 0.5rem; }

.adage-video-iframe {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  max-width: 100%;
  background: linear-gradient(rgba(233, 233, 233, 0.3), #e9e9e9); }
  .adage-video-iframe > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1.5rem; }

.adage-video-native {
  max-width: 100%; }
  .adage-video-native > video {
    width: 100%;
    height: auto;
    display: block; }

.adage-video-thumbnail {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0; }
  .adage-video-thumbnail-trigger {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative; }

.adage-video-block-caption ul {
  list-style: outside;
  padding-left: 1rem;
  margin-top: .5rem;
  font-size: 0.875rem; }

.col-xl-4 .adage-video-block, .col-xl-4 .adage-video-block-caption {
  padding: 0 1.25rem; }

.col-xl-4 .adage-video-block {
  margin-top: 1rem; }

.adage-video-thumbnail-trigger:before {
  content: '';
  display: block;
  position: absolute;
  transition: 0.2s;
  background: rgba(10, 65, 129, 0.5);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-top: -2.5rem;
  margin-left: -2.5rem;
  top: 50%;
  left: 50%;
  z-index: 1; }

.adage-video-thumbnail-trigger svg {
  position: absolute;
  fill: #c1d7e9;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.625rem;
  margin-left: -0.625rem;
  top: 50%;
  left: 50%;
  z-index: 2; }

.adage-video-thumbnail-trigger:hover:before, .adage-video-thumbnail-trigger:focus:before {
  background: #0a4181;
  box-shadow: 0.16rem 0.16rem 0.6rem rgba(6, 47, 85, 0.3); }

main > .adage-block > .adage-video-block .adage-video-thumbnail {
  position: static; }

.adage-block-inner .video-content {
  display: none; }

.adage-block-inner .video-link {
  background-image: unset; }

.adage-block-inner .adage-video-thumbnail-container {
  position: relative;
  display: inline-block; }
  .adage-block-inner .adage-video-thumbnail-container:hover {
    cursor: pointer; }
  .adage-block-inner .adage-video-thumbnail-container svg {
    position: absolute;
    width: 3.36rem;
    height: 3.36rem;
    top: calc(50% - 1.68rem);
    left: calc(50% - 1.68rem);
    z-index: 2; }

main > .adage-block > .adage-image-block {
  max-width: 60rem; }

.adage-image-block {
  margin-top: 2rem;
  color: #818181;
  font-size: .85rem; }
  .adage-image-block > img {
    max-width: 100%; }

.cpgtextlistitemblock {
  width: 100%; }

.list-item-block {
  border: 10px solid #DFE9F1;
  display: flex;
  width: 100%;
  margin-bottom: 2rem; }
  .list-item-block > div {
    padding: 1rem 0.75rem;
    flex: 1 1 auto;
    width: 50%; }
    .list-item-block > div ul {
      list-style: disc;
      margin: 1rem;
      padding-left: 1rem; }
  .list-item-block-first {
    background: #f5f5f5; }

.adage-highlight-item {
  display: flex; }
  .adage-highlight-item-clickable {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%; }
    .adage-highlight-item-clickable > * {
      min-height: 1px;
      flex-shrink: 0; }

.cpg-highlight-item {
  display: flex;
  align-items: flex-start;
  padding: 2.9rem 0; }
  .cpg-highlight-item.border {
    border-top: 1px solid #e9e9e9; }
  .cpg-highlight-item-content {
    margin-left: 2.1rem; }
    .cpg-highlight-item-content h3 {
      margin-top: 0; }
    .cpg-highlight-item-content .btn {
      margin-top: 1rem; }
  .cpg-highlight-item-image {
    width: 15.625em;
    flex: 1 0 auto; }
    .cpg-highlight-item-image .adage-block,
    .cpg-highlight-item-image .adage-block-inner {
      margin: 0;
      height: 100%; }
    .cpg-highlight-item-image .adage-image-block {
      position: relative;
      overflow: hidden; }
      .cpg-highlight-item-image .adage-image-block img {
        height: 15.625em;
        width: 15.625em;
        object-fit: cover; }

.adage-highlight-item-clickable {
  text-decoration: none;
  color: #062f55; }
  .adage-highlight-item-clickable:hover, .adage-highlight-item-clickable:focus, .adage-highlight-item-clickable:active {
    color: #062f55; }

.adage-highlight-item-details {
  padding: 1.5rem; }
  @media screen and (max-width: 1280px) {
    .adage-highlight-item-details {
      padding: 1.25rem; } }
  @media screen and (max-width: 640px) {
    .adage-highlight-item-details {
      padding: 1rem; } }

.adage-highlight-item-cta {
  flex-grow: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem; }
  @media screen and (max-width: 1280px) {
    .adage-highlight-item-cta {
      padding-left: 1.25rem; } }
  @media screen and (max-width: 640px) {
    .adage-highlight-item-cta {
      padding-left: 1rem; } }
  @media screen and (max-width: 1280px) {
    .adage-highlight-item-cta {
      padding-right: 1.25rem; } }
  @media screen and (max-width: 640px) {
    .adage-highlight-item-cta {
      padding-right: 1rem; } }
  @media screen and (max-width: 1280px) {
    .adage-highlight-item-cta {
      padding-bottom: 1.25rem; } }
  @media screen and (max-width: 640px) {
    .adage-highlight-item-cta {
      padding-bottom: 1rem; } }

.cpg-highlight-item-title {
  color: #303030; }

.adage-highlight-item {
  box-shadow: 0.16rem 0.16rem 0.6rem rgba(6, 47, 85, 0.3); }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cpg-main > div > .row {
    display: block; } }

.adage-block > .adage-video-block {
  /*max-width: $max-line-length;*/ }
  .adage-block > .adage-video-block-caption {
    max-width: 60rem;
    margin-top: 0.5rem; }

.adage-video-iframe {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  max-width: 100%;
  background: linear-gradient(rgba(233, 233, 233, 0.3), #e9e9e9); }
  .adage-video-iframe > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1.5rem; }

.adage-video-native {
  max-width: 100%; }
  .adage-video-native > video {
    width: 100%;
    height: auto;
    display: block; }

.adage-video-thumbnail {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0; }
  .adage-video-thumbnail-trigger {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative; }

.adage-video-block-caption ul {
  list-style: outside;
  padding-left: 1rem;
  margin-top: .5rem;
  font-size: 0.875rem; }

.col-xl-4 .adage-video-block, .col-xl-4 .adage-video-block-caption {
  padding: 0 1.25rem; }

.col-xl-4 .adage-video-block {
  margin-top: 1rem; }

.adage-video-thumbnail-trigger:before {
  content: '';
  display: block;
  position: absolute;
  transition: 0.2s;
  background: rgba(10, 65, 129, 0.5);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-top: -2.5rem;
  margin-left: -2.5rem;
  top: 50%;
  left: 50%;
  z-index: 1; }

.adage-video-thumbnail-trigger svg {
  position: absolute;
  fill: #c1d7e9;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.625rem;
  margin-left: -0.625rem;
  top: 50%;
  left: 50%;
  z-index: 2; }

.adage-video-thumbnail-trigger:hover:before, .adage-video-thumbnail-trigger:focus:before {
  background: #0a4181;
  box-shadow: 0.16rem 0.16rem 0.6rem rgba(6, 47, 85, 0.3); }

main > .adage-block > .adage-video-block .adage-video-thumbnail {
  position: static; }

.adage-block-inner .video-content {
  display: none; }

.adage-block-inner .video-link {
  background-image: unset; }

.adage-block-inner .adage-video-thumbnail-container {
  position: relative;
  display: inline-block; }
  .adage-block-inner .adage-video-thumbnail-container:hover {
    cursor: pointer; }
  .adage-block-inner .adage-video-thumbnail-container svg {
    position: absolute;
    width: 3.36rem;
    height: 3.36rem;
    top: calc(50% - 1.68rem);
    left: calc(50% - 1.68rem);
    z-index: 2; }

/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 * 
 * http://www.idangero.us/swiper/
 * 
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: March 10, 2017
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height; }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  top: 18.5rem;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
  background: #e54d1b;
  opacity: 1; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #b8e986; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */ }

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px; }

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center; }

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

.adage-gallery-heading {
  position: absolute;
  top: 9rem;
  left: 10%;
  z-index: 99;
  background: #ffffff;
  opacity: 0.8;
  color: #f37321;
  padding: .25rem;
  text-transform: uppercase; }

.adage-gallery-container {
  margin-bottom: 0.5rem;
  width: 60rem; }

@media (min-width: 1024px) {
  .adage-gallery-item-caption {
    display: flex;
    margin-top: 1rem; }
    .adage-gallery-item-caption-details {
      flex: 0 1 auto;
      width: calc(75% - 2rem);
      padding-right: 2rem;
      order: -1; }
    .adage-gallery-item-caption-page {
      flex: 1 0 auto;
      text-align: right; } }

@media (max-width: 1024px) {
  .adage-gallery-item-caption-details {
    margin-top: 1rem; }
  .adage-gallery-item-caption-page {
    text-align: center; } }

@media (max-width: 640px) {
  .adage-gallery-item-caption {
    margin-top: 0.5rem; }
    .adage-gallery-item-caption-details {
      margin-top: 0.5rem; } }

.adage-gallery .adage-image-block img {
  width: 100%; }

.adage-gallery-container {
  overflow: hidden; }

.adage-gallery-item {
  position: relative; }
  .adage-gallery-item:before {
    content: '';
    background: rgba(193, 215, 233, 0.75);
    transition: 0.2s;
    pointer-events: none;
    opacity: 0;
    z-index: 1; }
  .adage-gallery-item:not(.swiper-slide-active):before {
    opacity: 0; }
  .adage-gallery-item-caption {
    font-size: 0.625rem;
    line-height: 1.5;
    color: #818181; }

.adage-gallery-btn-next, .adage-gallery-btn-prev {
  position: absolute;
  top: 10rem;
  z-index: 10;
  margin-top: -1.5rem;
  color: #c1d7e9;
  background-color: #818181;
  height: 5rem;
  width: 2rem;
  border: 0;
  cursor: pointer;
  transition: 0.2s;
  padding: .2rem;
  opacity: .8; }
  .adage-gallery-btn-next svg, .adage-gallery-btn-prev svg {
    height: 4.5rem;
    width: 100%;
    fill: #c1d7e9;
    transition: 0.2s; }
  .adage-gallery-btn-next:focus, .adage-gallery-btn-next:hover, .adage-gallery-btn-next:active, .adage-gallery-btn-prev:focus, .adage-gallery-btn-prev:hover, .adage-gallery-btn-prev:active {
    background: #c1d7e9;
    color: #0a4181;
    box-shadow: 0.16rem 0.16rem 0.6rem rgba(6, 47, 85, 0.3); }
    .adage-gallery-btn-next:focus svg, .adage-gallery-btn-next:hover svg, .adage-gallery-btn-next:active svg, .adage-gallery-btn-prev:focus svg, .adage-gallery-btn-prev:hover svg, .adage-gallery-btn-prev:active svg {
      fill: #0a4181; }
  @media (max-width: 640px) {
    .adage-gallery-btn-next, .adage-gallery-btn-prev {
      margin-top: -3rem;
      padding: .6rem; } }

.adage-gallery-btn-next {
  right: 1rem; }

.adage-gallery-btn-prev {
  left: 1rem; }

.adage-columns-auto-container {
  margin-top: 1rem;
  margin-bottom: 2rem; }
  @media (min-width: 640px) {
    .adage-columns-auto-container {
      display: flex; } }
  @media (max-width: 1024px) {
    .adage-columns-auto-container {
      flex-wrap: wrap; } }
  .adage-columns-auto-container > div, .adage-columns-auto-container > article, .adage-columns-auto-container > figure {
    flex: 1 1 0;
    width: 0;
    margin-right: 2.25rem; }
    @media screen and (max-width: 1280px) {
      .adage-columns-auto-container > div, .adage-columns-auto-container > article, .adage-columns-auto-container > figure {
        margin-right: 1.5rem; } }
    @media screen and (max-width: 1024px) {
      .adage-columns-auto-container > div, .adage-columns-auto-container > article, .adage-columns-auto-container > figure {
        margin-right: 1.5rem; } }
    @media screen and (max-width: 640px) {
      .adage-columns-auto-container > div, .adage-columns-auto-container > article, .adage-columns-auto-container > figure {
        margin-right: 1.25rem; } }
    @media (min-width: 1024px) {
      .adage-columns-auto-container > div:last-child, .adage-columns-auto-container > article:last-child, .adage-columns-auto-container > figure:last-child {
        margin-right: 0; } }
    @media (min-width: 640px) and (max-width: 1024px) {
      .adage-columns-auto-container > div, .adage-columns-auto-container > article, .adage-columns-auto-container > figure {
        flex: 0 0 auto;
        width: calc(50% - 0.75rem);
        margin-bottom: 1.5rem; }
        .adage-columns-auto-container > div:nth-last-child(odd), .adage-columns-auto-container > article:nth-last-child(odd), .adage-columns-auto-container > figure:nth-last-child(odd) {
          margin-right: 0; }
          .adage-columns-auto-container > div:nth-last-child(odd):first-child, .adage-columns-auto-container > article:nth-last-child(odd):first-child, .adage-columns-auto-container > figure:nth-last-child(odd):first-child {
            width: 100%; }
        .adage-columns-auto-container > div:nth-last-child(3) ~ *, .adage-columns-auto-container > article:nth-last-child(3) ~ *, .adage-columns-auto-container > figure:nth-last-child(3) ~ * {
          margin-bottom: 0; } }
    @media (max-width: 640px) {
      .adage-columns-auto-container > div, .adage-columns-auto-container > article, .adage-columns-auto-container > figure {
        width: 100%;
        margin-bottom: 1.25rem; } }

.adage-fwcta-inner {
  max-width: 45.5rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

.adage-fwcta-content {
  margin-top: 1rem; }

.adage-fwcta-cta {
  margin-top: 2rem; }

.adage-staff-container {
  margin-top: 1rem;
  margin-bottom: 2rem; }

.adage-staff-item {
  display: flex;
  align-items: flex-start; }
  .adage-staff-item-img {
    flex: 0 0 auto;
    width: 25%;
    max-width: 10rem;
    margin-right: 2.25rem; }
    @media screen and (max-width: 1280px) {
      .adage-staff-item-img {
        margin-right: 1.5rem; } }
    @media screen and (max-width: 1024px) {
      .adage-staff-item-img {
        margin-right: 1.5rem; } }
    @media screen and (max-width: 640px) {
      .adage-staff-item-img {
        margin-right: 1.25rem; } }
  .adage-staff-item-content {
    flex: 1 1 auto;
    max-width: 22.75rem; }

.adage-staff-item-img .adage-image-block {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 999rem; }
  .adage-staff-item-img .adage-image-block > * {
    position: absolute; }

.adage-staff-item-role {
  font-size: 0.625rem;
  line-height: 1.5;
  color: #818181; }

.adage-staff-item-info {
  margin-top: 0.5rem; }

.adage-newsletter-signup-inner {
  max-width: 45.5rem;
  margin-left: auto;
  margin-right: auto; }

.adage-newsletter-signup-description {
  margin-top: 1rem; }

.adage-newsletter-signup-form-item {
  flex-grow: 1;
  margin-right: 0; }

.cpg-imgtext-item {
  display: flex;
  padding-top: 1.25rem; }
  .cpg-imgtext-item.border {
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 2rem; }
  .cpg-imgtext-item h3 {
    margin-top: 0; }
  .cpg-imgtext-item-img.larger {
    width: 240px !important;
    height: 240px !important; }
  .cpg-imgtext-item-img.extra-large {
    width: 320px !important;
    height: 320px !important;
    margin-right: 40px; }
  .cpg-imgtext-item-img:not(.smaller) {
    width: 11.875rem;
    flex-shrink: 0; }
  .cpg-imgtext-item-img .cpg-block {
    margin: 0; }
  .cpg-imgtext-item-img .cpg-block-inner {
    padding: 0; }
  .cpg-imgtext-item-img-Left + .cpg-imgtext-item-content {
    padding-left: 1rem;
    padding-right: 0; }
  .cpg-imgtext-item-content {
    flex: 1;
    padding-right: 1rem; }
    .cpg-imgtext-item-content p:first-child {
      margin-top: 0; }
  .cpg-imgtext-item h5 {
    color: #303030; }
  .cpg-imgtext-item .adage-image-block {
    margin: 0; }
  .tabbed-block .cpg-imgtext-item {
    border: 0; }
  .cpg-imgtext-item.smaller .rich-text a:nth-child(2):not(.no-btn) {
    padding: .35rem 2rem .25rem;
    margin-left: 0.6rem; }
  .cpg-imgtext-item.smaller img {
    max-width: 90px;
    max-height: 90px; }

.tabbed-block .imagetextitemblock {
  background: #ffffff; }

.cpg-sidebar-block {
  padding-bottom: 1.86rem; }
  .cpg-sidebar-block-title {
    padding: 0.5rem 0.75rem; }
  .cpg-sidebar-block-content {
    padding: 0.75rem;
    padding-right: 1.25rem; }
    .cpg-sidebar-block-content ol {
      margin-left: 1.25rem; }
    .cpg-sidebar-block-content ul {
      list-style: none;
      margin-left: 0; }
      .cpg-sidebar-block-content ul > li + li {
        margin-top: 0.25rem; }
      .cpg-sidebar-block-content ul > li {
        position: relative;
        padding-left: 1rem;
        background: url("~/Static/img/png-icons/double-chevron.png") no-repeat;
        background-position: 0 0;
        background-position-y: .2rem;
        background-position-x: -.1rem; }

.cpg-sidebar-block-title {
  background: #818181;
  color: #ffffff;
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.57143; }

.cpg-sidebar-block-content {
  background: #f5f5f5; }
  .cpg-sidebar-block-content .button {
    display: flex;
    justify-content: center;
    margin: 0 1rem;
    padding: .5rem !important; }
  .cpg-sidebar-block-content p:first-child {
    margin-top: 0; }

.cpg-sidebar-link-group {
  width: 17rem;
  margin-top: 2rem; }

.cpg-sidebar-link-group-title {
  padding-bottom: 0.5rem;
  margin-bottom: 0; }

.cpg-sidebar-link-group-item + .cpg-sidebar-link-group-item {
  margin-top: 1rem; }

.cpg-sidebar-link-block-title {
  color: #303030;
  font-weight: 400; }

.cpg-sidebar-link-group-title {
  color: #303030;
  font-weight: 400; }

.cpg-sidebar-link-group-item + .cpg-sidebar-link-group-item {
  margin-top: 1rem; }

.cpg-sidebar-link-item {
  border-bottom: 1px solid #e9e9e9; }
  .cpg-sidebar-link-item:first-child {
    border-top: 1px solid #e9e9e9; }
  .cpg-sidebar-link-item > a {
    display: flex;
    padding: .5rem .75rem .5rem 0;
    color: #303030;
    font-size: 0.875rem;
    line-height: 1.57143;
    text-decoration: none;
    background-image: url("~/Static/img/chevron-right-solid-orange.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: .55rem; }

.hidden {
  display: none !important; }

.cpg-header-image-block {
  margin-bottom: .25rem; }
  .cpg-header-image-block.border {
    border-bottom: 1px solid #e9e9e9; }
  .cpg-header-image-block .cpg-header-img-wrapper {
    width: 100%;
    max-height: 15rem;
    overflow: hidden; }
  .cpg-header-image-block .cpg-header-img {
    width: 100%; }
  .cpg-header-image-block .cpg-header-image-title {
    display: flex;
    align-items: center; }
    .cpg-header-image-block .cpg-header-image-title h2 {
      margin: .5rem 0;
      flex-grow: 1; }
    .cpg-header-image-block .cpg-header-image-title.Orange h2 {
      color: #f37321; }
    .cpg-header-image-block .cpg-header-image-title .link-block {
      margin: 0; }
    .cpg-header-image-block .cpg-header-image-title .Secondary {
      padding: .25rem .75rem;
      border-radius: 0;
      width: unset; }
  .cpg-header-image-block .Black h2 {
    color: #303030; }
  .cpg-header-image-block .Orange h2 {
    color: #f37321; }
  .cpg-header-image-block .Blue h2 {
    color: #0a4181; }
  .cpg-header-image-block svg {
    width: 3.5rem;
    height: 3.5rem;
    margin: .5rem .25rem; }

.cpg-otp-block .cpg-otp-heading {
  color: #303030;
  font-size: 1.125rem;
  line-height: 1.11111;
  font-weight: 700;
  margin-bottom: .5rem; }

.cpg-otp-block .on-this-page-link-list li {
  position: relative;
  padding-bottom: .75rem;
  padding-left: 1.5rem;
  background: url("~/Static/img/png-icons/double-chevron.png") no-repeat;
  background-position-y: .15rem;
  background-position-x: 0;
  margin-right: 0.5rem; }

.cpg-otp-block .on-this-page-link-list a {
  text-transform: capitalize;
  margin: .5rem 0;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem; }

.tabbed-page-nav,
.tabbed-block-nav {
  display: flex;
  align-items: center; }
  .tabbed-page-nav .tabbed-page-tab,
  .tabbed-page-nav .tabbed-block-tab,
  .tabbed-block-nav .tabbed-page-tab,
  .tabbed-block-nav .tabbed-block-tab {
    width: 20rem;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
    font-weight: 400;
    text-align: center; }
    .tabbed-page-nav .tabbed-page-tab:first-child,
    .tabbed-page-nav .tabbed-block-tab:first-child,
    .tabbed-block-nav .tabbed-page-tab:first-child,
    .tabbed-block-nav .tabbed-block-tab:first-child {
      margin-left: 0; }
    .tabbed-page-nav .tabbed-page-tab:last-child,
    .tabbed-page-nav .tabbed-block-tab:last-child,
    .tabbed-block-nav .tabbed-page-tab:last-child,
    .tabbed-block-nav .tabbed-block-tab:last-child {
      margin-right: 0; }

.tabbed-block {
  margin-bottom: 4rem; }

.tabbed-page,
.tabbed-block {
  max-width: 1000px; }

.tabbed-page-nav .tabbed-page-tab,
.tabbed-page-nav .tabbed-block-tab,
.tabbed-block-nav .tabbed-page-tab,
.tabbed-block-nav .tabbed-block-tab {
  background: #f5f5f5;
  font-size: 1.125rem;
  line-height: 1.11111;
  color: #303030;
  cursor: pointer; }
  .tabbed-page-nav .tabbed-page-tab.highlight,
  .tabbed-page-nav .tabbed-block-tab.highlight,
  .tabbed-block-nav .tabbed-page-tab.highlight,
  .tabbed-block-nav .tabbed-block-tab.highlight {
    border: 0;
    font-weight: 700;
    background: #c1d7e9; }
  .tabbed-page-nav .tabbed-page-tab:only-child,
  .tabbed-page-nav .tabbed-block-tab:only-child,
  .tabbed-block-nav .tabbed-page-tab:only-child,
  .tabbed-block-nav .tabbed-block-tab:only-child {
    width: 100%;
    text-align: left;
    cursor: default; }

.tabbed-page.highlight .tabbed-block-content,
.tabbed-block.highlight .tabbed-block-content {
  display: block; }

.tabbed-page.four-column .tabbed-block-content .row .column:nth-child(4), .tabbed-page.four-column .tabbed-block-content .row .column:nth-child(3),
.tabbed-block.four-column .tabbed-block-content .row .column:nth-child(4),
.tabbed-block.four-column .tabbed-block-content .row .column:nth-child(3) {
  width: 125px; }

.tabbed-page.three-column .tabbed-block-content .row .column,
.tabbed-block.three-column .tabbed-block-content .row .column {
  text-align: left !important; }

.tabbed-page.forms-publications-block .tabbed-block-content,
.tabbed-block.forms-publications-block .tabbed-block-content {
  display: none; }
  .tabbed-page.forms-publications-block .tabbed-block-content .row,
  .tabbed-block.forms-publications-block .tabbed-block-content .row {
    background: #e9e9e9;
    margin: 0;
    padding: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    max-width: 100%; }
    .tabbed-page.forms-publications-block .tabbed-block-content .row:nth-child(2n),
    .tabbed-block.forms-publications-block .tabbed-block-content .row:nth-child(2n) {
      background-color: #f5f5f5; }
  .tabbed-page.forms-publications-block .tabbed-block-content .column,
  .tabbed-block.forms-publications-block .tabbed-block-content .column {
    color: #303030;
    padding: 0.5rem;
    display: table-cell;
    margin-bottom: 0;
    overflow-wrap: break-word; }
    .tabbed-page.forms-publications-block .tabbed-block-content .column p,
    .tabbed-block.forms-publications-block .tabbed-block-content .column p {
      display: inline; }

.tabbed-page .tabbed-page-content.highlight,
.tabbed-block .tabbed-page-content.highlight {
  border: 0;
  background: none; }

.tabbed-page {
  padding: 1rem;
  width: 60rem;
  margin: 0 auto; }

.tabbed-page-nav {
  border-bottom: 2px solid #0079c1; }
  .tabbed-page-nav > .tabbed-page-tab {
    color: #0079c1; }
    .tabbed-page-nav > .tabbed-page-tab.highlight {
      background: #0079c1;
      color: #ffffff;
      font-weight: 400; }

.tabbed-page-description {
  padding: 1rem 1rem 2rem 0; }
  .tabbed-page-description a {
    padding: 0;
    color: #0079c1; }
  .tabbed-page-description p {
    margin: 0;
    margin-bottom: .25rem; }

.forms-publications-block .svg-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.3125rem; }
  .forms-publications-block .svg-icon-inline {
    display: inline;
    vertical-align: middle; }
    .forms-publications-block .svg-icon-inline ~ p {
      display: inline; }

.forms-publications-block .column {
  /*.right {
            text-align: right;
        }
        .left{
            text-align: left;
        }*/ }
  .forms-publications-block .column:last-child, .forms-publications-block .column:nth-last-child(2) {
    text-align: right; }
    .forms-publications-block .column:last-child:first-child, .forms-publications-block .column:nth-last-child(2):first-child {
      text-align: left; }
  .forms-publications-block .column:last-child .svg-icon {
    height: 0.9375rem;
    width: 0.9375rem;
    vertical-align: sub;
    margin-left: 0; }
  .forms-publications-block .column .cpg-mail-link {
    padding: .25rem .5rem; }

.forms-publications-tab-trigger.highlight {
  font-size: 1.125rem;
  line-height: 1.11111;
  color: #303030;
  font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
  font-weight: 700;
  background: #818181; }

.forms-publications-page .tabbed-page-tab {
  width: 10rem; }

.mail-forms-publications-page {
  padding: 1rem;
  width: 60rem;
  margin: 0 auto; }
  .mail-forms-publications-page > ul {
    list-style: disc;
    padding-left: 1rem; }
  .mail-forms-publications-page > h3 {
    margin-bottom: 2rem; }

.EPiServerForms .Form__MainBody {
  max-width: 31.25rem; }

.EPiServerForms .Form__Title {
  font-size: 1.75rem;
  line-height: 1.21429;
  font-weight: 700;
  margin-bottom: 2rem; }

.EPiServerForms .Form__Description {
  color: #f37321;
  margin-bottom: 1rem;
  padding-bottom: .5rem;
  border-bottom: 2px solid #e9e9e9; }

.EPiServerForms .Form__Element select,
.EPiServerForms .Form__Element label.Form__Element__Caption,
.EPiServerForms .Form__Element input.FormTextbox__Input {
  display: block; }

.EPiServerForms .Form__Element label.Form__Element__Caption {
  font-size: 0.875rem;
  line-height: 1.57143; }

.EPiServerForms .Form__Element textarea.FormTextbox__Input,
.EPiServerForms .Form__Element input.FormTextbox__Input {
  width: 100%;
  padding: .5rem;
  background: #e9e9e9;
  border: 0;
  border-radius: 2px; }
  .EPiServerForms .Form__Element textarea.FormTextbox__Input:focus, .EPiServerForms .Form__Element textarea.FormTextbox__Input:active,
  .EPiServerForms .Form__Element input.FormTextbox__Input:focus,
  .EPiServerForms .Form__Element input.FormTextbox__Input:active {
    /*background: $white;*/ }

.EPiServerForms .Form__Element textarea.FormTextbox__Input {
  height: 8rem; }

.EPiServerForms .Form__Element.FormTextbox {
  margin-bottom: 1rem; }

.EPiServerForms .Form__Element.FormSubmitButton {
  cursor: pointer;
  vertical-align: top; }

.EPiServerForms .Form__Element.FormResetButton {
  cursor: pointer; }
  .EPiServerForms .Form__Element.FormResetButton:hover {
    text-decoration: none; }

.EPiServerForms .Form__Element .FormCaptcha__Refresh {
  display: block; }

.EPiServerForms .Form__Element.ValidationRequired .Form__Element__Caption {
  position: relative;
  display: inline-block;
  min-width: 0; }
  .EPiServerForms .Form__Element.ValidationRequired .Form__Element__Caption::after {
    display: block;
    position: absolute;
    right: -8px;
    top: -2px;
    content: '*';
    color: #f37321; }

.EPiServerForms .Form__Element select {
  color: #f37321;
  padding: .25rem;
  font-weight: 700;
  border-radius: 5px;
  width: 14rem; }
  .EPiServerForms .Form__Element select option {
    color: #303030; }

.EPiServerForms .Form__Status .Form__Status__Message.hide {
  display: none; }

.EPiServerForms .Form__Success__Message {
  background-color: #f5f5f5 !important; }

.vertical-media-block .adage-block {
  margin: 0; }

.vertical-media-block .adage-video-block-caption {
  font-size: 0.875rem; }

.vertical-media-block h1, .vertical-media-block h2, .vertical-media-block h3, .vertical-media-block h4, .vertical-media-block h5 {
  font-size: 1.125rem;
  line-height: 1.11111;
  color: #f37321;
  font-weight: 700;
  padding: 1rem 0;
  margin-top: 0;
  margin-bottom: .5rem;
  border-bottom: 1px solid #e9e9e9; }

.vertical-media-block h3 {
  padding-bottom: .25rem;
  margin-bottom: 1.5rem; }

.vertical-media-block > p {
  font-size: 0.875rem; }

.vertical-media-block audio {
  width: 100%; }

.vertical-media-block .adage-image-block img {
  width: 90%;
  margin-bottom: 1rem; }

.link-block {
  margin-top: 2rem;
  align-items: center; }
  .link-block-svg {
    display: flex; }
    .link-block-svg svg {
      height: 1.5rem;
      width: 1.5rem;
      fill: #818181;
      margin-right: .5rem; }
  .link-block a {
    position: relative;
    padding: 1rem; }
    .link-block a:before {
      content: '';
      position: absolute;
      top: 0;
      left: -2rem;
      width: 2rem;
      height: 100%; }
    .link-block a.btn {
      background-image: none;
      font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
      letter-spacing: .01rem;
      cursor: pointer; }

.cpg-audience-member-container {
  position: relative;
  padding: 1rem;
  padding-bottom: 0; }
  .cpg-audience-member-container ul {
    position: absolute;
    top: 4rem;
    right: 0;
    z-index: 1;
    max-width: 10.5rem; }
    .cpg-audience-member-container ul.last {
      right: -5rem; }
  .cpg-audience-member-container li {
    font-size: 0.875rem;
    line-height: 1.57143;
    margin-bottom: .25rem; }
  .cpg-audience-member-container a {
    text-decoration: none; }

.cpg-audience-member-title {
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 400;
  color: #7f6634;
  margin-top: 0;
  margin-bottom: 1rem; }

.cpg-audience-member-block {
  position: relative; }
  .cpg-audience-member-block img {
    transform: translateX(-3rem);
    height: 15rem;
    width: auto; }

.cpg-audience-member-info {
  width: 12rem;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: rgba(229, 184, 95, 0.8);
  box-shadow: -3px -2px 4px #818181;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 1;
  padding: .5rem;
  font-size: 0.625rem;
  line-height: 1.5; }
  .cpg-audience-member-info span {
    z-index: 1;
    display: block; }
    .cpg-audience-member-info span:last-child {
      color: #fffbbf; }

.cpg-email-signup {
  box-shadow: 0px 8px 15px 0 rgba(0, 0, 0, 0.05), 0px 6px 20px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px; }
  .cpg-email-signup .cpg-form-header {
    background: #27ae60;
    text-align: center;
    padding: 2rem;
    border-radius: 10px 10px 0 0; }
    .cpg-email-signup .cpg-form-header .cpg-form-title--email-signup {
      font-size: 1.125rem;
      line-height: 1.11111;
      font-weight: 700;
      color: #ffffff;
      margin: 0; }
  .cpg-email-signup .cpg-form-body {
    padding: 1rem;
    display: flex;
    flex-direction: column; }
    .cpg-email-signup .cpg-form-body .cpg-form-field {
      height: 3rem;
      width: 100%;
      background: #e9e9e9;
      margin: .5rem 1rem;
      border: 0;
      border-radius: 5px;
      padding: 1rem; }
    .cpg-email-signup .cpg-form-body .cpg-form-row {
      display: flex; }
  .cpg-email-signup .cpg-form-footer {
    margin: 2rem;
    padding: 1rem 0;
    border-top: 2px solid #e9e9e9; }
    .cpg-email-signup .cpg-form-footer button {
      padding: 1rem 2rem;
      -webkit-appearance: none;
      border-width: 1px;
      float: none; }

.cpg-course {
  display: flex;
  position: relative;
  background: #ffffff;
  margin-bottom: 1rem; }
  .cpg-course.large {
    padding: 1rem;
    flex-flow: row nowrap;
    padding: 2rem;
    background: #f5f5f5;
    max-width: 55rem; }
    .cpg-course.large .btn {
      width: 10rem;
      padding: .75rem .5rem; }
  .cpg-course.small {
    display: inline-block;
    vertical-align: top;
    margin: 0; }
    .cpg-course.small .btn {
      padding: .5rem;
      min-width: 7.5rem;
      border-radius: 0; }
    .cpg-course.small h4 {
      margin-top: 0;
      margin-bottom: 1rem;
      font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif; }
    .cpg-course.small .adage-image-block {
      margin-bottom: 0 !important; }
  .cpg-course-left {
    flex: 1 0 25%;
    margin-right: 1.5rem; }
    .cpg-course-left img {
      background: #ffffff; }
  .cpg-course-right {
    flex: 1 1 75%; }
    .cpg-course-right h3 {
      margin-bottom: 1.5rem;
      padding-bottom: .5rem;
      border-bottom: 1px solid #e9e9e9;
      font-weight: 400; }
    .cpg-course-right p {
      margin-top: 0; }
    .cpg-course-right .cpg-course-description h1, .cpg-course-right .cpg-course-description h2, .cpg-course-right .cpg-course-description h3 {
      font-size: 0.875rem;
      line-height: 1.57143;
      margin-bottom: 0; }
  .cpg-course-main .adage-image-block {
    margin-bottom: 1rem; }
    .cpg-course-main .adage-image-block img {
      width: 100%;
      height: auto;
      background: #ffffff; }
  .cpg-course-main h1, .cpg-course-main h2 {
    font-size: 1.125rem;
    line-height: 1.11111; }
  .cpg-course-main p {
    width: 20rem; }
  .cpg-course-links {
    display: flex; }
    .cpg-course-links * {
      flex: 0 1 auto;
      margin-right: .5rem;
      margin-top: 0;
      text-align: center; }
      .cpg-course-links *:last-child a {
        background: #f5f5f5;
        color: #0079c1; }
      .cpg-course-links *:first-child a {
        background: #0079c1;
        color: #ffffff; }
  .cpg-course-tag {
    position: absolute;
    top: 0;
    left: 0;
    background: #818181;
    padding: .75rem;
    color: #ffffff;
    font-size: 0.875rem; }

[class*="col"] .cpg-course {
  width: 100%; }
  [class*="col"] .cpg-course.small .adage-block-inner .adage-video-thumbnail-container {
    width: 100%; }

.cpg-three-column-border-block {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 3rem;
  border-top: 1px solid #e9e9e9; }

.cpg-one-third-column {
  padding: 0 .75rem;
  min-height: 5rem;
  flex: 1; }
  .cpg-one-third-column:not(:first-child) {
    border-left: 1px solid #e9e9e9; }
  .cpg-one-third-column h3 {
    margin: 0 0 .25rem; }
  .cpg-one-third-column ul {
    list-style: outside;
    padding-left: 1.5rem; }

.cpg-quote {
  border-left: 4px solid #f37321;
  padding: 2.15rem 3.55rem;
  background: #f5f5f5;
  display: flex;
  margin: 1rem 0;
  position: relative; }
  .cpg-quote:before {
    content: "“";
    display: block;
    position: absolute;
    top: .75rem;
    left: 1rem;
    color: #f37321;
    font-size: 3.25rem;
    font-style: italic; }
  .cpg-quote-content {
    font-style: italic;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%; }
    .cpg-quote-content:first-child {
      margin-left: 0; }
  .cpg-quote blockquote {
    margin-bottom: 1rem; }
  .cpg-quote blockquote p::before, .cpg-quote blockquote p::after {
    display: inline-block;
    vertical-align: bottom;
    position: relative;
    content: "\""; }
  .cpg-quote-image {
    width: 7.75rem;
    height: auto;
    margin-bottom: .5rem; }
  .cpg-quote p {
    margin-top: 0; }
  .cpg-quote cite {
    align-self: flex-end; }

.cpg-section-container {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #e9e9e9;
  padding: 3rem 0; }
  .cpg-section-container-last {
    /*border-bottom: 1px solid $neutral-5;*/
    margin-bottom: 3rem; }
    .cpg-section-container-last-blue {
      border-bottom: 2px solid #0079c1; }
  .cpg-section-container .cpg-table {
    width: 100%; }

.cpg-section-side-content {
  flex-basis: 30%;
  padding-left: 2.15rem;
  padding-right: 1rem; }
  .cpg-section-side-content.no-background > h2 {
    padding: 0.5rem;
    margin: 0;
    background: none;
    text-align: left;
    color: #303030;
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.11111;
    font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
    line-height: 1.25rem;
    border-bottom: 4px solid #404040;
    padding-left: 0; }
  .cpg-section-side-content > h2 {
    padding: 0.5rem;
    margin: 0;
    background: #f37321;
    text-align: center;
    color: #ffffff;
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.11111;
    font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif; }
  .cpg-section-side-content .cpg-otp-block {
    margin-top: 2rem; }
  .cpg-section-side-content .adage-video-block {
    padding: 1.5rem 1.4rem 0; }
  .cpg-section-side-content .adage-video-block-caption {
    padding: 0 1.4rem; }
  .cpg-section-side-content .videoblock {
    margin: 20px; }

.cpg-section-main-content {
  flex-basis: 71%;
  order: 1;
  /*&.no-background {
        border-right: none !important;
        border-left: none !important;
    }*/ }
  .Left .cpg-section-main-content {
    order: 0;
    padding-right: 2.5rem; }
    .Left .cpg-section-main-content:not(.no-background) {
      border-right: 1px solid #e9e9e9; }
  .Right .cpg-section-main-content {
    padding-left: 2.5rem; }
    .Right .cpg-section-main-content:not(.no-background) {
      border-left: 1px solid #e9e9e9; }
  .cpg-section-main-content h3 {
    margin: 0; }
  .cpg-section-main-content .adage-block:first-child .adage-wysiwyg-block,
  .cpg-section-main-content .adage-block:nth-child(2) .adage-wysiwyg-block {
    margin-top: 0; }

.cpg-theme-purple .cpg-section-container {
  padding: 3rem 3.5rem 3rem;
  border-top: 0;
  border-bottom: 1px solid #e9e9e9; }
  .cpg-theme-purple .cpg-section-container h2:not(.orange-arrow-list) {
    background: #ffffff;
    border-top: 4px solid #5f259f;
    color: #5f259f;
    text-align: left;
    font-family: "Helvetica Neue LT W05_75 Bold", Arial, sans-serif; }
  .cpg-theme-purple .cpg-section-container .adage-wysiwyg-block {
    padding: 0 1rem; }

.cpg-theme-purple .cpg-section-main-content {
  border-right: 0; }

.cpg-theme-purple .cpg-section-side-content {
  padding-left: 0; }

.vertical-media-block .adage-block {
  margin: 0; }

.vertical-media-block .adage-video-block-caption {
  font-size: 0.875rem; }

.vertical-media-block h1, .vertical-media-block h2, .vertical-media-block h3, .vertical-media-block h4, .vertical-media-block h5 {
  font-size: 1.125rem;
  line-height: 1.11111;
  color: #f37321;
  font-weight: 700;
  padding: 1rem 0;
  margin-top: 0;
  margin-bottom: .5rem;
  border-bottom: 1px solid #e9e9e9; }

.vertical-media-block h3 {
  padding-bottom: .25rem;
  margin-bottom: 1.5rem; }

.vertical-media-block > p {
  font-size: 0.875rem; }

.vertical-media-block audio {
  width: 100%; }

.vertical-media-block .adage-image-block img {
  width: 90%;
  margin-bottom: 1rem; }

.cpg-read-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  border-top: 1px solid #e9e9e9; }
  .cpg-read-more.blue-border {
    border-top: 2px solid #0079c1; }
  .cpg-read-more h4 {
    font-weight: 400;
    font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 1.11111;
    margin-top: 0;
    margin-bottom: 0;
    color: #303030; }
  .cpg-read-more a {
    margin: 0;
    padding: 1rem;
    padding-right: 1.5rem;
    background: url("~/Static/img/chevron-right-solid-blue.svg") no-repeat right;
    background-size: .8rem;
    text-align: left;
    text-decoration: none;
    font-weight: 400;
    font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.57143; }
    .cpg-read-more a:hover {
      text-decoration: underline; }
  .cpg-read-more-body {
    display: flex;
    align-items: flex-start; }
    .cpg-read-more-body p {
      margin-bottom: 0;
      margin-top: .5rem; }
    .cpg-read-more-body:first-child {
      flex: 0 1 75%; }
    .cpg-read-more-body h3 {
      margin-bottom: 0; }
  .cpg-read-more .adage-image-block {
    margin: 0;
    height: 6.5rem;
    width: 6.5rem; }
    .cpg-read-more .adage-image-block img {
      height: 6.5rem;
      width: 6.5rem; }

.cpg-theme-purple .cpg-read-more > a {
  color: #5f259f;
  background: url("~/Static/img/chevron-right-solid-purple.svg") no-repeat right;
  background-size: .8rem; }

.cpg-theme-purple .cpg-read-more.blue-border {
  border-top: 2px solid #5f259f; }

.cpg-img-content {
  display: flex; }
  .cpg-img-content.Left .cpg-imgtext-item-img {
    margin-right: 1rem; }
  .cpg-img-content.Right {
    flex-direction: row-reverse; }
    .cpg-img-content.Right .cpg-imgtext-item-img {
      margin-left: 1rem; }
  .cpg-img-content.smaller {
    width: unset; }
    .cpg-img-content.smaller img {
      width: 75px; }
  .cpg-img-content svg {
    position: absolute;
    width: 3.36rem;
    height: 3.36rem;
    top: calc(50% - 1.68rem);
    left: calc(50% - 1.68rem);
    z-index: 2; }

.cpg-imgtext-item.smaller {
  padding-top: .25rem; }

.row > .cpgimagecontentblock {
  width: 100%; }
  .row > .cpgimagecontentblock .cpg-img-content .content-wrapper {
    width: 100%; }

.cpg-theme-purple .cpg-language-header .cpg-language-header-content a {
  background-image: none; }
  .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:hover, .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:focus {
    text-decoration: underline; }
  .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:first-of-type {
    background: #5f259f;
    color: #ffffff;
    border-radius: 0;
    border: 0; }
  .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:last-of-type {
    color: #5f259f;
    border: 0;
    border-radius: 0;
    padding: 0.75rem 2rem; }
    .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:last-of-type:hover, .cpg-theme-purple .cpg-language-header .cpg-language-header-content a:last-of-type:focus {
      color: #5f259f; }

.cpg-theme-purple .cpg-language-header .cpg-language-header-links {
  border-left: 4px solid #5f259f; }
  .cpg-theme-purple .cpg-language-header .cpg-language-header-links a {
    color: #303030;
    padding: .4rem; }
    .cpg-theme-purple .cpg-language-header .cpg-language-header-links a:visited {
      color: #5f259f; }

.cpg-language-header > img {
  height: 240px;
  width: 100%; }

.cpg-language-header-body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 0 3rem;
  border-bottom: 1px solid #d8d8d8; }
  .cpg-language-header-body h4 {
    font-weight: 400;
    font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
    margin-bottom: 1rem; }

.cpg-language-header-icon {
  width: 7.1rem;
  height: auto; }

.cpg-language-header-content {
  flex: 0 0 70%;
  padding: 0 1rem; }

.cpg-language-header-links {
  width: 12rem;
  height: 8rem;
  border-left: 4px solid #0079c1;
  padding: .85rem;
  padding-left: 1.7rem; }
  .cpg-language-header-links li {
    margin: .25rem;
    padding: .5rem; }
    .cpg-language-header-links li:first-child {
      background: #f5f5f5; }

.text-list-highlight {
  display: flex; }
  .text-list-highlight .first-col {
    padding: 0 50px 50px 0;
    flex: 0 0 190px; }
    .text-list-highlight .first-col h3 {
      color: #000000;
      border-top: 2px solid #0a4181; }
  .text-list-highlight .second-col {
    margin-left: 1.5rem; }

/*
* Styles in this file are specific rules
* applied to classes that are imported with the markup
* from cpg.org's old website, to be used to achieve
* edge-case and one-off styles that our own blocks cannot account for.
*/
img.right {
  float: right;
  padding-left: 0.5rem;
  padding-bottom: 1rem; }

img.left {
  float: left;
  margin-right: 1rem;
  margin-bottom: 1rem; }

p.right {
  text-align: right; }

a.right {
  float: right;
  margin-bottom: 1rem; }

h3.underline {
  color: #303030;
  border-bottom: solid 1px #d8d8d8;
  margin: 0 0 1.25rem 0 !important;
  padding: 0 0 0.625rem 0; }

h4.underline {
  border-bottom: solid 1px #d8d8d8; }

.box {
  display: block;
  padding: 0.625rem;
  margin: 1.25rem 0;
  background: #e5ecf9; }

.box-06 {
  padding: 0.625rem;
  border: 1px solid #acdae7;
  background-color: #fbfdfe; }

.rich-text .forms-questions-button {
  margin-top: 0; }

.forms-questions-button {
  margin: 0px;
  padding: .7rem;
  border-right: solid 1px #d8d8d8;
  display: inline-block;
  font-size: 1.2em;
  vertical-align: middle;
  max-width: 100%;
  text-align: center; }
  .forms-questions-button a {
    text-decoration: none; }
    .forms-questions-button a:hover, .forms-questions-button a:focus {
      text-decoration: underline; }
  .forms-questions-button:last-child {
    border-right: 0; }

.pod-wrapper.last {
  text-align: right; }

.pod .tiny-span-7 a {
  border-bottom: 0; }

.bottom-nav,
.small-bottom-nav {
  border-top: 2px solid #062f55;
  padding-top: 1.45rem; }
  .bottom-nav > ul, .bottom-nav li,
  .small-bottom-nav > ul,
  .small-bottom-nav li {
    list-style: none !important; }
  .bottom-nav ul,
  .small-bottom-nav ul {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-left: 0; }
  .bottom-nav li,
  .small-bottom-nav li {
    margin-left: 2rem;
    border-bottom: 2px solid transparent; }
    .bottom-nav li:not(:first-child):focus, .bottom-nav li:not(:first-child):hover,
    .small-bottom-nav li:not(:first-child):focus,
    .small-bottom-nav li:not(:first-child):hover {
      border-color: #e54d1b; }
  .bottom-nav h4,
  .small-bottom-nav h4 {
    margin: 0;
    color: #000000;
    font-size: 0.875rem;
    line-height: 1.57143; }
  .bottom-nav a,
  .small-bottom-nav a {
    color: #303030;
    padding-bottom: .25rem;
    text-decoration: none;
    margin-bottom: 0; }
    .bottom-nav a:focus, .bottom-nav a:hover,
    .small-bottom-nav a:focus,
    .small-bottom-nav a:hover {
      color: #818181; }

.bottom-nav {
  font-size: 1.125rem;
  line-height: 1.11111;
  padding-top: 1.75rem; }
  .bottom-nav h4 {
    font-size: 1.125rem;
    line-height: 1.11111; }

.updated-Title {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem; }
  .updated-Title img {
    width: 3rem;
    height: 3rem;
    margin: .5rem; }
  .updated-Title .updated-pageTitle {
    font-size: 1.75rem;
    line-height: 1.21429;
    color: #f37321;
    font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif; }

#second-info-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  #second-info-section h3 {
    width: 100%;
    color: #0a4181;
    font-size: 1.75rem;
    line-height: 1.2;
    margin-bottom: 1.75rem; }
  #second-info-section .info-box-padding {
    width: 20.75rem; }
    #second-info-section .info-box-padding h2 {
      font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
      font-size: 1.75rem;
      line-height: 1.2;
      margin-top: 2.85rem; }
    #second-info-section .info-box-padding p {
      font-size: 0.875rem;
      line-height: 1.57143; }
  #second-info-section #financial-icon-box,
  #second-info-section #health-icon-box,
  #second-info-section #training-icon-box {
    height: 9.5rem;
    display: flex;
    justify-content: center;
    align-items: center; }
  #second-info-section #financial-icon-box {
    background: #578c28; }
  #second-info-section #health-icon-box {
    background: #a50519; }
  #second-info-section #training-icon-box {
    background: #0079c1; }

#banner-message-box {
  text-align: center;
  padding-bottom: 3rem;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 1rem; }
  #banner-message-box h3 {
    font-size: 1.75rem;
    line-height: 1.2;
    color: #0a4181;
    font-family: "Helvetica Neue LT W05_75 Bold", Arial, sans-serif; }
  #banner-message-box p {
    font-size: 0.875rem;
    line-height: 1.57143;
    padding: 0 5rem; }

.orange-arrow-list {
  color: #f37321;
  display: inline-block;
  vertical-align: top;
  margin: 0 1rem;
  font-size: 2.5rem;
  line-height: 1.2; }
  .orange-arrow-list + p {
    display: inline-block;
    width: 90%; }

iframe[src="https://cw.na1.hgncloud.com/church_pension/"] {
  width: 100%;
  height: 48rem; }
  iframe[src="https://cw.na1.hgncloud.com/church_pension/"] .warningMessage {
    display: none; }

.svIndex {
  width: 32%;
  display: inline-block; }
  .svIndex .cartoons.clearfix {
    margin-bottom: 1rem; }
    .svIndex .cartoons.clearfix a:not(:hover) {
      text-decoration: none; }

.header-div,
#header-div {
  height: 2.9rem;
  background-color: #f5f5f5;
  margin-bottom: 1.45rem;
  border-top: solid 2px #0a4181; }
  .header-div h5,
  .header-div .sub-section-header,
  .header-div #sub-section-header,
  #header-div h5,
  #header-div .sub-section-header,
  #header-div #sub-section-header {
    color: #303030;
    margin-bottom: .4rem;
    margin-left: 1.45rem;
    font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.2;
    line-height: 2.9rem; }

ul.list-columns {
  list-style: none;
  height: 11rem;
  display: flex;
  flex-flow: column wrap;
  margin: 0;
  font-size: 0.875rem;
  padding-top: 1rem; }
  ul.list-columns li {
    list-style: none;
    height: 2.3rem;
    width: 22rem;
    margin-bottom: .75rem;
    margin-right: 3rem;
    padding: .5rem;
    text-align: center;
    background: #f5f5f5; }

ul.cpg-top-navigation-list {
  min-width: 36.1875rem;
  list-style: none;
  padding: 1rem 0 0 0;
  margin: 0 0 1.5rem 0;
  border-top: 1px solid #e9e9e9; }
  ul.cpg-top-navigation-list li {
    display: inline-block;
    padding: 0 0.25rem 0px 0.25rem; }

h3 > .neutral {
  font-size: 1.44rem;
  font-weight: 600;
  color: #303030; }

h3.neutral {
  font-size: 1.44rem;
  font-weight: 600;
  color: #303030; }

/*@font-face {
    font-family: "Helvetica Neue LT W05_55 Roman";
    src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot");
    src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix") format("embedded-opentype"), url("../fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("../fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff"), url("../fonts/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue LT W05_65 Medium";
    src: url("../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot");
    src: url("../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("embedded-opentype"), url("../fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"), url("../fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff"), url("../fonts/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue LT W05_75 Bold";
    src: url("../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot");
    src: url("../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix") format("embedded-opentype"), url("../fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"), url("../fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff"), url("../fonts/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf") format("truetype");
}
*/
.adage-header {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif; }
  .adage-header-inner {
    width: 840px;
    margin: 0 auto;
    display: flex;
    padding: 8.75px;
    padding-bottom: 44px; }
    .adage-header-inner-lg {
      padding-bottom: 44px;
      padding-top: .625rem; }

.adage-header-logo {
  width: 270px;
  margin-right: 8.75px; }
  .adage-header-logo:focus {
    box-shadow: none; }
  .adage-header-logo.mouse-click:focus > img {
    box-shadow: none;
    outline: none; }
  .adage-header-logo img:focus {
    box-shadow: none;
    outline: none; }

.epi-editmode .cpg-mega-nav-list {
  flex-flow: row; }

.epi-editmode .nav-list-item-link {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%; }

/*ECD header */
.ecd-header {
  background-color: #062f55; }
  .ecd-header * {
    color: #ffffff; }
  .ecd-header-inner {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex; }
    .ecd-header-inner h2 {
      margin: 0;
      color: #ffffff;
      font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
      font-size: 26px; }

.ecd-footer-content {
  border-top: 1px solid #e1e1e1;
  padding-top: 24px; }
  .ecd-footer-content > div {
    margin-top: 0px; }

body {
  display: flex;
  flex-direction: column;
  min-width: 0; }
  body ::selection {
    background: #0a4181; }

header {
  flex: 0 0 auto; }

footer {
  flex: 10 0 auto; }

main {
  flex: 1 0 auto;
  background: #ffffff; }

.skip-link {
  position: absolute;
  background: #ffffff;
  padding: 0.5rem;
  z-index: 1000;
  top: 0;
  left: 0;
  transform: translateY(-100%); }
  .skip-link:focus {
    transform: none; }

li p {
  margin: 0; }

main.top-border {
  border-top: 5px solid #0a4181; }

.indent-2 {
  margin-left: 7rem !important; }

.ecd-page {
  display: flex;
  align-items: flex-start; }
  .ecd-page-sidebar {
    width: 25%;
    padding-right: 1.5rem; }
  .ecd-page-content {
    width: 100%; }
    .ecd-page-content-section {
      border-top: 1px solid #e1e1e1;
      padding: 1rem 0 2rem 0; }
    .ecd-page-content-subtitle-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 1rem; }
    .ecd-page-content-subtitle-map {
      display: flex; }
    .ecd-page-content-subtitle-icon {
      width: 0.85rem;
      height: 1rem;
      background: url(/static/img/mapit-drop.png) no-repeat left top/contain; }

/*@font-face {
    font-family: "Helvetica Neue LT W05_55 Roman";
    src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot");
    src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix") format("embedded-opentype"), url("../fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("../fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff"), url("../fonts/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue LT W05_65 Medium";
    src: url("../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot");
    src: url("../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("embedded-opentype"), url("../fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"), url("../fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff"), url("../fonts/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue LT W05_75 Bold";
    src: url("../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot");
    src: url("../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix") format("embedded-opentype"), url("../fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"), url("../fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff"), url("../fonts/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf") format("truetype");
}
*/
.ecd-nav-list-item-link, .ecd-mobile-nav-list-item-link {
  padding: 0.5rem;
  display: block;
  color: #303030;
  text-decoration: none; }
  .ecd-nav-list-item-link:visited, .ecd-mobile-nav-list-item-link:visited {
    color: #303030; }
  .ecd-nav-list-item-link:hover, .ecd-mobile-nav-list-item-link:hover {
    color: #0a4181;
    background: #E1E1E1; }
  .active.ecd-nav-list-item-link, .active.ecd-mobile-nav-list-item-link {
    color: #0a4181;
    background: #f5f5f5;
    font-family: "Helvetica Neue LT W05_75 Bold", "Helvetica", "Helvetica Neue LT W05_65 Medium", Arial, sans-serif; }

.ecd-nav {
  border-top: 2px solid #0a4181;
  border-bottom: 2px solid #0a4181; }

.ecd-mobile-nav {
  position: relative; }
  .ecd-mobile-nav-header {
    background-color: #0a4181;
    display: flex;
    justify-content: space-between;
    height: 3.75rem; }
    .ecd-mobile-nav-header-inner {
      display: flex;
      align-items: center;
      padding-left: 1.5rem; }
  .ecd-mobile-nav-trigger {
    border-radius: 0px;
    border-bottom-color: transparent;
    font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
    display: inline-flex;
    align-items: center; }
    .ecd-mobile-nav-trigger svg {
      fill: #ffffff;
      height: 1.25rem;
      width: 1.25rem; }
      .ecd-mobile-nav-trigger svg + span {
        margin: 0.5rem; }
  .ecd-mobile-nav-panel {
    background: #e9e9e9;
    position: absolute;
    width: 100%;
    z-index: 100;
    display: none;
    box-shadow: 1px 1px 2px 0 #9f98a6; }
    @media (min-width: 47.9375em) {
      .ecd-mobile-nav-panel {
        width: 20rem;
        position: absolute;
        right: 0; } }
    .ecd-mobile-nav-panel.nav-open {
      display: block; }

.ecd-page .btn-primary, .ecd-page .btn-secondary, .ecd-page .new-button, .ecd-page .adage-theme-dark.adage-fwcta .btn, .adage-theme-dark.adage-fwcta .ecd-page .btn, .ecd-page .adage-theme-dark.adage-newsletter-signup .btn, .adage-theme-dark.adage-newsletter-signup .ecd-page .btn, .ecd-page .quickfind-pagination-prev-next {
  display: inline-block;
  min-height: 40px;
  min-width: 120px;
  padding: 0.25rem 16px;
  text-decoration: none;
  border-radius: 3px;
  border: 1px solid transparent;
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
  font-size: 0.875rem;
  color: #ffffff; }
  .ecd-page .btn-primary[disabled], .ecd-page .btn-secondary[disabled], .ecd-page .new-button[disabled], .ecd-page .adage-theme-dark.adage-fwcta .btn[disabled], .adage-theme-dark.adage-fwcta .ecd-page .btn[disabled], .ecd-page .adage-theme-dark.adage-newsletter-signup .btn[disabled], .adage-theme-dark.adage-newsletter-signup .ecd-page .btn[disabled], .ecd-page .quickfind-pagination-prev-next[disabled] {
    cursor: not-allowed;
    opacity: .5; }
  .ecd-page .btn-primary:focus, .ecd-page .btn-secondary:focus, .ecd-page .new-button:focus, .ecd-page .adage-theme-dark.adage-fwcta .btn:focus, .adage-theme-dark.adage-fwcta .ecd-page .btn:focus, .ecd-page .adage-theme-dark.adage-newsletter-signup .btn:focus, .adage-theme-dark.adage-newsletter-signup .ecd-page .btn:focus, .ecd-page .quickfind-pagination-prev-next:focus {
    opacity: 1; }

.ecd-page .btn-primary {
  background: #038A3F;
  outline: 1px solid transparent;
  outline-offset: 5px;
  transition: 0.2s all; }
  .ecd-page .btn-primary:hover, .ecd-page .btn-primary:focus {
    background: #02612C; }
  .ecd-page .btn-primary:focus {
    box-shadow: 0 0 0 2px #ffffff;
    outline-offset: 1px;
    outline-color: #02612C; }

.ecd-page .btn-secondary, .ecd-page .new-button, .ecd-page .adage-theme-dark.adage-fwcta .btn, .adage-theme-dark.adage-fwcta .ecd-page .btn, .ecd-page .adage-theme-dark.adage-newsletter-signup .btn, .adage-theme-dark.adage-newsletter-signup .ecd-page .btn, .ecd-page .quickfind-pagination-prev-next {
  background: #ffffff;
  color: #2081D9;
  border: 1px solid #174983;
  outline: 1px solid transparent;
  outline-offset: 5px;
  transition: 0.2s all; }
  .ecd-page .btn-secondary:hover, .ecd-page .new-button:hover, .ecd-page .adage-theme-dark.adage-fwcta .btn:hover, .adage-theme-dark.adage-fwcta .ecd-page .btn:hover, .ecd-page .adage-theme-dark.adage-newsletter-signup .btn:hover, .adage-theme-dark.adage-newsletter-signup .ecd-page .btn:hover, .ecd-page .quickfind-pagination-prev-next:hover {
    color: #174983;
    box-shadow: 0px 2px 2.5px #CECECE; }
  .ecd-page .btn-secondary:hover, .ecd-page .btn-secondary[disabled], .ecd-page .new-button:hover, .ecd-page .adage-theme-dark.adage-fwcta .btn:hover, .adage-theme-dark.adage-fwcta .ecd-page .btn:hover, .ecd-page .adage-theme-dark.adage-newsletter-signup .btn:hover, .adage-theme-dark.adage-newsletter-signup .ecd-page .btn:hover, .ecd-page .quickfind-pagination-prev-next:hover, .ecd-page .new-button[disabled], .ecd-page .adage-theme-dark.adage-fwcta .btn[disabled], .adage-theme-dark.adage-fwcta .ecd-page .btn[disabled], .ecd-page .adage-theme-dark.adage-newsletter-signup .btn[disabled], .adage-theme-dark.adage-newsletter-signup .ecd-page .btn[disabled], .ecd-page .quickfind-pagination-prev-next[disabled] {
    border-color: transparent; }
  .ecd-page .btn-secondary:focus, .ecd-page .new-button:focus, .ecd-page .adage-theme-dark.adage-fwcta .btn:focus, .adage-theme-dark.adage-fwcta .ecd-page .btn:focus, .ecd-page .adage-theme-dark.adage-newsletter-signup .btn:focus, .adage-theme-dark.adage-newsletter-signup .ecd-page .btn:focus, .ecd-page .quickfind-pagination-prev-next:focus {
    box-shadow: 0 0 0 2px #ffffff;
    outline-offset: 1px;
    outline-color: #80B7E9; }

.ecd-page h1, .ecd-page h2,
.ecd-page h1, .ecd-page h2 {
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif; }

.ecd-page h3, .ecd-page h5 {
  color: #303030;
  font-family: "Helvetica Neue LT W05_75 Bold", "Helvetica", "Helvetica Neue LT W05_65 Medium", Arial, sans-serif; }

.ecd-page h1 {
  color: #303030;
  font-size: 34px; }

.ecd-page h2 {
  color: #174983;
  font-size: 26px; }

.ecd-page h3 {
  font-size: 22px; }

.ecd-page h5 {
  font-size: 16px; }

.ecd-page strong, .ecd-page bold {
  font-family: "Helvetica Neue LT W05_75 Bold", "Helvetica", "Helvetica Neue LT W05_65 Medium", Arial, sans-serif; }

.ecd-page .form-item label, .ecd-page .form-item-item label {
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
  font-size: 14px;
  color: #303030; }
  .ecd-page .form-item label > span, .ecd-page .form-item-item label > span {
    font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #303030; }

.ecd-body .MuiTypography-root,
.ecd-body .MuiMenuItem-root,
.ecd-body .MuiInputBase-input {
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400; }

@media (max-width: 40em) {
  .ecd-body .MuiInputBase-input {
    font-size: 16px; } }

a {
  font-weight: normal;
  color: #174983; }

a:visited {
  color: #174983; }

a[href^="mailto:"],
a[href^="http://"],
a[href^="https://"],
a[href$=".doc"],
a[href$=".docx"],
a[href$=".pdf"] {
  padding-right: 1.5rem;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: right; }

a[href$=".pdf"] {
  background-image: url("~/Static/img/pdf.svg"); }

a[href$=".doc"], a[href$=".docx"] {
  background-image: url("~/Static/img/file.svg");
  background-size: .8rem; }

a[href^="http://"], a[href^="https://"] {
  background-image: url("~/Static/img/external.svg"); }

a[href^="mailto:"] {
  background-image: url("~/Static/img/email.svg"); }

.ss_email {
  content: url("~/Static/img/email.svg");
  height: .8rem; }

/*Make sure that we don't show the external on certain links*/
a[href^="https://player.vimeo"], a[href^="https://test.cpg"], a[href^="https://qatest.cpg"], a[href^="https://dev.cpg"], a[href^="https://cpg"], a[href^="https://prod.cpg"], a[href^="https://www.cpg"], a[href^="http://localhost"], a[href^="http://churchpension"], a[href^="https://youtu.be"], .no-icon, .noicon {
  background-image: none !important; }
  a[href^="https://player.vimeo"]:not(.btn), a[href^="https://test.cpg"]:not(.btn), a[href^="https://qatest.cpg"]:not(.btn), a[href^="https://dev.cpg"]:not(.btn), a[href^="https://cpg"]:not(.btn), a[href^="https://prod.cpg"]:not(.btn), a[href^="https://www.cpg"]:not(.btn), a[href^="http://localhost"]:not(.btn), a[href^="http://churchpension"]:not(.btn), a[href^="https://youtu.be"]:not(.btn), .no-icon:not(.btn), .noicon:not(.btn) {
    padding-right: 0; }

a[href$=".docx"] {
  background-position: right center; }

.adage-video-thumbnail-container a[href^="https://youtu.be"], .adage-video-thumbnail-container a[href^="http://youtu.be"], .adage-video-thumbnail-container a[href^="https://www.youtube"], .adage-video-thumbnail-container a[href^="http://www.youtube"] {
  padding-right: 0; }

.quickfind-autocomplete-container {
  position: relative; }

.quickfind-autocomplete-list {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 100%;
  background: #f5f5f5; }

.quickfind-autocomplete-link {
  display: block;
  width: 100%;
  padding: 0.5rem;
  color: #0F6FA8; }
  .quickfind-autocomplete-link-detail {
    display: block;
    font-weight: 400;
    color: #303030; }
  .quickfind-autocomplete-link:hover {
    background: #BBBBBB; }

.quickfind-autocomplete-divider {
  border-top: 1px solid #e1e1e1; }

.ecd-page .form-item label {
  margin-bottom: 0; }
  .ecd-page .form-item label + .MuiInputBase-root {
    margin-top: 0;
    max-width: 20rem; }

.ecd-page .form-item .MuiGrid-root {
  margin-top: 0; }

.ecd-page .form-item .MuiGrid-item .MuiFormControlLabel-root {
  width: 100%; }

.ecd-page .form-item .MuiGrid-item .MuiCheckbox-root + .MuiFormControlLabel-label {
  width: 100%; }

.ecd-page .form-item .MuiGrid-item .Mui-focusVisible + .MuiFormControlLabel-label {
  color: #ffffff;
  background-color: #767676; }

.ecd-page .form-item.quickfind-autocomplete-container {
  max-width: 20rem; }

.ecd-page .form-item .MuiInputLabel-asterisk {
  color: #ad3333;
  margin-left: 0.25rem; }

.ecd-page .form-group .form-item + .form-item {
  margin-top: 24px; }

.ecd-page .form-group .form-item-item {
  margin-top: 24px; }
  .ecd-page .form-group .form-item-item .quickfind-scroll-container {
    border: 1px solid #c1c1c1; }
    .ecd-page .form-group .form-item-item .quickfind-scroll-container .form-item {
      margin-top: 0px; }

.quickfind-advanced-cta {
  margin-top: 1rem;
  transition: 0.2s;
  color: #0F6FA8; }
  .quickfind-advanced-cta:hover, .quickfind-advanced-cta:active, .quickfind-advanced-cta:focus {
    color: #205791; }

.quickfind-scroll-container {
  height: 12.5rem;
  border: 1px solid #5f5c55;
  overflow: scroll;
  padding: 1rem; }

.quickfind-button-wrapper {
  margin-top: 2rem; }

.quickfind-parish-section-columns {
  grid-template-columns: 1fr 2fr;
  column-gap: 2rem; }
  @media (min-width: 64em) {
    .quickfind-parish-section-columns {
      display: grid; } }
  .quickfind-parish-section-columns > div {
    margin-top: 24px; }

.form-item-item .quickfind-scroll-container {
  padding: 0.25rem 1rem;
  overflow-x: auto;
  max-width: 20rem; }
  @media (min-width: 64em) {
    .form-item-item .quickfind-scroll-container {
      max-width: 41.5rem; } }

.quickfind-results-table {
  margin-bottom: 2rem; }
  .quickfind-results-table-row {
    display: table;
    table-layout: fixed;
    width: 100%;
    max-width: 100%; }
    .quickfind-results-table-row-cell {
      display: table-cell;
      padding: 0.25rem; }
    .quickfind-results-table-row-header {
      background: #A1B7D0; }
  .quickfind-results-table-link {
    transition: 0.2s;
    color: #0F6FA8; }
    .quickfind-results-table-link:hover, .quickfind-results-table-link:active, .quickfind-results-table-link:focus {
      color: #205791; }
  .quickfind-results-table-content > .quickfind-results-table-row:nth-child(even) {
    background: #E1E1E1; }

.quickfind-pagination {
  display: flex;
  justify-content: space-between; }
  .quickfind-pagination-prev-next {
    padding: 0.25rem 0.25rem !important;
    margin-left: 0.25rem;
    height: 1.5em !important;
    min-width: 0rem !important;
    border-color: transparent !important; }
  .quickfind-pagination-page-current {
    margin-left: 1rem; }
  .quickfind-pagination-page-link {
    transition: 0.2s;
    color: #0F6FA8;
    margin-left: 1rem; }
    .quickfind-pagination-page-link:hover, .quickfind-pagination-page-link:active, .quickfind-pagination-page-link:focus {
      color: #205791; }

.quickfind-parish-results-item {
  border: 1px solid #000000;
  margin-bottom: 20px;
  padding: 0.25rem 1rem;
  display: grid;
  grid-template-columns: auto 12fr 1fr 1fr; }
  .quickfind-parish-results-item-actions {
    display: flex; }
  .quickfind-parish-results-item-index {
    margin-right: 1rem; }
  .quickfind-parish-results-item-mapit {
    margin-left: 1rem;
    white-space: nowrap; }
  .quickfind-parish-results-item-content {
    flex: 1 1 auto;
    line-height: normal; }
    .quickfind-parish-results-item-content p {
      line-height: normal;
      margin: 0px; }
  .quickfind-parish-results-item:nth-child(even) {
    background: #E1E1E1; }
  .quickfind-parish-results-item-link {
    transition: 0.2s;
    color: #0F6FA8; }
    .quickfind-parish-results-item-link:hover, .quickfind-parish-results-item-link:active, .quickfind-parish-results-item-link:focus {
      color: #205791; }

.quickfind-parish-results-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem; }

.quickfind-parish-results-formbuttons {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .quickfind-parish-results-formbuttons .quickfind-button-wrapper {
    margin-top: 0; }

@media (min-width: 40em) {
  .quickfind-parish-results-sort {
    width: 14rem; } }

@media (max-width: 40em) {
  .quickfind-parish-results-sort-desktop {
    display: none; } }

@media (min-width: 40em) {
  .quickfind-parish-results-sort-mobile {
    display: none; } }

.quickfind-clergy-results-item {
  border: 1px solid #d8d8d8;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  .quickfind-clergy-results-item-content {
    border-bottom: 1px solid #d8d8d8;
    padding: 0.25rem 0.5rem 0.35rem;
    display: flex;
    justify-content: space-between; }
  .quickfind-clergy-results-item:nth-child(even) {
    background: #E1E1E1; }
  .quickfind-clergy-results-item-link {
    transition: 0.2s;
    color: #0F6FA8; }
    .quickfind-clergy-results-item-link:hover, .quickfind-clergy-results-item-link:active, .quickfind-clergy-results-item-link:focus {
      color: #205791; }
  .quickfind-clergy-results-item-label {
    text-align: start; }
  .quickfind-clergy-results-item-value {
    text-align: end; }

.quickfind-clergy-results-title {
  padding: 0.25rem;
  font-family: "Helvetica Neue LT W05_75 Bold", "Helvetica", "Helvetica Neue LT W05_65 Medium", Arial, sans-serif; }

.quickfind-clergy-results-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem; }

.quickfind-clergy-results-formbuttons {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .quickfind-clergy-results-formbuttons .quickfind-button-wrapper {
    margin-top: 0; }

.mail-formbuttons {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .mail-formbuttons .quickfind-button-wrapper {
    margin-top: 0; }

.quickfind-detail {
  margin-top: 1rem;
  transition: 0.2s;
  color: #0F6FA8; }
  .quickfind-detail-section-title {
    background: #E1E1E1;
    padding: 0.25rem; }
    .quickfind-detail-section-title p {
      margin: 0; }
  .quickfind-detail-section-inner {
    padding-bottom: 1rem; }
  .quickfind-detail-section-columns {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.25rem; }
    @media (min-width: 64em) {
      .quickfind-detail-section-columns {
        display: grid; } }
  .quickfind-detail-section-button-link {
    transition: 0.2s;
    color: #0F6FA8; }
    .quickfind-detail-section-button-link:hover, .quickfind-detail-section-button-link:active, .quickfind-detail-section-button-link:focus {
      color: #205791; }
  .quickfind-detail:hover, .quickfind-detail:active, .quickfind-detail:focus {
    color: #205791; }
  .quickfind-detail-three-col-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); }

.quickfind-scroll-container {
  height: 12.5rem;
  border: 1px solid #5f5c55;
  overflow: scroll;
  padding: 1rem; }
