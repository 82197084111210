﻿/// <reference path="../../../main">

// STRUCTURE
.cpg-search-alert-box {
    padding: 0.625rem;
    margin: 1.25rem 0;
    background: $offwhite;
    color: $accent-8;
    box-shadow: 0px 0.0625rem 0.125rem rgba(0,0,0,0.5);
    border-radius: 0.3125rem;

    p {
        margin: 0;
    }
}