/// <reference path="../../main.scss" />

/// Input types that are visually compatible with text style inputs
/// @name form styling
$input-types: ( 
"text", "number", "password", "tel", "email", "search", "url", "date", "month", "week", "time", "datetime", "datetime-local" );

%form-element {
    /*minimum size for form element font-size to prevent iOS resizing is 16px*/
    font-size: 1rem;
    &:focus {
        box-shadow: none;
    }

    /*
    line-height: 1.75;
    padding: $space $gap-sm;
    width: 100%;
    max-width: $max-input-width;
    border: 1px solid $neutral-2;
    border-radius: $border-radius;
    font-family: $body-font;

    &:invalid:not(:focus) {
    }

    &[disabled] {
        cursor: not-allowed;
    }*/
}

input {
    @each $type in $input-types {
        &[type=#{$type}] {
            @extend %form-element;
        }
    }
}

select, textarea {
    @extend %form-element;
    width: 10rem;
    color: $accent-1;
    padding: .25rem 0;
    background: $neutral-4;
}

textarea {
    height: 5rem;
}

.select {
}

label {
    @include font-size($reg-font-sizes);
    font-weight: $font-weight-bold;
    display: block;
    line-height: 2;
    &:not(.visually-hidden) ~ * {
        margin-top: $space;
    }
}

button, submit, input[type="submit"], input[type="button"] {
    @extend %btn-primary;
}