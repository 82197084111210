.cpg-small-cta-container {
    margin-top: 2rem;
    
    h2 {
        font-size: 1.3rem;
        font-family: $heading-font-bold;
    }
}

.cpg-small-cta {
    display: flex;

    &-right {
        flex: 1 1 50%;
        padding-left: 1rem;
    }

    a {
        font-size: 1.1rem;
        font-family: $body-font;

        &:not(:hover) {
            text-decoration: none;
        }
    }
}