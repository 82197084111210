﻿/// <reference path="../../main.scss" />

// FORM STRUCTURE
.#{$adage-prefix}form {
    &-row {
        margin-top: $gap-sm;
        @extend %simple-2-col-container;
        align-items: flex-end;
    }

    &-item {
        @extend %simple-2-col-item;
    }
}
