/// <reference path="../../../ecd-styles" />


.ecd-page {
    .form-item {
        label {
            margin-bottom: 0;            

            & + .MuiInputBase-root {
                margin-top: 0;
                max-width: 20rem;
            }
        }

        .MuiGrid-root {
            margin-top: 0;
        }

        .MuiGrid-item {    
            .MuiFormControlLabel-root{
                width: 100%;
            }                         

            .MuiCheckbox-root + .MuiFormControlLabel-label{
                width: 100%;
            }
            
            .Mui-focusVisible + .MuiFormControlLabel-label {
                color: $white;
                background-color: $accent-focused;                           
            }
        }        

        &.quickfind-autocomplete-container {
            max-width: 20rem;
        }

        .MuiInputLabel-asterisk {
            color: $accent-asterisk;
            margin-left: $space-sm;
        }
    }

    .form-group {
        .form-item {
            & + .form-item {
                margin-top: $gap-form;
            }
        }

        .form-item-item {
            margin-top: $gap-form;

            .quickfind-scroll-container {
                border: 1px solid $gray-border;
                .form-item{
                    margin-top: 0px;
                }
            }
        }
    }
}
