﻿/// <reference path="../../../ecd-styles">

.#{$ecd-prefix}footer {
    &-inner {
        @extend %width-constraint;
    }

    &-content {
        border-top: 1px solid $accent-hr;
        padding-top: $gap-form;
        & > div {
            margin-top: 0px;
        }
    }
}