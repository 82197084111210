/// <reference path="../../main">

.cpg-section-container {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid $neutral-3;
    padding: 3rem 0;

    &-last {
        /*border-bottom: 1px solid $neutral-5;*/
        margin-bottom: 3rem;

        &-blue {
            border-bottom: 2px solid $primary;
        }
    }

    .cpg-table {
        width: 100%;
    }
}

.cpg-section-side-content {
    flex-basis: 30%;
    padding-left: 2.15rem;
    padding-right: 1rem;

    &.no-background {
        > h2 {
            // @extend %btn-tertiary;
            padding:$space;
            margin: 0;
            background: none;
            text-align: left;
            color: $primary-text;
            margin: 0;
            @include font-size($medium-large-font-sizes);
            font-family: $body-font;
            line-height: 1.25rem;
            border-bottom: 4px solid #404040;
            padding-left: 0;
        }
    }

    > h2 {
        // @extend %btn-tertiary;
        padding: $space;
        margin: 0;
        background: $accent-1;
        text-align: center;
        color: $white;
        margin: 0;
        @include font-size($medium-large-font-sizes);
        font-family: $body-font;
    }

    .cpg-otp-block {
        margin-top: 2rem;
    }

    .adage-video-block {
        padding: 1.5rem 1.4rem 0;
    }

    .adage-video-block-caption {
        padding: 0 1.4rem;
    }

    .videoblock {
        margin: 20px;
    }
}

.cpg-section-main-content {
    flex-basis: 71%;
    order: 1;

    /*&.no-background {
        border-right: none !important;
        border-left: none !important;
    }*/

    .Left & {
        order: 0;
        padding-right: 2.5rem;

        &:not(.no-background) {
            border-right: 1px solid $neutral-3;
        }
    }

    .Right & {
        padding-left: 2.5rem;

        &:not(.no-background) {
            border-left: 1px solid $neutral-3;
        }
    }

    h3 {
        margin: 0;
    }

    .adage-block:first-child,
    .adage-block:nth-child(2) {
        .adage-wysiwyg-block {
            margin-top: 0;
        }
    }
}
// Themed
.cpg-theme-purple {
    .cpg-section-container {
        padding: 3rem 3.5rem 3rem;
        border-top: 0;
        border-bottom: 1px solid $neutral-3;

        h2 {
            &:not(.orange-arrow-list) {
                background: $white;
                border-top: 4px solid $accent-4;
                color: $accent-4;
                text-align: left;
                font-family: $heading-font-bold;
            }
        }

        .adage-wysiwyg-block {
            padding: 0 1rem;
        }
    }

    .cpg-section-main-content {
        border-right: 0;
    }

    .cpg-section-side-content {
        padding-left: 0;
    }
}
