﻿/// <reference path="../../../main">

// STRUCTURE
.cpg-search-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    &-arrows {
        display: flex;
        justify-content: center;

        &-item {
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                width: 1rem;
                height: 1rem;
                fill: $create-account-blue-7;
            }

            p {
                color: $create-account-blue-7;
            }

            &:hover {
                &:not(.cpg-search-pagination-arrows-disabled) {
                    p {
                        color: #4a9dcf;
                    }

                    svg {
                        fill: #4a9dcf;
                    }
                }
            }
        }

        &-disabled {
            cursor: default;

            p {
                color: $search-gray-3;
            }

            svg {
                fill: $search-gray-3;
            }
        }
    }

    &-buttons {
        display: flex;
        justify-content: center;
        margin: 0 .5rem;
        padding: 0 .5rem;
        border-left: 0.0625rem solid $neutral-2;
        border-right: 0.0625rem solid $neutral-2;
        color: $neutral-2;

        &-item {
            + .cpg-search-pagination-buttons-item {
                margin-left: .5rem;
                padding-left: .5rem;
                border-left: 0.0625rem solid $neutral-2;
            }


            &.selected, &.ellipsis {
                span {
                    color: $search-gray-3 !important;
                    cursor: default;
                }
            }

            span {
                color: $create-account-blue-7;
                cursor: pointer;

                &:hover {
                    color: #4a9dcf;
                }
            }
        }


        span {
        }
    }
}

.cpg-pagination-footer {
    border: 0.0625rem solid $search-gray-1;
    background: $search-gray-2;
    padding: 0 0.625rem;
    margin: 1.25rem 0;
    box-shadow: 0px 0.0625rem 0.125rem rgba(0,0,0,0.5);
    border-radius: 0.3125rem;
}