﻿/// <reference path="../../main">

body {
    display: flex;
    flex-direction: column;
    // added min-width to keep website from scaling down on small devices
    min-width: $max-width;
}

header {
    flex: 0 0 auto;
}

footer {
    flex: 10 0 auto;
}

main {
    flex: 1 0 auto;
    background: $white;
}

.skip-link {
    position: absolute;
    background: $white;
    padding: $space;
    z-index: 1000;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    &:focus {
        transform: none;
    }
}

li p {
    margin: 0;
}

main.top-border {
    border-top: 5px solid $primary-medium;
}

.indent-2 {
    margin-left: 7rem !important;
}