﻿/// <reference path="../../main">

@mixin focus() {
    outline: 2px solid transparent;
    outline-offset: 5px;
    transition: $transition all;

    &:focus {
        box-shadow: 0 0 0 2px $white;
        outline-offset: 2px;
        outline-color: $primary-medium;
    }
}

%focus {
   /* @include focus(); TODO create scss focus styles outside of react styles*/
}

a[href],
area[href],
input:not([disabled]),
select:not([disabled]),
textarea:not([disabled]),
button:not([disabled]),
iframe,
[tabindex],
[contentEditable=true] {
    &:not([tabindex='-1']) {
        //@include focus();
    }
}