﻿/// <reference path="../../ecd-styles">
/*@font-face {
    font-family: "Helvetica Neue LT W05_55 Roman";
    src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot");
    src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix") format("embedded-opentype"), url("../fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("../fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff"), url("../fonts/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue LT W05_65 Medium";
    src: url("../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot");
    src: url("../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("embedded-opentype"), url("../fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"), url("../fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff"), url("../fonts/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue LT W05_75 Bold";
    src: url("../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot");
    src: url("../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix") format("embedded-opentype"), url("../fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"), url("../fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff"), url("../fonts/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf") format("truetype");
}
*/
%sign-in-or-app-btn {
    margin: 0;
    background: $primary;
    font-family: $body-font;
    font-weight: $font-weight-normal;
    color: $white;
    padding: 3.5px 7px;
    font-size: 12.25px;
    line-height: 1.42857;
    text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
    box-shadow: rgb(10, 65, 129) 0px 2px 0px 0px;
    cursor: pointer;
    text-decoration: none;
    border-color: #1c7abf;
    border-bottom: 2px solid #18669f;
    border-radius: 3px;

    &:hover {
        background: $accent-1-dark;
        border-color: $accent-1-dark;
        box-shadow: 0 2px #A12902;
        color: $white;
    }

    &:after {
        content: none !important;
    }
}

%sign-in-or-app-btn-disabled {
    margin: 0;
    background: $search-gray-3;
    font-family: $body-font;
    font-weight: $font-weight-normal;
    color: $white;
    padding: 3.5px 7px;
    font-size: 12.25px;
    line-height: 1.42857;
    text-decoration: none;
    opacity: 1;

    &:after {
        content: none !important;
    }
}

.adage-header {
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style: none;
    font-family: $body-font;

    &-inner {
        width: remToPx($max-width);
        margin: 0 auto;
        display: flex;
        padding: remToPx(0.625rem);
        padding-bottom: 44px;

        &-lg {
            @extend %width-constraint;
            padding-bottom: 44px;
            padding-top: .625rem;
        }
    }
}


.adage-header-logo {
    width: 270px;
    margin-right: remToPx(0.625rem);

    &:focus {
        box-shadow: none;
    }

    &.mouse-click {
        &:focus {
            > img {
                box-shadow: none;
                outline: none;
            }
        }
    }

    img {
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}



// fixes to mega nav preview in epi mode
.epi-editmode {
    .cpg-mega-nav-list {
        flex-flow: row;
    }

    .nav-list-item-link {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
    }
}

/*ECD header */

.ecd-header {
    background-color: $primary-dark;
    & * {
        color: $white;
    }

    &-inner {
        @extend %width-constraint;
        padding-top: 8px; // Julio Pezua request
        padding-bottom: 8px; // Julio Pezua request
        display: flex;

        h2 {
            margin: 0;
            color: $white;
            font-family: $body-font;
            font-size: $font-size-h2;
        }
    }
}
