/// <reference path="../../main">

.cpg-create-account {
    padding-top: 4rem;
    align-self: stretch;
    
    &-wrapper {
        display: block;
        width: 55rem;
        
        &.wide {
            width: 70rem
        }
    }

    &-footer {
        margin: 1rem 1.75rem;
    }
}


.cpg-create-account-modal {
    padding-top: 0;
    margin-left: 0 !important;

    .cpg-create-account-main {
        padding-top: 0;
    }
}

.cpg-create-account-main {
    padding-top: 2.5rem;
    border: 1px solid $accent-3;
    border-radius: .5rem;
    position: relative;
    background: $white;

    h1 {
        @include font-size($medium-font-sizes);
        color: $accent-1;
        font-family: $heading-font-bold;
        font-weight: 600;
        margin: 1rem 0 .125rem 0;
        position: absolute;
        top: -2.8rem;
        left: 1rem;
        padding: 1rem;
        background: $white;
    }
}

.cpg-create-account-steps {
    padding-bottom: 1rem;

    ol {
        display: flex;
        justify-content: center;
    }
}

.cpg-step-display {
    &.current .cpg-step-display-number {
        width: 1.7rem;
        height: 1.7rem;
        line-height: .7;
        background-color: $create-account-blue-2;
        color: $white;
        border-width: 3px;
        border-color: $create-account-blue-1;
        padding-top: 0.25rem;
    }
}

.cpg-step-display-number {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    background-color: $create-account-blue-1;
    color: $create-account-blue-2;
    border: 1px solid $create-account-blue-3;
    border-radius: 100%;
    padding: .25rem;
    margin-right: .5rem;
    text-align: center;
    line-height: 1;
}
    
.cpg-step-display-text {
    color: $create-account-text;
    margin-right: 1.5rem;
}

.cpg-create-account-step-body {
    padding: 0;

    .adage-modal-dialog {
        max-width: 50rem;
    }
}

.cpg-step-info {
    padding: 0 .6rem;
    margin: .75rem 0;
    background: $create-account-blue-4;
    color: $create-account-blue-5;
    border: 1px solid $create-account-blue-6;
    border-radius: .3rem;

    p, a {
        display: inline-block;
        margin: .75rem 0;
    }

    &.green {
        background-color: $create-account-green-1;
        color: $create-account-text;
        border-color: $neutral-3;
    }

    &.red {
        border-color: $create-account-error-red-border;
        background-color: $create-account-error-red-background;
        color: $create-account-error-red-text;
    }

    &.yellow {
        background-color: $light-yellow;
        border-color: $light-yellow-border;
        color: $brown-text;
    }
}

.cpg-create-account-fields {
    padding: .75rem;
    background: linear-gradient($white, $white 60%, $light-yellow-border);
    border-top-left-radius:  .5rem;
    border-top-right-radius:  .5rem;
}

.cpg-create-account-section {
    &.email {
        .cpg-text-field {
            justify-content: flex-start;

        }

        .form-element-input {
            width: 18rem;
        }
    }
}

.cpg-select-fieldset,
.cpg-text-field {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    legend,
    label {
        flex-shrink: 1;
        position: relative;
        @include font-size($reg-font-sizes);
        font-family: $heading-font-medium;
        font-weight: $font-weight-normal;
    
        &::before {
            display: block;
            left: -.5rem;
            position: absolute;
            color: $accent-1-dark;
            content: "*"
        }
    }

    input {
        flex-shrink: 1;
        background-color: $white;
        border: 1px solid $neutral-5;
        padding: .35rem;
        @include font-size($reg-font-sizes);
    }

    a {
        @include font-size($small-font-sizes);
    }

    > * {
        margin: 0 .25rem;
    }

    &-wrapper {
        display: flex;
        align-items: baseline;

        .cpg-text-field {
            padding: 0;
            justify-content: flex-end;
        }

        .cpg-text-field-rules {
            background: $create-account-yellow-1;
            padding: .4rem 0;
            margin-left: .5rem;
            border: 1px solid $create-account-yellow-2;
            @include font-size($small-font-sizes);
            flex-grow: 1;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                transform: rotate(45deg);
                background: $create-account-yellow-1;
                width: .75rem;
                height: .75rem;
                top: .5rem;
                left: -.25rem;
            }
            
            p {
                margin: 0 .75rem;
            }
            
            ul, li {
                margin-left: .75rem;
                list-style: outside;
                width: 15rem;
            }
        }

        > .cpg-text-field,
        &-inner {
            flex: 0 0 50%;
        }

        &-inner {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &.wide {
                flex: 0 1 60%;
            }
        }
    }

    .form-element-error {
        display: table;
        position: absolute;
        left: 102%;
        top: .25rem;
        background: $dark-red;
        color: $neutral-3;
        opacity: 1;
        flex-direction: column;
        width: 20rem;
        z-index: 99;
        border: 1px solid $light-red;

        @include font-size($reg-font-sizes);

        &::before {
            display: block;
            content: " ";
            height: .75rem;
            width: .75rem;
            position: absolute;
            left: -.3rem;
            top: .3rem;
            background: $dark-red;
            transform: rotate(45deg);
        }

        &-text {
            display: block;

            &-wrapper {
                margin-left: .5rem;
                padding: .25rem;
                background: $dark-red;
            }
        }
    }

    .form-element-error-icon {
        height: 1rem;
        width: 1rem;
    }
}

.cpg-select-fieldset {
    padding-top: .5rem;

    select {
        color: $create-account-text;
        background: $white;

        &:last-child {
            width: 3rem;
        }
    }
}

.cpg-step-link-text {
    @extend %un-button;

    border-bottom: dashed;
    border-bottom-width: 1px;
    color: $create-account-blue-7;
}

.cpg-create-account-step-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;


    background: $light-yellow;
    padding: .75rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;

    box-shadow: inset 0px 5px 4px -3px rgba(50, 50, 50, 0.5)
}

.cpg-field-checkbox,
.cpg-show-password {
    display: flex;
    align-items: center;
    padding: 0 2rem;

    &.center {
        justify-content: center;
    }

    img {
        margin: .25rem;
    }

}

.cpg-securityq-fieldset {
    display: flex;
    align-items: center;
    justify-content: center;

    select {
        width: 20rem;
        @include font-size($small-font-sizes);
    }

    input {
        width: 20rem
    }
}