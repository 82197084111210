/// <reference path="../../main.scss" />


%body {
    font-size: 100%;
    font-family: $body-font, serif;
    color: $primary-text;
    @include font-size($medium-font-sizes);

    ::selection {
        color: $white;
    }

    ::-moz-selection {
        color: $white;
    }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
	@extend %body;
}

a {
    @include link-helper();
}

@include mouse-and-touch-input() {
    a {
        box-shadow: none !important;
        outline: 0 !important;
    }
}

b, strong {
    font-weight: $font-weight-bold;
    letter-spacing: .02em;
}

em, i {
	font-style: italic;
}

h1, h2, h3, h4, h5 {
}

h1 {
    @include font-size($x-large-font-sizes);
    color: $primary-text;
    font-family: $heading-font-medium;
    font-weight: 500;
}

h2 {
    @include font-size($large-font-sizes);
    color: $primary-text;
    font-family: $heading-font-medium;
    font-weight: 500;
    margin-bottom: 1rem;
}

h3 {
    @include font-size($medium-large-font-sizes);
    color: $accent-1;
    font-family: $heading-font-bold;
    margin: 1rem 0 .5rem 0;
    font-weight: 500;
    line-height: 1.5rem;
}

h4 {
    @include font-size($medium-large-font-sizes);
    color: $primary-text;
    font-family: $heading-font-medium-bold;
    font-weight: 500;
    margin: 1rem 0 .5rem 0;
    line-height: 1.5rem;

    &.variation {
        color: $accent-1 !important;
    }
}

h5 {
    @include font-size($reg-font-sizes);
    color: $neutral-1;
    font-family: $heading-font-bold;
    font-weight: $font-weight-bold;
    margin-bottom: 1.5rem;
    letter-spacing: .006em;
    line-height: 1.5rem;
}

h6 { // WILDCARD STYLES  -  NOT CURRENTLY DEFINED
    @include font-size($small-font-sizes);
    text-transform: uppercase;
    letter-spacing: .5px;
    color: $primary-text;
    font-family: $heading-font-medium;
    font-weight: 500;
}

blockquote {
}

sup, sub {
	font-size: .65em;
	margin-left: 1px;
}

sup {
	vertical-align: super;
}

sub {
	vertical-align: sub;
}

p {
    line-height: 1.5rem;
    margin: .875rem 0;
  
    letter-spacing: .008em;
}

h2 + h3, h3 + p, h4 + p {
    margin-top: 0;
}

hr {
    border: 0.5px solid #e9e9e9;
    height: 1px;
    margin-top: .5rem;
    margin-bottom: .5rem;

    h2 + &,
    h3 + & {
        border: .5px solid $neutral-3;
        height: 1px;
    }
}

hr.alternate-hr-gray {
    border-color: $neutral-3;
    height: 1px;
}

small {
    color: $neutral-2;
    font-size: .85rem;

    a:not(:hover) {
        text-decoration: none;
    }
}

/*li {
    a:not(:hover) {
        text-decoration: none;
    }
}*/
a:not(:hover) {
    text-decoration: none;
}
