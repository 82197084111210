.cpg-events-container {
    
    .cpg-event-group {
        background: $neutral-4;
        padding: 2rem;
        padding-top: 1rem;
        margin-bottom: 1rem;
    }

    .cpg-event {
        border-bottom: 1px solid $neutral-3;
        display: flex;

        &:last-child {
            border-bottom: 0;
        }


    }

    .cpg-event-heading {
        flex: 0 0 25%;
        padding-right: 1.5rem;

        h4 {
            @include font-size($medium-font-sizes);
        }
        
        h5 {
            @include font-size($small-font-sizes);
            color: $primary-text;
        }
    }
    
    .cpg-event-body {
        flex: 0 0 75%;
        padding-right: 4rem;

        h1,h2 {
            @include font-size($reg-font-sizes);
            font-weight: $font-weight-bold;
            margin-top: 1rem;
        }
        
    }
}