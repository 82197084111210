﻿/// <reference path="../../main">


%themes {
    &.#{$adage-prefix}theme {
        &-light, &-default {
            background: $neutral-3;
            color: $primary-dark;

            .btn {
                @extend %btn-primary;
            }
        }

        &-dark {
            background: $primary-dark;
            color: $primary-light;

            .btn {
                @extend %btn-secondary;
            }
        }
    }
}

%cpg-themes {
    .cpg-theme-purple & {
        .btn-primary {
            background: $accent-4;
        }
    }
}

