.cpg-course {
    display: flex;
    position: relative;
    background: $white;
    margin-bottom: $gap / 2;

    &.large {
        padding: $gap / 2;
        flex-flow: row nowrap;
        padding: 2rem;
        background: $neutral-4;
        max-width: 55rem;

        .btn {
            width: 10rem;
            padding: .75rem .5rem;
        }
    }

    &.small {
        display: inline-block;
        vertical-align: top;
        margin: 0;

        .btn {
            padding: .5rem;
            min-width: 7.5rem;
            border-radius: 0;
        }

        h4 {
            margin-top: 0;
            margin-bottom: 1rem;
            font-family: $body-font;
        }

        .adage-image-block {
            margin-bottom: 0 !important;
        }
    }

    &-left {
        flex: 1 0 25%;
        margin-right: 1.5rem;

        img {
            background: $white;
        }
    }

    &-right {
        flex: 1 1 75%;

        h3 {
            margin-bottom: 1.5rem;
            padding-bottom: .5rem;
            border-bottom: 1px solid $neutral-3;
            font-weight: $font-weight-normal;
        }

        p {
            margin-top: 0;
        }

        .cpg-course-description {
            h1, h2, h3 {
                @include font-size($medium-font-sizes);
                margin-bottom: 0;
            }
        }
    }

    &-main {
        .adage-image-block {
            margin-bottom: 1rem;

            img {
                width: 100%;
                height: auto;
                background: $white;
            }
        }

        h1, h2 {
            @include font-size($medium-large-font-sizes);
        }

        p {
            width: 20rem;
        }
    }

    &-links {
        display: flex;

        * {
            flex: 0 1 auto;
            margin-right: .5rem;
            margin-top: 0;
            text-align: center;

            &:last-child a {
                background: $neutral-4;
                color: $primary;
            }


            &:first-child a {
                background: $primary;
                color: $white;
            }
        }
    }

    &-tag {
        position: absolute;
        top: 0;
        left: 0;
        background: $neutral-2;
        padding: .75rem;
        color: $white;

        @include font-size($reg-font-sizes);
    }
}
/// Column specific
[class*="col"] {
    .cpg-course {

        width: 100%;

        &.small {
            .adage-block-inner .adage-video-thumbnail-container {
                width: 100%;
            }
        }
    }
}
