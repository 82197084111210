.cpg-nav-landing-wrapper {
}

.cpg-nav-landing-main {
    position: relative;
    margin: 0;
}


.cpg-nav-landing-links {
    margin-bottom: $gap;
    
    ul {
        display: flex;
        justify-content: space-evenly;
    }

    li {
        margin-right: .25rem;
        text-align: center;
        display: flex;
        flex: 1 1 auto;
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    a {
        width: 100%;
        color: $primary-text;
        background: $neutral-3;
        padding: 1.5rem;
        border-bottom: 3px solid $primary-medium;
        text-decoration: none;

        @include font-size($medium-font-sizes);

        &:hover {
            background: $neutral-4;
            border-color: $accent-1;
        }
    }
}