﻿/// <reference path="../../../main">

// STRUCTURE
.cpg-sidebar-search {
    min-width: 18.6875rem;
    padding-right: 2.5rem;
    flex: 0 0 18.6875rem;
}

.cpg-search-sidebar {
    padding: 0.625rem;
    margin: 1.25rem 0;
    border-radius: 0.3125rem;

}

.cpg-search-facets-list {
    margin-bottom: 1.25rem;

    &-title {
        margin-bottom: $gap-sm;
    }

    > strong {
        margin-bottom: 0.3125rem;
        display: block;
        font-size: 0.85rem;
    }

    &-item {
        display: flex;
        align-items: center;
       

        input[type="checkbox"] {
            margin-right: 0.625rem;
        }

        label {
            display: flex;
            align-items: center;
           

            .text {
            }

            svg {
                width: 1.125rem;
                height: 1.125rem;
            }
        }

        .option {
            display: flex;
            align-items: center;
            font-size: 0.85rem;
            margin-left: 0.3125rem;
            padding-bottom: 4px;
            cursor: pointer;
        }
    }

    .cpg-search-facets-list-footer {
        margin-top: 0.625rem;
       

        a {
            cursor: pointer;
            + a {
                margin-left: .5rem;
                padding-left: .5rem;
                border-left: 0.0625rem solid $neutral-2;
            }

            &:hover {
                color: #4a9dcf;
            }
        }
    }
}