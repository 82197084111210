﻿/// <reference path="../../main.scss">

/*
* Styles in this file are specific rules
* applied to classes that are imported with the markup
* from cpg.org's old website, to be used to achieve
* edge-case and one-off styles that our own blocks cannot account for.
*/

// Special case for floated images
img {
    &.right {
        float: right;
        padding-left: 0.5rem;
        padding-bottom: 1rem;
    }

    &.left {
        float: left;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
}

p.right {
    text-align: right;
}

a.right {
    float: right;
    margin-bottom: 1rem;
}

// Underlined headers
h3.underline {
    color: $neutral-1;
    border-bottom: solid 1px $neutral-5;
    margin: 0 0 1.25rem 0 !important;
    padding: 0 0 0.625rem 0;
}

h4.underline {
    border-bottom: solid 1px $neutral-5
}

// From CPG
.box {
    display: block;
    padding: 0.625rem;
    margin: 1.25rem 0;
    background: $box-blue;
}

.box-06 {
    padding: 0.625rem;
    border: 1px solid $box-border;
    background-color: $box-neutral;
}
.rich-text .forms-questions-button {
    margin-top: 0;
}
.forms-questions-button {
    margin: 0px;
    padding: .7rem;
    border-right: solid 1px #d8d8d8;
    display: inline-block;
    font-size: 1.2em;
    vertical-align: middle;
    max-width: 100%;
    text-align: center;

    a {
        text-decoration: none;
        
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &:last-child {
        border-right: 0;
    }
}

.pod-wrapper {
    &.last {
        text-align: right;
    }
}

.pod {
    .tiny-span-7 {
        a {
            @extend %btn-neutral;
            border-bottom: 0;
        }
    }
}

.bottom-nav,
.small-bottom-nav {
    border-top: 2px solid $primary-dark;
    padding-top: 1.45rem;

    > ul, li {
        list-style: none !important;
    }

    ul {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        margin-left: 0;
    }

    li {
        margin-left: 2rem;
        border-bottom: 2px solid transparent;
        
        &:not(:first-child) {
            &:focus,
            &:hover {
                border-color: $accent-1-dark;
            }
        }
    }

    h4 {
        margin: 0;
        color: $black;
        @include font-size($medium-font-sizes);
    }

    a {
        color: $primary-text;
        padding-bottom: .25rem;
        text-decoration: none;
        margin-bottom: 0;
        
        &:focus,
        &:hover {
            color: $neutral-2;
        }
    }
}

.bottom-nav {
    @include font-size($medium-large-font-sizes);
    padding-top: 1.75rem;

    h4 {
        @include font-size($medium-large-font-sizes);
    }
}


// .updated-Title and children classes are a group
// of inline styles used to recreate
// the header-image block pattern

.updated-Title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1rem;

    img {
        width: 3rem;
        height: 3rem;
        margin: .5rem;
    }

    .updated-pageTitle {
        @include font-size($large-font-sizes);
        color: $accent-1;
        font-family: $body-font;
    }
}

#second-info-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h3 {
        width: 100%;
        color: $primary-medium;
        @include font-size($large3-font-sizes);
        margin-bottom: 1.75rem;
    }

    .info-box-padding {
        width: 20.75rem;

        h2 {
            font-family: $body-font;
            @include font-size($large3-font-sizes);
            margin-top: 2.85rem;
        }

        p {
            @include font-size($medium-font-sizes);
        }
    }

    #financial-icon-box,
    #health-icon-box,
    #training-icon-box {
        height: 9.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #financial-icon-box {
        background: $accent-2;
    }

    #health-icon-box {
        background: $red-2;
    }

    #training-icon-box {
        background: $primary;
    }
}

#banner-message-box {
    text-align: center;
    padding-bottom: 3rem;
    border-bottom: 1px solid $neutral-5;
    margin-bottom: 1rem;

    h3 {
        @include font-size($large3-font-sizes);
        color: $primary-medium;
        font-family: $heading-font-bold;
    }
    
    p {
        
        @include font-size($medium-font-sizes);
        padding: 0 5rem;
    }
}

// for redirect pages
.orange-arrow-list {
    color: $accent-1;
    display: inline-block;
    vertical-align: top;
    margin: 0 1rem;
    @include font-size($x-large2-font-sizes);

    + p {
        display: inline-block;
        width: 90%;
    }
}

iframe[src="https://cw.na1.hgncloud.com/church_pension/"] {
    width: 100%;
    height: 48rem;

    .warningMessage {
        display: none;
    }
}

// cartoon pages
.svIndex {
    width: 32%;
    display: inline-block;

    .cartoons.clearfix {
        margin-bottom: 1rem;

        a:not(:hover) {
            text-decoration: none;
        }
    }
}

.header-div,
#header-div {
    height: 2.9rem;
    background-color: $neutral-4;
    margin-bottom: 1.45rem;
    border-top: solid 2px $primary-medium;

    h5,
    .sub-section-header,
    #sub-section-header {
        color: $primary-text;
        margin-bottom: .4rem;
        margin-left: 1.45rem;
        font-family: $body-font;
        font-weight: $font-weight-normal;
        @include font-size($large2-font-sizes);
        line-height: 2.9rem;
    }
}

ul.list-columns {
    list-style: none;
    height: 11rem;
    display: flex;
    flex-flow: column wrap;
    margin: 0;
    @include font-size($reg-font-sizes);
    padding-top: 1rem;

    li {
        list-style: none;
        height: 2.3rem;
        width: 22rem;
        margin-bottom: .75rem;
        margin-right: 3rem;
        padding: .5rem;
        text-align: center;
        background: $neutral-4;
    }
}

ul.cpg-top-navigation-list {
    min-width: 36.1875rem;
    list-style: none;
    padding: 1rem 0 0 0;
    margin: 0 0 1.5rem 0;
    border-top: 1px solid $neutral-3;

    li {
        display: inline-block;
        padding: 0 0.25rem 0px 0.25rem;
    }
}

h3 {
    > .neutral {
        font-size: 1.44rem;
        font-weight: 600;
        color: $neutral-1;
    }
}

h3.neutral {
    font-size: 1.44rem;
    font-weight: 600;
    color: $neutral-1;
}