%select-epi {
        color: $accent-1;
        font-weight: 700;
        border-radius: 5px;
        width: 14rem;
}

.cpg-contactform {
    &-title {
        border-bottom: 1px solid $neutral-2;
        margin-bottom: $gap;
        padding-bottom: $gap-sm;
    }

    &-inner {
        display: flex;
        border-bottom: 1px solid $neutral-2;
        margin-bottom: $gap;
        padding-bottom: $gap;


        .field-validation-error {
            color: $red;
        }
    }

    &-form, &-info {
        width: 50%;
    }

    &-info {
        padding-left: $gap;

        &-disclaimer {
            p {
                color: $neutral-2;
            }
        }

        input[type=submit] {
            margin-top: $gap;
        }
    }

    &-form {
        border-right: 1px solid $neutral-2;
        padding-right: $gap;

        & > div {
            margin-bottom: $gap-sm;
        }

        input, textarea {
            width: 100%;
            margin-top: 0;
            background-color: $neutral-4;
            border-width: 1px;
            border-color: $neutral-4;
            color: $primary-text;
            border-style: solid;
            padding: .5rem;
        }

        select {
            @extend %select-epi;
        }
    }

    &-contact {
        display: flex;
        padding-bottom: $gap;

        p {
            margin-top: 0;
            margin-bottom: 0;
        }

        & > div {
            padding-right: $gap;
        }
    }

    &-subscribe {
        width: 50%;

        & > div {
            margin-bottom: $gap-sm;
        }

        input[type=text] {
            width: 100%;
            padding: .5rem;
            margin-top: 0;
            background-color: $neutral-4;
            border-width: 1px;
            border-color: $neutral-4;
            color: $primary-text;
            border-style: solid;
        }

        select {
            @extend %select-epi;
        }
    }
}
