/// <reference path="../../main">

.cpg-read-more {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem;
    border-top: 1px solid $neutral-3;

    
    &.blue-border {
        border-top: 2px solid $primary;
    }

    h4 {
        font-weight: $font-weight-normal;
        font-family: $body-font;
        @include font-size($medium-large-font-sizes);
        margin-top: 0;
        margin-bottom: 0;
        color: $primary-text;
    }



    a {
        margin: 0;
        padding: 1rem;
        padding-right: 1.5rem;
        background: url('~/Static/img/chevron-right-solid-blue.svg') no-repeat right;
        background-size: .8rem;
        text-align: left;
        text-decoration: none;
        font-weight: $font-weight-normal;
        font-family: $body-font;
        @include font-size($medium-font-sizes);

        &:hover {
            text-decoration: underline;
        }
    }

    &-body {
        display: flex;
        align-items: flex-start;

        p {
            margin-bottom: 0;
            margin-top: .5rem;
        }

        &:first-child {
            flex: 0 1 75%;
        }

        h3 {
            margin-bottom: 0;
        }
    }

    .adage-image-block {
        margin: 0;
        height: 6.5rem;
        width: 6.5rem;

        img {
            height: 6.5rem;
            width: 6.5rem;
        }
    }
}

.cpg-theme-purple {
    .cpg-read-more {
        > a {
            color: $accent-4;
            background: url('~/Static/img/chevron-right-solid-purple.svg') no-repeat right;
            background-size: .8rem;
        }

        &.blue-border {
            border-top: 2px solid $accent-4;
        }
    }
}