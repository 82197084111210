$top: 3rem;

.react-gallery {
    display: flex;
    flex-flow: row wrap;
}

.react-gallery-img {
    flex: 1 1 20rem;
    margin: 1rem 0.5rem;
    cursor: pointer;
}

.cpg-slideshow {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    overflow: hidden;
}

.cpg-slideshow__close {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 0;
    cursor: pointer;
    pointer-events: none;
    color: $white;

    &::after {
        content: " ";
        display: block;
        position: absolute;
        top: $top;
        width: 2rem;
        height: 2rem;
        left: calc(50% + 26.5rem);
        background: $primary-medium url('~/Static/img/close.svg') center center no-repeat;
        background-size: 25px 25px;
        cursor: pointer;
        pointer-events: auto;
        z-index: 1;
    }
}

.cpg-slideshow__slide {
    display: block;
    position: absolute;
    top: $top;
    left: 50%;
    width: 800px;
    height: auto;
    margin: 0 0 24px -400px;
    background: #ffffff;
    z-index: -1;
}

.cpg-slideshow__media {
    display: block;
    position: relative;
    width: 100%;
    padding: 3rem 2rem;
    text-align: center;
    line-height: 0;
    background: #212121;

    img {
        top: 0;
        left: 0;
        min-height: 420px;
        height: 100%;
        width: auto;
    }
}

.cpg-slideshow__controls {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 300ms ease;

    &:hover {
        opacity: 1;
    }

    &--prev,
    &--next {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 10rem;
        background-size: 30px 30px, 50px 50px;
        font-size: 0;
        cursor: pointer;
        opacity: .75;
    }

    &--prev {
        left: 0;
        background-image: url('~/Static/img/chevron-left-solid.svg'), url('~/Static/img/circle-solid.svg');
        background-position: 3.8rem center, center center;
        background-repeat: no-repeat;
    }
    
    &--next {
        right: 0;
        background-image: url('~/Static/img/chevron-right-solid.svg'), url('~/Static/img/circle-solid.svg');
        background-position: 4rem center, center center;
        background-repeat: no-repeat;
        
    }
}