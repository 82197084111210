﻿.text-list {
    &-highlight {
        display: flex;

       .first-col {
            padding: 0 50px 50px 0;
            flex: 0 0 190px;

            h3 {
                color: $black;
                border-top: 2px solid rgb(10, 65, 129);
            }
        }

        .second-col {
            margin-left: 1.5rem;
        }
    }
}
