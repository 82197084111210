﻿/// <reference path="../../main.scss" />

.back-to-top {
    position: fixed;
    bottom: 5.5rem;
    right: 1.875rem;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 99;
    cursor: pointer;
    -webkit-border-radius: 1.25rem;
    -moz-border-radius: 1.25rem;
    border-radius: 1.25rem;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    opacity: 0;
    transform: scale(0);
    background-color: $primary;


    &.active {
        opacity: 1;
        transform: scale(1);
    }

    &:hover {
        background-color: $primary-medium
    }

    &:focus {
        background-color: $primary
    }

    svg {
        fill: $white;
        width: 1.5rem;
        height: 1.5rem;
        transform: translate(.5rem, .5rem);
    }
}
