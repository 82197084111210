.cpg-language-header {
    .cpg-theme-purple & {
        .cpg-language-header-content {
            a {
                background-image: none;
                
                &:hover, &:focus {
                    text-decoration: underline;
                }
                
                &:first-of-type {
                    @extend %btn;
                    background: $accent-4;
                    color: $white;
                    border-radius: 0;
                    border: 0;
                }
                
                &:last-of-type {
                    @extend %btn-neutral;
                    color: $accent-4;
                    border: 0;
                    border-radius: 0;
                    padding: 0.75rem 2rem;
                    
                    &:hover, &:focus {
                        color: $accent-4;
                    }
                }
            }
        }

        .cpg-language-header-links {
            border-left: 4px solid $accent-4;

            a {
                color: $primary-text;
                padding: .4rem;
    
                &:visited {
                    color: $accent-4;
                }
            }
        }
    }

    > img {
        height: 240px;
        width: 100%;
    }

    &-body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 0 3rem;
        border-bottom: 1px solid $neutral-5;
        
        h4 {
            font-weight: $font-weight-normal;
            font-family: $body-font;
            margin-bottom: 1rem;
        }
    }

    &-icon {
        width: 7.1rem;
        height: auto;
    }
    
    &-content {
        flex: 0 0 70%;
        padding: 0 1rem;
    }

    &-links {
        width: 12rem;
        height: 8rem;
        border-left: 4px solid $primary;
        padding: .85rem;
        padding-left: 1.7rem;

        li {
            margin: .25rem;
            padding: .5rem;
            &:first-child {
                background: $neutral-4;
            }
        }
    }
}