﻿/// <reference path="../../../../ecd-styles">

// STRUCTURE
.quickfind {
    &-results {
        &-table {
            margin-bottom: $gap;

            &-row {
                display: table;
                table-layout: fixed;
                width: 100%;
                max-width: 100%;

                &-cell {
                    display: table-cell;
                    padding: $space/2;
                }

                &-header {
                    background: $blue-light;
                }
            }

            &-link {
                @extend %un-button;
                @include link-helper($blue, $blue-text);
            }

            &-content {
                & > .quickfind-results-table-row {
                    &:nth-child(even) {
                        background: $gray-verylight;
                    }
                }
            }
        }
    }

    &-pagination {
        display: flex;
        justify-content: space-between;

        &-prev-next {
            @extend %btn-secondary;
            padding: $space/2 $space/2 !important;
            margin-left: $space/2;
            height: 1.5em !important;
            min-width: 0rem !important;
            border-color: transparent !important;
        }


        &-page {
            &-current {
                margin-left: $gap-sm;
            }

            &-link {
                @extend %un-button;
                @include link-helper($blue, $blue-text);
                margin-left: $gap-sm;
            }
        }
    }
}

.quickfind-parish {
    &-results {
        &-item {
            border: 1px solid $black;
            margin-bottom: $gap-results;
            padding: $gap-sm/4 $gap-sm;
            display: grid;
            grid-template-columns: auto 12fr 1fr 1fr;            

            &-actions{
                display: flex;
            }

            &-index {
                margin-right: $gap-sm;
            }            

            &-mapit{
                margin-left: $gap-sm;
                white-space: nowrap;
            }

            &-content {
                flex: 1 1 auto;
                line-height: normal;

                p {
                    line-height: normal;
                    margin: 0px;
                }
            }

            &:nth-child(even) {
                background: $gray-verylight;
            }

            &-link {
                @extend %un-button;
                @include link-helper($blue, $blue-text);
            }
        }

        &-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: $gap-sm;
        }

        &-formbuttons {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .quickfind-button-wrapper {
                margin-top: 0;
            }
        }

        &-sort {
            @include media-min(small) {
                width: 14rem;
            }

            &-desktop {
                @include media(small) {
                    display: none;
                }
            }

            &-mobile {
                @include media-min(small) {
                    display: none;
                }
            }
        }
    }
}

.quickfind-clergy {
    &-results {
        &-item {
            border: 1px solid $neutral-5;
            margin-bottom: $gap-results;            
            display: flex;
            justify-content: space-between;
            flex-direction: column;                        

            &-content{
                border-bottom: 1px solid $neutral-5;
                padding: 0.25rem 0.5rem 0.35rem;
                display: flex;
                justify-content: space-between;                                
            }

            &:nth-child(even) {
                background: $gray-verylight;
            }

            &-link {
                @extend %un-button;
                @include link-helper($blue, $blue-text);
            }

            &-label{
                text-align: start;
            }

            &-value{
                text-align: end;
            }
        }  
        
        &-title {
            padding: $space/2;
            font-family: $heading-font-medium-bold;
        }
        
        &-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: $gap-sm;               
        }

        &-formbuttons {
            display: flex;
            justify-content: flex-start;
            align-items: center;            
            .quickfind-button-wrapper {
                margin-top: 0;
            }         
        }
    }
}

.mail {
    &-formbuttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;            
        .quickfind-button-wrapper {
            margin-top: 0;
        }         
    }
}