.cpg-login-wrapper {
    width: 38rem;
}

.cpg-forgot-wrapper {
    width: 52rem;
}

.cpg-login,
.cpg-forgot {
    padding-top: 4rem;

    &--password {
        .cpg-forgot-fields {
            background: linear-gradient($white, $white 20%, $light-yellow-border);
        }
    }
}

.cpg-forgot-main,
.cpg-login-main {
    border: 1px solid $accent-3;
    border-radius: .5rem;
    position: relative;

    h1 {
        @include font-size($medium-font-sizes);
        color: $accent-1;
        font-family: $heading-font-bold;
        font-weight: 600;
        margin: 1rem 0 .125rem 0;
        position: absolute;
        top: -2.8rem;
        left: 1rem;
        padding: 1rem;
        background: $white;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.cpg-forgot-prompt,
.cpg-forgot-welcome,
.cpg-login-welcome,
.cpg-login-error {
    margin-top: .5rem;
    padding: .5rem .75rem;
    border-width: 1px;
    border-style: solid;
    border-radius: .5rem;
}

.cpg-forgot-prompt {
    background: $light-blue;
    color: $blue-text;
    border-color: $primary-light;
}

.cpg-login-error {
    background: $light-pink;
    color: $red-text;
    border-color: $light-pink-border;
}

.cpg-forgot-welcome,
.cpg-login-welcome {
    background: $light-yellow;
    border-color: $light-yellow-border;
    color: $brown-text;

    p {
        margin: .25rem;
    }
}

.cpg-forgot-fields,
.cpg-login-fields {
    padding: 2rem .75rem;
    background: linear-gradient($white, $white 60%, $light-yellow-border);
    border-top-left-radius:  .5rem;
    border-top-right-radius:  .5rem;

    &--password {
        padding-bottom: .75rem;
    }
}

.cpg-forgot-field,
.cpg-login-field {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .25rem 4rem .25rem 4rem;
    position: relative;
    
    label {
        color: $brown-text;
        position: relative;
        @include font-size($reg-font-sizes);
        font-family: $heading-font-medium;
        font-weight: $font-weight-normal;
    
        &::before {
            display: block;
            left: -.5rem;
            position: absolute;
            color: $accent-1-dark;
            content: "*"
        }
    }

    input {
        background-color: $white;
        border: 1px solid $neutral-3;
        padding: .35rem;
        @include font-size($reg-font-sizes);
    }

    a {
        @include font-size($small-font-sizes);
    }

    > * {
        margin: 0 .25rem;
    }

    &--short {
        input {
            width: 8rem;
        }

    }

    .field-validation-error {
        position: absolute;
        left: 22rem;
        top: .25rem;
        background: $dark-red;
        color: $neutral-3;
        padding: .15rem;
        padding-left: .6rem;
        opacity: 1;
        display: table;
        flex-direction: column;
        width: 18rem;
        @include font-size($small-font-sizes);
        z-index: 99;

        &::before {
            display: block;
            content: " ";
            height: .75rem;
            width: .75rem;
            position: absolute;
            left: -.3rem;
            top: .4rem;
            background: $dark-red;
            transform: rotate(45deg);
        }
    }
}

.cpg-forgot-field {
    justify-content: flex-start;

    label {
        flex: 0 0 25%;
        text-align: right;

        &::before {
            display: none;
        }
       
        span {
            color: $accent-1-dark;
            margin-right: .75rem;
        }
    }

    select {
        color: $primary-text;
        background: $white;

        &:nth-last-child(1) {
            width: 4rem;
        }
    }

    .field-validation-error {
        left: 26rem;
    }

    &--short .field-validation-error {
        left: 22.5rem;
        top: .4rem;
    }

    // IE11 targetted fix
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        .field-validation-error {
            left: 25rem;
        }
        
        &--short .field-validation-error {
            left: 20rem;
            top: .4rem;
        }
    }

    &--pw {
        label {
            flex: 1 0 auto;
        }

        .field-validation-error {
            left: 42rem;
        }
        
        &.recovery {
            .field-validation-error {
                left: 44rem;
                top: .75rem;
                margin: 0;
            }
        }
        
        // IE11 targetted fix
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            .field-validation-error {
                left: 42rem;
            }
        }
    }

    
}

.cpg-form-help {
    text-align: center;

    img {
        margin-top: -.5rem;
    }

    &--inline {
        color: $primary;
        text-decoration: dashed underline;
        cursor: default;
    }
}

.cpg-forgot-form-bottom,
.cpg-login-form-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: $light-yellow;
    padding: .75rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;

    box-shadow: inset 0px 5px 4px -3px rgba(50, 50, 50, 0.5);

    &.right {
        justify-content: flex-end;

        input:first-child {
            margin-right: 1rem;
        }
    }

    input {
        &:hover {
            cursor: pointer;
        }
    }
}

.cpg-forgot-form-bottom {
    justify-content: flex-end;
}

.cpg-forgot-bottom-text,
.cpg-login-bottom-text {
    padding: .6rem;
    margin-top: 1.3rem;

    &-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-left {
        flex: 0 1 60%;
        color: $neutral-2;
        position: relative;

        em {
            color: $accent-1-dark;
        }
    }

    .btn_create_account {
        cursor: pointer;
        padding: .5rem;
        background: linear-gradient($primary-light, $primary);
        text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
        font-weight: $font-weight-bold;
        box-shadow: 0px 1px 1px rgba(0,0,0,0.5);
        
        &:hover {
            background: linear-gradient($primary, $primary-light);
        }
    }

}

.ccis_help p {
    color: $neutral-2;

    em {
        color: $accent-1-dark;
    }
}

.cpg-recovery-field {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
    flex: 0 0 60%;

    .field-validation-error {
        position: absolute;
        left: 103%;
        top: .25rem;
        background: $dark-red;
        color: $neutral-3;
        padding: .4rem;
        padding-left: .6rem;
        opacity: 1;
        display: table;
        flex-direction: column;
        max-width: 20rem;
        z-index: 999;

        &::before {
            display: block;
            content: " ";
            height: .75rem;
            width: .75rem;
            position: absolute;
            left: -.3rem;
            top: .5rem;
            background: $dark-red;
            transform: rotate(45deg);
        }
    }

    label {
        flex-shrink: 1;
        position: relative;
        @include font-size($reg-font-sizes);
        font-family: $heading-font-medium;
        font-weight: $font-weight-normal;
        padding-right: 1rem;

        &::before {
            display: block;
            left: -.5rem;
            position: absolute;
            color: $accent-1-dark;
            content: "*"
        }
    }

    input {
        flex-shrink: 1;
        background-color: $white;
        border: 1px solid $neutral-5;
        padding: .35rem;
        @include font-size($reg-font-sizes);
    }

    &-wrapper {

        display: flex;
        align-items: baseline;

        .cpg-recovery-field {
            padding: 0;
            justify-content: flex-end;
        }

        .cpg-recovery-field-rules {
            background: $create-account-yellow-1;
            padding: .4rem 0;
            margin-left: .5rem;
            border: 1px solid $create-account-yellow-2;
            @include font-size($small-font-sizes);
            flex-grow: 1;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                transform: rotate(45deg);
                background: $create-account-yellow-1;
                width: .75rem;
                height: .75rem;
                top: .5rem;
                left: -.25rem;
            }
            
            p {
                margin: 0 .75rem;
            }
            
            ul, li {
                margin-left: .75rem;
                list-style: outside;
                width: 15rem;
            }
        }

        > .cpg-recovery-field {
            flex: 0 0 50%;
        }

        &-inner {
        }
    }
}

.cpg-recovery-field-wrapper-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-basis: 50%;
}

.adage-modal-wrapper {
    padding-top: 200px;
    align-items: inherit !important;

    .cpg-login-wrapper {
        padding: 1rem .5rem;
        background: $white;
    }

    .cpg-login {
        margin: 0;
    }
}


/* Below is my effort to make a fade out animation for the validation */

// .field-validation-error,
// .field-validation-error span {
//     animation: cssAnimation 0s ease-in 5s forwards;
//     -webkit-animation-fill-mode: forwards;
//     animation-fill-mode: forwards;
// }

// @keyframes cssAnimation {
//     to {
//         width:0;
//         height:0;
//         overflow:hidden;
//         opacity: 0;
//     }
// }

// @-webkit-keyframes cssAnimation {
//     to {
//         width:0;
//         height:0;
//         visibility:hidden;
//         opacity: 0;
//     }
// }