/// <reference path="../../main">

%cpg-table,
.cpg-table {
    width: 100%;

    ul {
        list-style: initial;
        margin-left: 25px;
    }

    &-header {
        margin-bottom: 1rem;
        @include font-size($medium-large-font-sizes);
    }


    thead {
        border-bottom: 1px solid $neutral-2;
        border-top: 1px solid $neutral-2;
        font-weight: $font-weight-bold;
        @include font-size($medium-font-sizes);
    }

    th {
        background: $neutral-4;
        vertical-align: bottom;
    }

    td, th {
        text-align: left;
        padding: 1rem;
        border-left: 1px solid $neutral-2;

        &:last-child {
            border-right: 1px solid $neutral-2;
        }

        &:not(:first-child):empty {
            border-left: none;
        }

        p {
            margin: 0;
        }
    }


    td {
        border-bottom: 1px solid $neutral-2;
        padding-right: .8rem;
    }

    tr {
        border-top: 1px solid $neutral-2;

        &.highlighted {
            color: $accent-1;
            font-weight: $font-weight-bold;
        }
    }

    &--borderless {

        th {
            background: $white;
        }

        thead {
            border-top: 0;
        }

        td, th {
            border-left: 0;

            &:last-child {
                border-right: 0;
            }
        }
    }
}
