.cpg-img-content {
    display: flex;

    &.Left {
        .cpg-imgtext-item-img {
            margin-right: 1rem;
        }
    }

    &.Right {
        flex-direction: row-reverse;

        .cpg-imgtext-item-img {
            margin-left: 1rem;
        }
    }

    &.smaller {
        width: unset;

        img {
            width: 75px;
        }
    }

    svg {
        position: absolute;
        width: $cpg-video-play-circle-size;
        height: $cpg-video-play-circle-size;
        top: calc(50% - #{$cpg-video-play-circle-size/2});
        left: calc(50% - #{$cpg-video-play-circle-size/2});
        z-index: 2;
    }
}

.cpg-imgtext-item {
    &.smaller {
        padding-top: .25rem;
    }
}
.row {
    & > .cpgimagecontentblock {
        width: 100%;

        .cpg-img-content {
            .content-wrapper {
                width: 100%;
            }
        }
    }
}