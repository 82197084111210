﻿/// <reference path="../../main">


// STRUCTURE
.#{$adage-prefix}fwcta {
    @extend %standard-padding-v;
    @extend %standard-padding-h;

    &-inner {
        max-width: $max-line-length;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &-content {
        margin-top: $gap-sm;
    }

    &-cta {
        margin-top: $gap;
    }

}

// THEMES
.#{$adage-prefix}fwcta {
    @extend %themes;
}