﻿/// <reference path="../../main">

$calendar-date-color: #c4972c;

.liturgical-calendar {

    &-menu {
        min-width: 11.875rem;
        padding-right: 1.25rem;
        margin-right: 0.625rem;
        min-height: 1rem;
    }

    &-outer {
        display: flex;
    }

    &-selection {
        border: 1px solid #d4d0c8;
        border-radius: 5px;
        padding: 0.625rem;
        margin: 2rem 0 1.428rem 0;

        .select-header {
            margin-top: -22px;
            background: white;
            width: 140px;
            margin-left: 20px;
            padding-left: 10px;
        }

        .month-text, .year-text {
            font-size: 0.875rem;
            margin-right: 0.625rem;
            margin-top: 0.25rem;

            span {
                color: #f90000;
            }
        }

        .year-text {
            margin-right: 1.25rem;
        }

        .select-period {
            display: flex;

            select {
                margin-top: 0.25rem;
                margin-bottom: 0.25rem;
                color: black;
                background: white;
                font-size: 0.875rem;
                border-radius: 2px;
            }
        }

        button {
            font-weight: $font-weight-bold;
            background: #f39000 url("/Static/img/bgr_button-default.gif");
            border-radius: 2px;
            border: 1px solid #ed3c11;
            padding: 4px 10px 4px 7px;
            box-shadow: 0px 1px 1px rgba(0,0,0,0.5);
            margin: 4px 0 6px 6px;
            
            &:hover {
                background: #e34100 url("/Static/img/bgr_button-hover.gif");
            }
        }
    }

    &-events {
        display: flex;
    }
}

%event-hover-styles {
    background-color: #fee095;

    .event-name {
        color: #7f611b;
    }
}

.liturgical-calendar-view {

    .event {

        &-list {
            width: 100%;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid #dedddb;
        }

        &-item {
            display: flex;

            &:hover {
                @extend %event-hover-styles;
            }

            &.active {
                @extend %event-hover-styles;
            }

            .date {
                flex-shrink: 0;
                width: 7rem;
                color: $calendar-date-color;
                text-align: right;
                margin-right: 1rem;
            }
        }
    }
}
