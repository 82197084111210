﻿/// <reference path="../../../ecd-styles">
/*@font-face {
    font-family: "Helvetica Neue LT W05_55 Roman";
    src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot");
    src: url("../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix") format("embedded-opentype"), url("../fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("../fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff"), url("../fonts/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue LT W05_65 Medium";
    src: url("../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot");
    src: url("../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("embedded-opentype"), url("../fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"), url("../fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff"), url("../fonts/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue LT W05_75 Bold";
    src: url("../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot");
    src: url("../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix") format("embedded-opentype"), url("../fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"), url("../fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff"), url("../fonts/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf") format("truetype");
}
*/



%nav-link {
    padding: $space;
    display: block;
    color: $neutral-1;
    text-decoration: none;

    &:visited {
        color: $neutral-1;
    }

    &:hover {
        color: $primary-medium;
        background: $gray-verylight;
    }

    &.active {
        color: $primary-medium;
        background: $neutral-4;
        font-family: $heading-font-medium-bold;
    }
}

.#{$ecd-prefix} {
    &nav {
        border-top: 2px solid $primary-medium;
        border-bottom: 2px solid $primary-medium;

        &-list-item {
            &-link {
                @extend %nav-link;
            }
        }
    }

    &mobile-nav {
        position: relative;

        &-header {
            background-color: $primary-medium;
            display: flex;
            justify-content: space-between;
            height: 3.75rem;
            &-inner {
                display: flex;
                align-items: center;
                padding-left: $gap-md;
            }
        }

        &-trigger {
            border-radius: 0px;
            border-bottom-color: transparent;
            font-family: $body-font;
            display: inline-flex;
            align-items: center;
            svg {
                fill: $white;
                height: 1.25rem;
                width: 1.25rem;
                & + span {
                    margin: $space;
                }
            }
        }

        &-panel {
            background: $neutral-3;
            position: absolute;
            width: 100%;
            z-index: 100;
            display: none;
            box-shadow: 1px 1px 2px 0 #9f98a6;

            @include media-min(tablet) {
                width: 20rem;
                position: absolute;
                right: 0;
            }

            &.nav-open {
                display: block;
            }
        }

        &-list-item {
            &-link {
                @extend %nav-link;
            }
        }
    }
}
