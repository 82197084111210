﻿/// <reference path="../../../main">

// STRUCTURE
.cpg-search-box {
    margin-bottom: $gap-md;

    &-top {
        display: flex;
        color: $gray-dark;
        align-items: center;
        border-bottom: 0.125rem groove $white;

        p {
            margin-top: 0;
            margin-bottom: 0;
        }

        > * {
            width: 50%;
            flex-grow: 1;
        }

        .page-size {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            > p {
                font-weight: $font-weight-bold;
                margin-right: $gap-sm;
            }

            select {
                color: $black;
                width: 4rem;
            }
        }
    }
}


.cpg-search-box .cpg-search-pagination, .cpg-pagination-footer .cpg-search-pagination {
    display: flex;
    margin-top: $gap-sm;
    justify-content: flex-start;
}
.cpg-search-result-content .cpg-pagination-footer {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    margin-bottom: 1.25rem;
    box-shadow: none;
    border-radius: initial;
}
.search-pagination-arrows {
    display: flex;
    gap: $space;

    p {
        margin: 0;
        padding: 0 $space/2;
    }

    svg {
        margin: 0;

    }

        .double-arrow {
            display: flex;

            svg {

                &:first-child {
                    margin-right: -7px;
                }
            }
        }

        &-item {
            display: flex;
            gap: 0.25rem;

            svg use {
                --chevron-color: $blue;
            }
        }
    }
.search-pagination-arrows-left svg {
    transform: rotate(180deg);
}

.cpg-search-pagination-arrows-disabled {
        opacity: 50%;

        span, p {
            color: $blue-light;
        }

        svg use {
            --chevron-color: $blue-light;
        }
}

    .cpg-search-pagination-buttons-item.selected {
        background: $gray-verylight;
        border-radius: 2px;
        border: solid $gray-light 1px;
        padding: $space-sm;
    }

    .cpg-search-result-content .cpg-search-pagination-buttons-item.selected span {
        color: $black !important;
    }

    .cpg-search-result-content .cpg-search-pagination-buttons {
        align-items: center;
        border: none;
    }

    .cpg-search-pagination-buttons-item + .cpg-search-pagination-buttons-item {
        border: none !important;
        /*margin-left: $gap-sm !important;*/
    }


    .cpg-search-pagination {
        font-family: $heading-font-medium, serif;
        font-weight: 700;
    }

.cpg-search-result .cpg-wrapper-large {
    max-width: $max-width;
}

.cpg-pagination-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .cpg-search-pagination {
        margin-top: 0;
    }

    .page-size {
        font-family: $heading-font-medium, serif;
        font-weight: 700;
        margin-top: $space;
        margin-left: $gap-sm;

        p {
            margin: 0;
        }

        select {
            color: $black;
            margin-top: $gap-sm;
            background: #fff;
            border-radius: 3px;
            width: 8rem;
            padding: 0.5rem;
        }
    }
}

