﻿/// <reference path="../../../../ecd-styles">

// STRUCTURE
.quickfind-autocomplete {
    &-container {
        position: relative;
    }

    &-list {
        position: absolute;
        z-index: 10;
        width: 100%;
        top: 100%;
        background: $neutral-4;        
    }

    &-link {
        @extend %un-button;
        display: block;
        width: 100%;
        padding: $space;
        color: $blue;

        &-detail {            
            display: block;
            font-weight: $font-weight-normal;
            color: $neutral-1;
        }

        &:hover {
            background: $gray-light;
        }
    }

    &-divider {
        border-top: 1px solid $accent-hr;
    }
}