﻿/// <reference path="../../main">

$elements-to-space: (
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'ul', 'ol'
    );

%responsive-top-margin {
    @include responsive-property('margin-top', $text-spacing-map);
}

.rich-text {
    @each $element1 in $elements-to-space {
        @each $element2 in $elements-to-space {
            > #{$element1} + #{$element2} {
                @extend %responsive-top-margin;
            }
        }
    }


    ul, ol {
        margin-left: $gap;
    }

    ul {
        list-style: initial;

        li {
            margin-bottom: $space-sm;
        }
    }

    ol {
        list-style: decimal;
    }

    a {
        &.no-icon {
            background-image: none;
            padding-right: 0;
        }
    }

    .links-container {
        a {
            @extend %btn-neutral;
            border-bottom: 0 !important;
        }
    }
}