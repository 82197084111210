.forms-publications-block {
    .svg-icon {
        width: 2*$ui-icon-size;
        height: 2*$ui-icon-size;
        margin-left: $ui-icon-size/2;

        &-inline {
            display: inline;
            vertical-align: middle;

            ~ p {
                display: inline;
            }
        }
    }

    .column {
        &:last-child,
        &:nth-last-child(2) {
            text-align: right;

            &:first-child {
                text-align: left;
            }
        }

        /*.right {
            text-align: right;
        }
        .left{
            text-align: left;
        }*/

        &:last-child {
            .svg-icon {
                height: 1.5*$ui-icon-size;
                width: 1.5*$ui-icon-size;
                vertical-align: sub;
                margin-left: 0;
            }
        }

        .cpg-mail-link {
            @extend %btn-primary;
            padding: .25rem .5rem;
        }
    }
}

.forms-publications-tab {
    &-trigger {
        &.highlight {
            @include font-size($medium-large-font-sizes);
            color: $primary-text;
            font-family: $heading-font-medium;
            font-weight: 700;
            background: $neutral-2;
        }
    }
}

.forms-publications-page {
    .tabbed-page-tab {
        width: 10rem;
    }
}