﻿/// <reference path="../../main.scss" />

/// parameters: size, line-height, letter-spacing (optional)
$x-large-font-sizes: ( //h1
    null : (2.125rem, (2.375/2.125)),
);

$x-large2-font-sizes: ( //h1
    null : (2.5rem, (3/2.5)),
);

$large-font-sizes: ( //h2
null : (1.75rem, (2.125/1.75)), );

$large2-font-sizes: ( //h2/h3
    null : (1.4rem, (2.4/2)),
);

$large3-font-sizes: ( //h2/h3
    null : (1.75rem, (2.4/2)),
);


$medium-large-font-sizes: ( //h4
    null : (1.125rem, (1.25/1.125)), 
);

/*$medium-large-font-sizes: ( //h3
    null : (1.2rem, (1.65/1.125)),
);*/

$medium-font-sizes: ( //body
     null : (.875rem, (1.375/.875)), 
);

$reg-font-sizes: (  //h5 + body
    null : (.875rem), 
);

$small-font-sizes: ( //h6
null : (.625rem, (.9375/.625)), );


@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
    @each $fs-breakpoint, $fs-font-size in $fs-map {
        @if $fs-breakpoint == null {
            @include _make-font-size($fs-font-size);
        }
        @else {
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }

            @media screen and (max-width: $fs-breakpoint) {
                @include _make-font-size($fs-font-size);
            }
        }
    }
}

@mixin _make-font-size($fs-font-size) {
    @if type-of($fs-font-size) == "list" {
        font-size: nth($fs-font-size, 1);

        @if (length($fs-font-size) > 1) {
            line-height: nth($fs-font-size, 2);
        }

        @if (length($fs-font-size) > 2) {
            letter-spacing: nth($fs-font-size, 3);
        }
    }
    @else {
        font-size: $fs-font-size;
    }
}