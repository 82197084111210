/// <reference path="../../main">

.cpg-react-accordion-item {
    &.open {
        border-bottom: 1px solid $neutral-3;
        padding-bottom: 1rem;

        &:last-child {
            border-bottom: 0;
        }
    }

    .svg-icon {
        width: 1rem;
        height: 1rem;
    }

    .cpg-text-field-wrapper {
        padding-bottom: 2rem;
    }

    .cpg-text-field {
        align-items: baseline;
    }
}

.cpg-react-accordion-trigger {
    color: $primary-medium;
    padding-left: 2rem;
    @include font-size($medium-large-font-sizes);
    font-family: $heading-font-medium;
    font-weight: $font-weight-normal;
    position: relative;
    
    &::before {
        content: '▸';
        height: 1rem;
        width: 1rem;
        position: absolute;
        left: 0;
        color: $primary;
    }
    
    .open & {
        color: #6C4F19;
        border-bottom: 1px solid #FCB13A;
        
        &::before {
            content: '▾';
            height: 1rem;
            width: 1rem;
            position: absolute;
            left: 0;
            color: $accent-1;
        }
    }
}

.cpg-verification-help-steps {
    ol {
        list-style: inside decimal;
        padding: 3rem 1rem 1rem;
    }
}