.cpg-profile-settings {
    padding-top: 4rem;
    align-self: stretch;
    
    &-wrapper {
        display: block;
        width: 55rem;
        
        &.wide {
            width: 70rem
        }
    }

    &-footer {
        margin: 1rem 1.75rem;
    }
}

.cpg-profile-settings-main {
    padding-top: 1rem;
    border: 1px solid $accent-3;
    border-radius: .5rem .5rem 0 0;
    position: relative;
    background: $white;

    h1 {
        @include font-size($medium-font-sizes);
        color: $accent-1;
        font-family: $heading-font-bold;
        font-weight: 600;
        margin: 1rem 0 .125rem 0;
        position: absolute;
        top: -2.8rem;
        left: 1rem;
        padding: 1rem;
        background: $white;
    }
}

.cpg-profile-settings-fields {
    padding: .75rem;
    background: linear-gradient($white, $white 60%, $light-yellow-border);
    border-top-left-radius:  .5rem;
    border-top-right-radius:  .5rem;

    .cpg-react-accordion-item.open {
        border-color: $golden-glow;
    }

    .cpg-react-accordion-trigger {
        @include font-size($medium-font-sizes);
    }
}

.cpg-profile-settings-section {
    display: flex;
    padding-top: 2rem;
    padding-left: 2rem;

    h5 {
        color: #BC8917;
        @include font-size($medium-font-sizes);
    }


    .cpg-text-field-wrapper {
        width: 44rem;
    }

    label {
        font-weight: $font-weight-bold;
        @include font-size($medium-font-sizes);
    }


    .cpg-text-field-rules {
        @include font-size($reg-font-sizes);
        border-radius: 3px;

        ul {
            margin-left: 1rem;
        }
    }

    &.password {
        .cpg-text-field-wrapper {
            width: 47rem;
        }
    }

    &.security-questions {
        display: block;

        legend {
            @include font-size($medium-font-sizes);
        }
    }

    .contact-email {
        .form-element-error {
            left: 77%;
            width: 12rem;
        }

        .form-element-input {
            width: 14rem;
        }
    }
}

.cpg-profile-settings-contact-info {
    flex: 0 1 50%;
    padding: 0 2rem;
    margin-bottom: 2rem;

    &:first-child {
        padding-left: 0;
        border-right: 1px solid $golden-glow;
    }

    .cpg-text-field {
        align-items: flex-end;

        label {
            align-self: center;
            font-weight: $font-weight-bold;
            @include font-size($medium-font-sizes);
        }

        label::before {
            display: none;
        }

        &.small input {
            width: 2.25rem;
        }

        &.medium input {
            width: 4rem;
        }
    }
}

.cpg-contact-info-heading {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $golden-glow;
    margin-bottom: 1rem;
    @include font-size($medium-font-sizes);
    
    h5 {
        margin-bottom: 0;
        margin-right: 1rem; 
    }
    
    label {
        @include font-size($reg-font-sizes);
    }
}

.cpg-general-info {
    display: flex;
    align-items: baseline;
    padding-bottom: 1rem;

    &-wrapper {
        display: flex;
        flex-direction: column;
    }
    
    span {
        @include font-size($medium-font-sizes);
        &:first-child {
            font-weight: $font-weight-bold;
            text-align: right;
            padding-right: 1rem;
            flex: 0 0 20%;
        }
        
        &:last-child {
            flex: 0 0 auto;
        }
    }
}

.cpg-contact-info--phone {
    display: flex;
    position: relative;
    
    .form-element-error {
        display: table;
        position: absolute;
        left: 87%;
        top: .6rem;
        background: $dark-red;
        color: $neutral-3;
        opacity: 1;
        flex-direction: column;
        width: 9rem;
        z-index: 99;
        border: 1px solid $light-red;

        @include font-size($small-font-sizes);

        &::before {
            display: block;
            content: " ";
            height: .75rem;
            width: .75rem;
            position: absolute;
            left: -.3rem;
            top: .3rem;
            background: $dark-red;
            transform: rotate(45deg);
        }

        &-text {
            display: block;

            &-wrapper {
                margin-left: .5rem;
                padding: .25rem;
                background: $dark-red;
            }
        }

        .form-element-error-icon {
            height: 1rem;
            width: 1rem;
        }

        &.wide {
            left: 93%;
        }
    }
}

.cpg-profile-settings-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;


    background: $light-yellow;
    padding: .75rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;

    box-shadow: inset 0px 5px 4px -3px rgba(50, 50, 50, 0.5);
}
