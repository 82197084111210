﻿/// <reference path="../../../../ecd-styles">

// STRUCTURE
.quickfind {
    &-advanced-cta {
        margin-top: $gap-sm;
        @extend %un-button;
        @include link-helper($blue, $blue-text);
    }
    &-scroll-container {
        height: 12.5rem;
        border: 1px solid $gray-dark;
        overflow: scroll;
        padding: $gap-sm;
    }

    &-button-wrapper {
        margin-top: $gap;
    }

    &-parish {
        &-section {
            &-columns {        
                grid-template-columns: 1fr 2fr;
                column-gap: $gap;
                @include media-min(medium) {
                    display: grid;
                }        

                & > div{
                    margin-top: $gap-form;
                }
            }
        }
    }
}

.form-item-item{
    .quickfind {
        &-scroll-container {
            padding: $gap-sm/4 $gap-sm;
            overflow-x: auto;
            max-width: 20rem;
            @include media-min(medium) {
                max-width: $gap-md+40rem;
            }  
        }        
    }
}
