﻿/// <reference path="../../main">

// STRUCTURE
main > .#{$adage-prefix}block > .#{$adage-prefix}image-block {
    //only apply max width if directly inside main
    max-width: $max-width;
}

.#{$adage-prefix}image-block {
    margin-top: 2rem;

    & > img {
        max-width: 100%;
    }

    color: $neutral-2;
    font-size: .85rem;
}