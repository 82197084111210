/// <reference path="../../main">

.cpg-quote {
    border-left: 4px solid $accent-1;
    padding: 2.15rem 3.55rem;
    background: $neutral-4;
    display: flex;
    margin: 1rem 0;
    position: relative;

    &:before {
        content: "“";
        display: block;
        position: absolute;
        top: .75rem;
        left: 1rem;

        color: $accent-1;
        font-size: 3.25rem;
        font-style: italic;
    }

    &-content {
        font-style: italic;
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:first-child {
            margin-left: 0;
        }

    }

    blockquote {
        margin-bottom: 1rem;
    }

    blockquote p {

        &::before,
        &::after {
            display: inline-block;
            vertical-align: bottom;
            position: relative;
            content: "\""; 
        }
    }

    &-image {
        width: 7.75rem;
        height: auto;
        margin-bottom: .5rem;
    }

    p {
        margin-top: 0;
    }

    cite {
        align-self: flex-end; 
    }
}