﻿.mail-forms-publications-page {
    padding: 1rem;
    width: $max-width;
    margin: 0 auto;

    > ul {
        list-style: disc;
        padding-left: 1rem;
    }

    > h3 {
        margin-bottom: 2rem;
    }
}
