﻿/// <reference path="../../main">
$left-column-max-width: pxToRem(530px);
$right-column-max-width: pxToRem(365px);
$two-column-gutter: pxToRem(50px);

// STRUCTURE
.cpg-wrapper-two-column {
    display: flex;
    align-items: flex-start;
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 $wrapper-column-gutter $wrapper-column-gutter 0;

    > * {
        border-top: 1px solid $neutral-3;
        flex-grow: 1;
    }

    &-left {
        flex-basis: $left-column-max-width;
        flex-grow: 1;
        padding: $two-column-gutter $two-column-gutter $two-column-gutter 0;

        .row {
            margin: 1rem 0;
            padding-bottom: .5rem;

            &:not(:nth-child(2)) {
                border-bottom: 1px solid $neutral-3;
            }
        }

        .adage-image-block {
            display: flex;
            justify-content: center;
        }

        .adage-wysiwyg-block:only-child {
            margin-top: 0;
        }

        .row0 {
            margin: 0;
        }
    }

    &-right {
        flex-basis: $right-column-max-width;
        border-left: 1px solid $neutral-3;
        padding: $two-column-gutter 0 $two-column-gutter $two-column-gutter;

        figure {
            margin-bottom: 2rem;
        }

        .row1 {
            border-bottom: 1px solid $neutral-3;
        }
    }

    h1 {
        color: $accent-1;
        @include font-size($large-font-sizes);
        margin-bottom: 2rem;
        font-family: $heading-font-medium;
        max-width: 19rem;
    }

    h5 {
        @include font-size($medium-large-font-sizes);
    }

    .left-col-child {
        h5 {
            margin-bottom: 0;

            + p {
                margin-bottom: 2rem;
            }
        }
    }

    .new-button {
        @extend %btn-secondary;
        text-decoration: none;
        padding: .24rem 2rem .27rem 2rem;
        border-radius: 3px;
        letter-spacing: .03rem;
        font-family: "Helvetica Neue LT W05_65 Medium", "Helvetica", Arial, sans-serif;
        font-size: .84rem;
    }

    p {
        @include font-size($medium-font-sizes);
    }

    .cpg-sidebar-link-group-title {
        color: $primary-text;
    }
}
