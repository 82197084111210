//Overriding EpiSevrer forms styles

.EPiServerForms {

    .Form__MainBody {
        max-width: 31.25rem;
    }

    .Form__Title {
        @include font-size($large-font-sizes);
        font-weight: $font-weight-bold;
        margin-bottom: 2rem;
    }

    .Form__Description {
        color: $accent-1;
        margin-bottom: 1rem;
        padding-bottom: .5rem;
        border-bottom: 2px solid $neutral-3;
    }

    .Form__Element {
        select,
        label.Form__Element__Caption,
        input.FormTextbox__Input {
            display: block;
        }

        label.Form__Element__Caption {
            @include font-size($medium-font-sizes);
        }

        textarea.FormTextbox__Input,
        input.FormTextbox__Input {
            width: 100%;
            padding: .5rem;
            background: $neutral-3;
            border: 0;
            border-radius: 2px;

            &:focus,
            &:active {
                /*background: $white;*/
            }
        }

        textarea.FormTextbox__Input {
            height: 8rem;
        }

        &.FormTextbox {
            margin-bottom: 1rem;
        }

        &.FormSubmitButton {
            @extend %btn-primary;
            cursor: pointer;
            vertical-align: top;
        }

        &.FormResetButton {
            @extend %btn-neutral;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        .FormCaptcha__Refresh {
            @extend %btn-primary;
            display: block;
        }

        &.ValidationRequired {
            .Form__Element__Caption {
                position: relative;
                display: inline-block;
                min-width: 0;

                &::after {
                    display: block;
                    position: absolute;
                    right: -8px;
                    top: -2px;
                    content: '*';
                    color: $accent-1;
                }
            }
        }

        select {
            color: $accent-1;
            padding: .25rem;
            font-weight: $font-weight-bold;
            border-radius: 5px;
            width: 14rem;

            option {
                color: $primary-text;
            }
        }
    }

    .Form__Status .Form__Status__Message.hide {
        display: none;
    }

    .Form__Success__Message {
        background-color: $neutral-4 !important ;
    }
}