﻿/// <reference path="../../main">

$max-imgtext-img-width: 11.875rem;

// STRUCTURE && STYLE
.#{$prefix}imgtext-item {
    display: flex;
    padding-top: 1.25rem;

    &.border {
        border-bottom: 1px solid $neutral-3;
        padding-bottom: 2rem;
    }

    h3 {
        margin-top: 0;
    }

    &-img {
        &.larger {
            width: 240px !important;
            height: 240px !important;
        }

        &.extra-large {
            width: 320px !important;
            height: 320px !important;
            margin-right: 40px;
        }

        &:not(.smaller) {
            width: $max-imgtext-img-width;
            flex-shrink: 0;
        }

        .#{$prefix}block {
            margin: 0;
        }

        .#{$prefix}block-inner {
            padding: 0;
        }
    }

    &-img-Left + &-content {
        padding-left: $gap-sm;
        padding-right: 0;
    }

    &-content {
        flex: 1;
        padding-right: $gap-sm;

        p:first-child {
            margin-top: 0;
        }
    }

    h5 {
        color: $primary-text;
    }

    .adage-image-block {
        margin: 0;
    }

    .tabbed-block & {
        border: 0;

        
    }

    &.smaller {
        .rich-text {


            a:nth-child(2) {
                &:not(.no-btn) {
                    @extend %btn-neutral;
                    padding: .35rem 2rem .25rem;
                    margin-left: 0.6rem;
                }
            }
        }

        img {
            max-width: 90px;
            max-height: 90px;
        }
    }
}

.tabbed-block .imagetextitemblock {
    background: $white;
}
