﻿/// <reference path="../../main">

// STRUCTURE
.#{$prefix}sidebar-link-group { // container block
	width: 17rem;
	margin-top: 2rem;

}

.#{$prefix}sidebar-link { // for each group item
	&-group {

		&-title {
			padding-bottom: $space;
			margin-bottom: 0;
		}

		&-item {

			& + & {
				margin-top: $gap-sm;
			}
		}
	}
}


// BASIC STYLES
.#{$prefix}sidebar-link-block { // container block

	&-title {
		color: $primary-text;
		font-weight: $font-weight-normal;
	}
}

.#{$prefix}sidebar-link { // for each group item

	&-group {
		&-title {
			color: $primary-text;
			font-weight: $font-weight-normal;
		}

		&-item {

			& + & {
				margin-top: $gap-sm;
			}
		}
	}

	&-list {
		
	}

	&-item {
		border-bottom: 1px solid $neutral-3;
		
		&:first-child {
			border-top: 1px solid $neutral-3;
		}
		
		& > a {
			display: flex;
			padding: .5rem .75rem .5rem 0;
			color: $primary-text;
			@include font-size($medium-font-sizes);
			text-decoration: none;

			background-image: url('~/Static/img/chevron-right-solid-orange.svg');
			background-repeat: no-repeat;
			background-position: right;
			background-size: .55rem;
		}
	}
}

.hidden {
    display: none !important;
}