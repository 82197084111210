﻿/// <reference path="../../../ecd-styles">

body {
    display: flex;
    flex-direction: column;
    // added min-width to keep website from scaling down on small devices
    min-width: 0;
    
    ::selection {
        background: $primary-medium;        
    }
}

header {
    flex: 0 0 auto;
}

footer {
    flex: 10 0 auto;
}

main {
    flex: 1 0 auto;
    background: $white;
}

.skip-link {
    position: absolute;
    background: $white;
    padding: $space;
    z-index: 1000;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    &:focus {
        transform: none;
    }
}

li p {
    margin: 0;
}

main.top-border {
    border-top: 5px solid $primary-medium;
}

.indent-2 {
    margin-left: 7rem !important;
}

.#{$ecd-prefix}page {
    display: flex;
    @extend %width-constraint;
    @extend %tiny-padding-v;
    align-items: flex-start;

    &-sidebar {
        width: 25%;
        padding-right: $gap-md;
    }

    &-content {
        width: 100%;

        &-section {
            border-top: 1px solid $accent-hr;
            padding: $gap-sm 0 $gap 0;
        }

        &-subtitle {
            &-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: $gap-sm;
            }

            &-map {
                display: flex;
            }

            &-icon {
                width: 0.85rem;
                height: 1rem;                
                background: url(/static/img/mapit-drop.png) no-repeat left top/contain;
            }
        }
    }
}