/// <reference path="../../../ecd-styles" />

@mixin focus($border-color) {
    outline: 1px solid transparent;
    outline-offset: 5px;
    transition: $transition all;
    &:focus {
        box-shadow: 0 0 0 2px $white;
        outline-offset: 1px;
        outline-color: $border-color;
    }
}

%ecd-btn {
    display: inline-block;
    min-height: 40px;
    min-width: 120px;
    padding: 0.25rem 16px;
    text-decoration: none;
    border-radius: 3px;
    border: 1px solid transparent;
    font-family: $body-font;
    @include font-size($reg-font-sizes);
    color: $white;

    &[disabled] {
        cursor: not-allowed;
        opacity: .5;
    }    

    &:focus {
        opacity: 1;
    }
}

.ecd-page {
    .btn-primary {
        @extend %ecd-btn;
        background: $primary-btn-color;

        &:hover, &:focus {
            background: $primary-btn-color-hover;
        }

        @include focus($primary-btn-color-border);        
    }

    .btn-secondary, %btn-secondary {
        @extend %ecd-btn;
        background: $white;
        color: $secondary-btn-color;
        border: 1px solid $secondary-btn-color-hover;                    

        &:hover {
            color: $secondary-btn-color-hover;
            box-shadow: 0px 2px 2.5px $secondary-btn-shadow-hover;
        }

        &:hover, &[disabled] {
            border-color: transparent;
        }

        @include focus($secondary-btn-color-border);        
    }
}
