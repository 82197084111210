.cpg-email-signup {

    box-shadow: 0px 8px 15px 0 rgba(0, 0, 0, 0.05), 0px 6px 20px 0 rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    
    .cpg-form-header {
        background: $accent-6;
        text-align: center;
        padding: 2rem;
        border-radius: 10px 10px 0 0;
        
        .cpg-form-title--email-signup {
            @include font-size($medium-large-font-sizes);
            font-weight: $font-weight-bold;
            color: $white;

            margin: 0;
        }
    }

    .cpg-form-body {
        padding: 1rem;
        display: flex;
        flex-direction: column;

        .cpg-form-field {
            height: 3rem;
            width: 100%;
            background: $neutral-3;
            margin: .5rem 1rem;
            border: 0;
            border-radius: 5px;
            padding: 1rem;
        }

        .cpg-form-row {
            display: flex;
        }
    }

    .cpg-form-footer {
        margin: 2rem;
        padding: 1rem 0;
        border-top: 2px solid $neutral-3;

        button {
            padding: 1rem 2rem;
            -webkit-appearance: none;
            border-width: 1px;
            float: none;
        }
    }
}