.cpg-error {
    .adage-block-inner {
        padding: 0;
    }
    
    .cpg-error-reasons {
        border: 10px solid $light-blue;
        border-radius: .5rem;
        padding: .5rem;
        margin: 0;

        p {
            margin-top: 0;
        }
    }
}