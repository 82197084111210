﻿/// <reference path="../../main">

$two-col-breakpoint: "medium" !global; // this breakpoint must exist as a value in the gutter-map variable to work
$one-col-breakpoint: "small" !global; // this breakpoint must exist as a value in the gutter-map variable to work

// STRUCTURE
.#{$adage-prefix}columns-auto {
    &-container {
        margin-top: $gap-sm;
        margin-bottom: $gap;

        @include media-min($one-col-breakpoint) {
            display: flex;
        }

        @include media(medium) {
            flex-wrap: wrap;
        }

        & > div, & > article, & > figure {
            flex: 1 1 0;
            width: 0;
            @include responsive-property('margin-right', $gutter-map);

            @include media-min($two-col-breakpoint) {
                &:last-child {
                    margin-right: 0;
                }
            }

            @include media-range($one-col-breakpoint, $two-col-breakpoint) {
                flex: 0 0 auto;
                width: calc(50% - #{map-get($gutter-map, $two-col-breakpoint) / 2});
                margin-bottom: map-get($gutter-map, $two-col-breakpoint);

                &:nth-last-child(odd) {
                    margin-right: 0;

                    &:first-child {
                        width: 100%;
                    }
                }
                &:nth-last-child(3) ~ * {
                    margin-bottom: 0;
                }
            }

            @include media($one-col-breakpoint) {
                width: 100%;
                margin-bottom: map-get($gutter-map, $one-col-breakpoint);
            }
        }
    }
}
