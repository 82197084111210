﻿/// <reference path="../../main">

%full-bleed-image {
    height: 100%;
    width: auto;
    max-width: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    // @include ie {
    //     // hide image in IE since it will be replaced with a <figure> with a background image
    //     display: none;
    // }

    @supports (object-fit: cover) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transform: none;
        left: 0;
        top: 0;
    }
}

%full-bleed-image-ie-replacement {
    // IE replacement with background image, to be applied to <figure> tag in context
    background-size: cover;
    background-position: center center;
    height: 100%;
    opacity: 0;
    transition: .3s opacity;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &[class] {
        // force a fade-in when the class is applied
        opacity: 1;
    }
}