﻿/// <reference path="../../main">

$left-sidebar-max-width: 13.6rem;
$right-sidebar-max-width: pxToRem(190px);
$right-sidebar-wide-max-width: pxToRem(279px);
$main-col-max-width: pxToRem(730px);
$wrapper-column-gutter: 1.75rem;

// STRUCTURE
.cpg-wrapper {
    display: flex;
    align-items: flex-start;
    max-width: $max-width;
    margin: 0 auto;
    padding: $gap-sm 0.625rem;
    &-large {
       max-width: 100rem;
    }
    .cpg-main {
        margin: 0 $wrapper-column-gutter;
        flex-shrink: 0;

        &:last-child {
            margin-right: 0;
        }

        &.cpg-main-home {
        }
    }

    &--media-article {
        display: block;
    }

    &-home {
        padding-top: 3rem;
    }
}

.cpg-wrapper .cpg-theme-purple {
    h1 {
        color: #873FAD;
    }
}

.cpg-main {
    flex: 1 0 0;

    .#{$adage-prefix}block {
        @extend %small-margin-v;
    }

    .adage-block {
        margin: 0;
        flex: 0 0 25%;
    }

    &-content {
        margin-left: 2rem;
    }

    &--media-article {
        &-content {
            > .cpg-main-content {
                margin: 0;
            }
        }

        &-important {
            display: flex;
            align-items: flex-start;

            &.cpg-theme-purple {
                h3 {
                    border-bottom: 2px solid #873FAD;
                }
            }

            h3 {
                padding-bottom: 10px;
                border-bottom: 2px solid $primary;
            }

            h4 {
                color: $primary-text !important;
                border-left: solid 3px $accent-1;
                padding-left: 5px;
                margin-top: 0;
            }

            .rich-text {

                > div:last-child {
                    display: flex;
                    padding: 2rem 3rem;
                    border-bottom: solid 1px $neutral-5;
                }

                > div:nth-last-child(2) {
                    display: flex;
                    padding: 2rem 3rem;
                    border-bottom: solid 1px $neutral-5;
                }
            }

            .span-3 {
                flex: 0 0 17%;
                margin: 0;
                height: 6.5rem;
                width: 6.5rem;

                img {
                    height: 6.5rem;
                    width: 6.5rem;
                }
            }
        }

        ul, ol {
            margin-left: $gap;
        }

        ul {
            list-style: initial;
        }

        ol {
            list-style: decimal;
        }

        display: flex;
        width: auto;
    }

    .tabbed-block-nav .tabbed-block-tab {
        width: 16rem;
    }

    .tabbed-block .row {
        background: $white;
    }

    .cpgtableblock {
        max-width: 68.5rem;
        width: 100%;

        td {
            select {
                & + button {
                    margin-top: 10px;
                }
            }
        }
    }
}

.cpg-sidebar {

    &-left, &-right {
        .#{$adage-prefix}block + .#{$adage-prefix}block {
            margin-top: $gap;
        }
    }

    &-left {
        flex: 0 0 $left-sidebar-max-width;
        min-width: $left-sidebar-max-width;
    }

    &-right {
        &:not(.wide) {
            flex: 0 0 ($right-sidebar-max-width + $wrapper-column-gutter);
        }

        &.wide {
            flex: 0 0 ($right-sidebar-wide-max-width + $wrapper-column-gutter);
        }

        min-width: ($right-sidebar-max-width + $wrapper-column-gutter);
    }
}

.cpg-header {
    display: flex;
    justify-content: space-between;

    &--audiences {
        display: block;

        .cpg-wrapper {
            justify-content: space-between;
            padding: 0;
        }
    }
}

.cpg-footer {
    display: flex;
    justify-content: space-between;

    &--only-next {
        flex-direction: row-reverse;
    }

    &--toc {
        margin-right: 314px;
    }

    .pagination-link {
        font-weight: normal;
    }

   
}
// BASIC STYLES
.cpg-wrapper {
}

.cpg-main {
    &-content {
        h1, h2, h3, h5 {
            color: $accent-1;
            @include font-size($medium-large-font-sizes);
            font-weight: $font-weight-bold;
        }
    }

    .cpg-header-image-block + div .adage-wysiwyg-block {
        p {
            @include font-size($medium-font-sizes);
        }
    }

    &-sidebar {
        border-left: 1px solid $neutral-3;
        padding-left: 2.5rem;
    }
}

.cpg-sidebar {

    &-left {
    }

    &-right {
    }
}

.cpg-header {
    padding: 1rem 0;
    border-bottom: 2px solid $primary;
    margin-bottom: 1rem;

    &.cpg-theme-purple {
        border-bottom: 2px solid #873FAD;
    }

    &-home {
        border-bottom: none;
    }

    .cpg-heading {
        color: $primary;

        &.cpg-theme-purple {
            color: #873FAD;
        }

        @include font-size($large-font-sizes);
    }

    .cpg-heading-date {
        background: $primary;

        &.cpg-theme-purple {
            background: #873FAD;
        }

        color: $white;
        @include font-size($medium-large-font-sizes);
        padding: .25rem 2.5rem;
        line-height: 1.6;
        position: relative;
        // for diagonal clipping of date element
        &::after {
            content: "";
            background: $white;
            position: absolute;
            bottom: -1rem;
            right: -1rem;
            height: 2rem;
            width: 2rem;
            transform: rotate(45deg);
        }
    }

    &--audiences {
        padding: 0;
        background: linear-gradient($golden-glow, #E5B85F);
    }
}

.cpg-footer {
    margin-top: 2rem;
    border-top: 2px solid $primary;

    &.cpg-theme-purple {
        border-top: 2px solid #873FAD;
    }

    padding: 1rem;

    a {
        color: $primary;

        &.cpg-theme-purple {
            color: #873FAD;
        }

        font-weight: $font-weight-bold;
    }

    .svg-icon {
        height: 2*$ui-icon-size;
        width: 2*$ui-icon-size;
        vertical-align: middle;
        margin: 0 .5rem;
    }
}

.cpgtabbedblock {
    .tabbed-block {
        .tabbed-block-content {
            & > div {
                & > .row {
                    display: flex;
                }
            }
        }
    }
}

.addthis_inline_share_toolbox {
    .at-share-btn {
        margin-right: 12px !important;
        .at-icon-wrapper {
            height: 24px !important;
            width: 24px !important;
            line-height: 24px !important;

            svg {
                width: 24px !important;
                height: 24px !important;
            }
        }
    }
}