﻿/// <reference path="../../main">


//STRUCTURE AND STYLING

.link-block {
    margin-top: 2rem;
    align-items: center;

    &-svg {
        display: flex;

        svg {
            height: 1.5rem;
            width: 1.5rem;
            fill: $neutral-2;
            margin-right: .5rem;
        }
    }

    a {
        position: relative;
        padding: 1rem;


        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -2rem;
            width: 2rem;
            height: 100%;
        }

        &.btn {
            @extend %btn;
            background-image: none;
            font-family: "Helvetica Neue LT W05_65 Medium", Arial, sans-serif;
            letter-spacing: .01rem;
            cursor: pointer;
        }
    }
}