﻿/// <reference path="../../main">

// STRUCTURE
.#{$adage-prefix}fiftyfifty {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;

    &-item {
        flex: 1 1 auto;
        width: 50%;
        display: block;
    }

    &-inner {
        &.reversed {
            .#{$adage-prefix}fiftyfifty-item:first-child {
                @include hide;
            }
        }

        display: flex;

        &.reversed {
            .#{$adage-prefix}fiftyfifty-item:last-child {
                @include hide;
            }
        }
    }
}

// BASIC STYLES
.#{$adage-prefix}fiftyfifty {
    &-heading {
        @extend %visually-hidden;
    }

    &-item {
        & + & {
            margin-left: $gap-sm;
        }

        .#{$adage-prefix}wysiwyg-block {
            padding: 0;
            width: 100%;
            display: flex;
            align-items: center;
        }

        p:first-child {
            margin-top: 0;
        }

        &:last-child {
            .adage-video-block-caption {
                text-align: right;
            }
        }

        &.gray {
            border-top: 3px solid $primary-medium;
            background: $neutral-4;
            padding: 2.15rem;

            h2 {
                margin-bottom: 1rem;
                font-family: $heading-font-bold;
                @include font-size($large3-font-sizes);
            }
        }

        #testimony {
            #test-img-wrapper {
                float: left;
                margin-right: 1rem;
            }

            #test-textblock {
                width: 27.5rem;
                padding-left: 1rem;
                @include font-size($medium-font-sizes);
            }
        }
    }

    .#{$adage-prefix}image-block {
        width: 100%; //IE fix
        figcaption {
            @extend %visually-hidden;
        }

        .adage-video-thumbnail-container {
            position: static;
        }
    }

    &-title {
        border-bottom: 0;

        .row0 & {
            @include font-size($large-font-sizes);
        }
    }
}

// HANDLING IMAGES AND ASPECT RATIO
.#{$adage-prefix}fiftyfifty {

    .#{$adage-prefix}block {
        width: 100%;
    }

    .#{$adage-prefix}image-block, .#{$adage-prefix}video-block {
        width: 100%;
        height: 0;
        position: relative;
        overflow: hidden;
        margin-top: 0;

        & > a {
            width: 100%;
            height: 100%;
        }

        img {
            @extend %full-bleed-image;
        }

        figure {
            @extend %full-bleed-image-ie-replacement;
        }
    }

    &-inner {
        &.ratio-standard {
            .#{$adage-prefix}image-block {
                padding-bottom: ratioToPercent(4.5, 3);
            }

            .#{$adage-prefix}video-block {
                padding-bottom: ratioToPercent(16, 9);
            }
        }

        &.ratio-square {
            .#{$adage-prefix}image-block {
                padding-bottom: ratioToPercent(1, 1);
            }
        }
    }
}

.#{$adage-prefix}fiftyfifty {
    .#{$adage-prefix}video-thumbnail-container {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .ratio-standard, .ratio-square {
        .#{$adage-prefix}video-thumbnail-container {
            &:after {
                content: '';
                width: 100%;
                display: block;
            }

            img {
                @extend %full-bleed-image;
            }
        }
    }

    .ratio-standard {
        .#{$adage-prefix}video-thumbnail-container {
            &:after {
                padding-bottom: ratioToPercent(4.5, 3);
            }
        }
    }

    .ratio-square {
        .#{$adage-prefix}video-thumbnail-container {
            &:after {
                padding-bottom: ratioToPercent(1, 1);
            }
        }
    }
}
