﻿/// <reference path="../../main">


// STRUCTURE
.tabbed-page-nav,
.tabbed-block-nav {
    display: flex;
    align-items: center;

    .tabbed-page-tab,
    .tabbed-block-tab {
        width: 20rem;
        padding: ($gap/4) ($gap/2);
        margin: 0 $gap/4;
        font-family: $heading-font-medium;
        font-weight: $font-weight-normal;
        text-align: center;
        
        &:first-child {
            margin-left: 0;
        }
        
        &:last-child {
            margin-right: 0;
        }
    }
}

.tabbed-block {
    margin-bottom: 4rem;
}

.tabbed-page,
.tabbed-block {
    max-width: 1000px;
}

// STYLING
.tabbed-page-nav,
.tabbed-block-nav {
    .tabbed-page-tab,
    .tabbed-block-tab {
        background: $neutral-4;
        @include font-size($medium-large-font-sizes);
        color: $primary-text;
        cursor: pointer;
        
        &.highlight {
            border: 0;
            font-weight: $font-weight-bold;
            background: $primary-light;
        }

        &:only-child {
            width: 100%;
            text-align: left;
            cursor: default;
        }
    }
}

.tabbed-page,
.tabbed-block {
    &.highlight {
        .tabbed-block-content {
            display: block;
        }
    }

    &.four-column {
        .tabbed-block-content {
            .row {
                .column {
                    &:nth-child(4), &:nth-child(3) {
                        width: 125px;
                    }
                }
            }
        }
    }

    &.three-column {
        .tabbed-block-content {
            .row {
                .column {
                   text-align: left !important;
                }
            }
        }
    }

    &.forms-publications-block {
        .tabbed-block-content {
            display: none;


            .row {
                background: $neutral-3;
                margin: 0;
                padding: 0;
                display: table;
                table-layout: fixed;
                width: 100%;
                max-width: 100%;

                &:nth-child(2n) {
                    background-color: $neutral-4;
                }
            }

            .column {
                color: $primary-text;
                padding: $gap/4;
                display: table-cell;
                margin-bottom: 0;
                overflow-wrap: break-word;

                p {
                    display: inline;
                }
            }
        }
    }

    .tabbed-page-content {
        &.highlight {
            border: 0;
            background: none;
        }
    }
}

.tabbed-page {
    padding: 1rem;
    width: $max-width;
    margin: 0 auto;
}

.tabbed-page-nav {
    border-bottom: 2px solid $primary;

    > .tabbed-page-tab {
        color: $primary;

        &.highlight {
            background: $primary;
            color: $white;
            font-weight: $font-weight-normal;
        }
    }
}

.tabbed-page-description {
    padding: 1rem 1rem 2rem 0;

    a {
        padding: 0;
        color: $primary;
    }

    p {
        margin: 0;
        margin-bottom: .25rem;
    }
}
    
