﻿/// <reference path="../../main">
$link-normal:		#1c7abf;
$link-hover:		#4a9dcf;

@mixin link-helper($color: $link-normal, $contrast: $link-hover) {
    transition: $transition;
    color: $color;

    &:hover, &:active, &:focus {
        color: $contrast;
    }
}